import React, { Component } from 'react';
import { Button, message } from 'antd';
import axios from '../../../../axios';
// import PropTypes from 'prop-types';

export class ReleaseButton extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
  }

  handleReleaseOrder = async () => {
    try {
      const response = await axios.post(
        '/drivers/releaseOrder',
        {
          orderId: this.props.order._id,
        },
        {
          headers: {
            'access-token': localStorage.getItem('token'),
          },
        },
      );
      console.log(response);
      message.success('successful');
      this.props.fetchOrders();
    } catch (error) {
      message.error('error');
    }
  };

  render() {
    return (
      <Button
        type="danger"
        onClick={() => {
          this.handleReleaseOrder();
        }}
      >
        Release
      </Button>
    );
  }
}

export default ReleaseButton;
