import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import SideMenu from '../../components/SideMenu/SideMenu';
import Orders from './Orders/Orders';
import Profile from './Profile/Profile';

export class Account extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      menuKey: '',
    };
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    await this.setState({ menuKey: window.location.pathname.toString().replace('/account/', '') });
  }

  render() {
    const menuLinks = [
      {
        key: 'orders',
        href: '/account/orders',
        name: 'Orders',
        intlName: 'orders',
      },
      {
        key: 'profile',
        href: '/account/profile',
        name: 'Profile',
        intlName: 'profile',
      },
    ];

    return (
      <div style={{ padding: '10px 20px' }}>
        <div className="accountTitle" style={{ fontSize: '20px', padding: '20px 0px' }}>
          Account
        </div>
        <Row gutter={16}>
          {this.state.menuKey !== '' ? (
            <SideMenu menuLinks={menuLinks} menuKey={this.state.menuKey} />
          ) : null}
          <Col sm={24} md={18}>
            <Switch>
              <Route exact path="/account/orders" component={Orders} />
              <Route exact path="/account/profile" component={Profile} />
              <Redirect from="/account" to="/account/orders" />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Account;
