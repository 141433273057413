import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Cart.css';
import {
  Row,
  Col,
  Button,
  Icon,
  Divider,
  Input,
  Empty,
  InputNumber,
  Alert,
  Checkbox,
  message,
  Typography,
} from 'antd';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { StripeProvider, Elements } from 'react-stripe-elements';
import moment from 'moment';
import * as actions from '../../store/actions';
import axios from '../../axios';
import { STRIPE_KEY } from '../../config';
import empty from '../../assets/images/guideline/empty.svg';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import { checkValidity } from '../../util';

const { Title } = Typography;

export class Cart extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      redirect: false,
      deliveryDate: '',
      stripe: null,
      taxRate: 0,
      tips: 0,
      showTips: false,
      unitNumber: '',
      canDetour: false,
      disableDetour: true,
      lunchCutoffTime: null,
      dinnerCutoffTime: null,
      supperCutoffTime: null,
    };
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    await this.props.checkItems();
    await this.props.checkCartTime();
    if (this.props.isAuthenticated) {
      await this.props.getCurrentUser();
      if (this.props.cartItems.length !== 0) {
        if (window.Stripe) {
          this.setState({ stripe: window.Stripe(STRIPE_KEY) });
        } else {
          document.querySelector('#stripe-js').addEventListener('load', () => {
            // Create Stripe instance once Stripe.js loads
            this.setState({ stripe: window.Stripe(STRIPE_KEY) });
          });
        }
      }
    }

    await this.props.checkAddress();
    await this.props.checkCartAddress();
    await this.props.checkCartDeliveryType();
    if (this.props.cartItems.length !== 0) {
      // set seller id
      const { sellerId } = this.props.cartItems[0];

      // get current shop tax rate
      const sellerResponse = await axios.get(`/sellers/seller/${sellerId}`);
      // console.log(sellerResponse);
      const seller = sellerResponse.data.result;
      this.setState({ taxRate: seller.taxRate });
      this.props.updateBilling(this.state.taxRate);

      const deliveryDateResponse = await axios.get('/orders/check/delivery/date', {
        params: {
          sellerId,
          latitude: this.props.cartLatitude,
          longitude: this.props.cartLongitude,
        },
      });

      const deliveryDate = deliveryDateResponse.data.result;
      console.log(deliveryDate);

      const lunchCutoffTime = seller.cutoffTimeLunch;
      const dinnerCutoffTime = seller.cutoffTimeDinner;
      const supperCutoffTime = seller.cutoffTimeSupper;

      this.setState({
        lunchCutoffTime,
        dinnerCutoffTime,
        supperCutoffTime,
      });

      // check delivery date from our API
      if (deliveryDate.section === 'morning') {
        this.setState({
          deliveryDate: `${this.props.hours}:${this.formatTime(this.props.minutes)} Today (${
            deliveryDate.date
          })`,
        });
      } else if (deliveryDate.section === 'noon') {
        if (this.props.hours < dinnerCutoffTime.hours) {
          this.setState({
            deliveryDate: `${this.props.hours}:${this.formatTime(
              this.props.minutes,
            )} Tomorrow (${moment(deliveryDate.date)
              .add(1, 'd')
              .format('YYYY-MM-DD')})`,
          });
        } else {
          this.setState({
            deliveryDate: `${this.props.hours}:${this.formatTime(this.props.minutes)} Today (${
              deliveryDate.date
            })`,
          });
        }
      } else if (deliveryDate.section === 'evening') {
        if (this.props.hours < supperCutoffTime.hours) {
          this.setState({
            deliveryDate: `${this.props.hours}:${this.formatTime(
              this.props.minutes,
            )} Tomorrow (${moment(deliveryDate.date)
              .add(1, 'd')
              .format('YYYY-MM-DD')})`,
          });
        } else {
          this.setState({
            deliveryDate: `${this.props.hours}:${this.formatTime(this.props.minutes)} Today (${
              deliveryDate.date
            })`,
          });
        }
      } else if (deliveryDate.section === 'midnight') {
        this.setState({
          deliveryDate: `${this.props.hours}:${this.formatTime(this.props.minutes)} Tomorrow (${
            deliveryDate.date
          })`,
        });
      }

      const distanceResponse = await axios.get('/orders/check/distance', {
        params: {
          originLatitude: this.props.cartLatitude,
          originLongitude: this.props.cartLongitude,
          destinationLatitude: this.props.latitude,
          destinationLongitude: this.props.longitude,
        },
      });

      const distance = distanceResponse.data.result;
      const miles = distance / 1609.344;

      if (miles < 1) {
        this.setState({
          disableDetour: false,
        });
      } else {
        this.setState({
          canDetour: false,
          disableDetour: true,
        });
      }
    }
    // this.calculateTotal();
  }

  formatTime = (time) => {
    if (typeof time === 'number') {
      if (time < 10) {
        return `0${String(time)}`;
      }
      return time;
    }
    return time;
  };

  handleChangeTipsByPercentage = (percentage) => {
    const number = Math.round(this.props.subtotal * percentage * 100) / 100;
    this.setState({ tips: number });
  };

  handleChangeTips = (value) => {
    if (checkValidity(value, 'isTips')) {
      const number = Math.round(Number(value) * 100) / 100;
      this.setState({ tips: number });
    } else {
      this.setState({ tips: 0 });
    }
  };

  handleClickTips = (value) => {
    this.setState({
      showTips: value,
    });
  };

  handleChangeCheckbox = async (e) => {
    // first check distance
    const distanceResponse = await axios.get('/orders/check/distance', {
      params: {
        originLatitude: this.props.cartLatitude,
        originLongitude: this.props.cartLongitude,
        destinationLatitude: this.props.latitude,
        destinationLongitude: this.props.longitude,
      },
    });

    const distance = distanceResponse.data.result;
    const miles = distance / 1609.344;

    if (miles < 1) {
      await this.setState({ canDetour: e.target.checked });
    } else {
      message.warn(
        'Sorry, the distance over then 1 mile, please go back home page change your address.',
      );
      await this.setState({ canDetour: false, disableDetour: true });
    }
    console.log(this.state.canDetour);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      cartItems, subtotal, deliveryFee, tax, total,
    } = this.props;
    const { tips, showTips } = this.state;
    const emptyImg = (
      <Row>
        <Col span={24} style={{ margin: '30px 0px' }}>
          <img src={empty} alt="food" />
        </Col>
      </Row>
    );
    if (cartItems !== undefined && cartItems.length === 0) {
      return (
        <div style={{ marginTop: '120px' }}>
          <Empty
            image={emptyImg}
            description={(
              <span>
                <FormattedMessage id="cartEmptyMessage" defaultMessage="Dropbee cart is empty" />
              </span>
)}
          >
            <Link to="/">
              <Button className="emptyButton" type="primary" size="large">
                <FormattedMessage id="chooseDish" defaultMessage="Dropbee" />
              </Button>
            </Link>
          </Empty>
        </div>
      );
    }
    return (
      <div>
        {this.state.redirect ? <Redirect to="/account/orders" /> : null}
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} sm={24} md={18}>
            <div className="cartConatiner">
              <Row>
                <Col md={24} lg={12} className="cartInfo">
                  <Title level={3}>
                    <FormattedMessage id="orderDetail" defaultMessage="cart" />
                  </Title>
                  <Row className="cartAddress">
                    {this.props.cartDeliveryType === 'pickup' ? (
                      <Col sm={8}>
                        <FormattedMessage id="pickupLocation" defaultMessage="Dropbee" />
                      </Col>
                    ) : null}
                    {this.props.cartDeliveryType === 'shuttle' ? (
                      <Col sm={8} className={this.state.canDetour ? 'cross' : null}>
                        <FormattedMessage id="shuttleLocation" defaultMessage="Dropbee" />
                      </Col>
                    ) : null}
                    {this.props.cartDeliveryType === 'delivery' ? (
                      <Col sm={8}>
                        <FormattedMessage id="deliveryLocation" defaultMessage="Dropbee" />
                      </Col>
                    ) : null}
                    <Col sm={16} className={this.state.canDetour ? 'cross' : null}>
                      <Row>
                     
                        {this.props.cartAddress}
                    
                      </Row>
                    </Col>

                    {this.props.cartDeliveryType === 'delivery' ? (
                      <Col span={24}>
                      
                      <Row type="flex" align="middle">
                          <Col sm={8}>
                          
                          </Col>
                          <Col sm={16}>
                            <Input
                              style={{ margin: '8px 0px' }}
                              name="unitNumber"
                              value={this.state.unitNumber}
                              onChange={(e)=>{console.log(e);this.handleChange(e);console.log(this.state.unitNumber)}}
                              placeholder="Apt/Unit/Notes"
                            />
                          </Col>
                        </Row>
                      </Col>
                      
                      ) : null}

                  </Row>
                  <Row>
                    <Col>
                      {this.props.cartDeliveryType !== 'shuttle'
                      || this.state.disableDetour ? null : (
                        <Alert
                          style={{ margin: '10px 0px' }}
                          message={(
                            <div>
                              <Checkbox
                                checked={this.state.canDetour}
                                onChange={this.handleChangeCheckbox}
                                disabled={this.state.disableDetour}
                              >
                                <FormattedMessage id="detour" defaultMessage="Detour" />
                              </Checkbox>
                              <div style={{ marginTop: '5px' }}>
                                <FormattedMessage
                                  id="detourExplain"
                                  defaultMessage="enter your address"
                                />
                              </div>
                            </div>
)}
                          type="info"
                        />
                        )}
                    </Col>
                  </Row>
                  {this.state.canDetour ? (
                    <Row>
                      <Row>
                        <Col sm={8}>
                          <FormattedMessage id="detourDelivery" defaultMessage="Dropbee" />
                        </Col>
                        <Col sm={16}>{this.props.address}</Col>
                      </Row>
                      <Row>
                        <Col sm={8}>
                          <FormattedMessage id="addressNote" default="Apt/unit" />
                        </Col>
                        <Col sm={16}>
                          <Input
                            style={{ margin: '8px 0px' }}
                            name="unitNumber"
                            value={this.state.unitNumber}
                            onChange={this.handleChange}
                            placeholder="Apt/Unit/Notes"
                          />
                        </Col>
                      </Row>
                    </Row>
                  ) : null}
                  <Divider />
                  <Row className="cartTime" type="flex" align="middle">
                    <Col sm={8}>
                      {' '}
                      <FormattedMessage id="pickupTime" defaultMessage="Dropbee" />
                    </Col>
                    <Col sm={16}>
                     
                        {this.state.deliveryDate}
              
                      
                    </Col>
                    {/* <Col sm={16}>
                      <DatePicker />
                    </Col> */}
                  </Row>
                  <Divider />
                  <Row className="cartDishList">
                    <Col span={24}>
                      {cartItems !== undefined
                        && this.props.cartItems.map(n => (
                          <Row key={n.id} className="cartDishItem cartItem">
                            <div className="left">
                              <div className="dishImg">
                                <img src={n.pictureURL} alt="food" />
                              </div>
                            </div>
                            <div className="right">
                              <div className="row main">
                                <div className="name">
                                  {' '}
                                  {n.title}
                                  {' '}
                                </div>
                                <div className="count">
                                  {`x${n.count}`}
                                  {' '}
                                </div>
                                <div className="price">{`$${n.price.toFixed(2)}`}</div>
                              </div>

                              {n.addons.length !== 0
                                && n.addons.map(addon => (
                                  <div key={addon.shortId} className="row addon">
                                    <div className="name">{addon.description}</div>

                                    <div className="price">
                                      {' '}
+
                                      {addon.price}
                                    </div>
                                  </div>
                                ))}
                              <div className="comment">{n.comment}</div>
                            </div>
                            <div className="option">
                              <div style={{ width: '100%' }}>
                                <button
                                  type="submit"
                                  onClick={() => {
                                    this.props.removeItem(n, this.state.taxRate);
                                  }}
                                  style={{
                                    color: 'black',
                                    border: 'none',
                                    padding: '0',
                                    background: 'none',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <Icon style={{ color: 'rgb(128, 128, 128)' }} type="delete" />
                                </button>
                              </div>
                            </div>
                          </Row>
                        ))}
                    </Col>
                  </Row>
                  <Divider style={{ marginBottom: '0px' }} />
                  <Row className="cartCharges">
                    <div className="cartItem">
                      <div className="name">
                        <FormattedMessage id="subtotal" defaultMessage="Dropbee" />
                      </div>
                      <div className="price">
                        <div style={{ width: '100%' }}>{`$${subtotal.toFixed(2)}`}</div>
                      </div>
                      <div className="option" />
                    </div>
                  </Row>
                  <Row className="cartCharges">
                    <div className="cartItem">
                      <div className="name">
                        <FormattedMessage id="tax" defaultMessage="Dropbee" />
                      </div>
                      <div className="price">
                        <div style={{ width: '100%' }}>{`$${tax.toFixed(2)}`}</div>
                      </div>
                      <div className="option" />
                    </div>
                  </Row>
                  <Row className="cartCharges">
                    <div className="cartItem">
                      <div className="name">
                        <FormattedMessage id="deliveryfee" defaultMessage="Dropbee" />
                      </div>
                      <div className="price">
                        {this.state.canDetour ? (
                          <div style={{ width: '100%' }}>
                            {`$${(deliveryFee + 0.5).toFixed(2)}`}
                          </div>
                        ) : (
                          <div style={{ width: '100%' }}>{`$${deliveryFee.toFixed(2)}`}</div>
                        )}
                      </div>
                      <div className="option" />
                    </div>
                  </Row>
                  <Row className="cartCharges">
                    <div className="cartItem">
                      <div className="name">
                        <FormattedMessage id="tips" defaultMessage="小费" />
                      </div>
                      <div
                        role="presentation"
                        className="price"
                        onClick={() => {
                          this.handleClickTips(true);
                        }}
                      >
                        <div style={{ width: '100%' }}>
                          <span
                            style={{
                              padding: '5px',
                              background: '#d2d2d2',
                              cursor: 'pointer',
                            }}
                          >
                            {tips === '' || tips === null ? '$0.00' : `$${tips.toFixed(2)}`}
                          </span>
                        </div>
                      </div>
                      <div className="option" />
                    </div>
                  </Row>
                  {showTips ? (
                    <Row className="cartCharges">
                      <div className="cartItem">
                        <div className="tipInfo">
                          <FormattedMessage id="tipsInfo" default="no tips required" />
                          <div className="tipsSetting">
                            <Button
                              size="small"
                              onClick={() => {
                                this.handleChangeTipsByPercentage(0.1);
                              }}
                            >
                              10%
                            </Button>
                            <Button
                              size="small"
                              onClick={() => {
                                this.handleChangeTipsByPercentage(0.15);
                              }}
                            >
                              15%
                            </Button>
                            <Button
                              size="small"
                              onClick={() => {
                                this.handleChangeTipsByPercentage(0.2);
                              }}
                            >
                              20%
                            </Button>
                            <InputNumber
                              className="tipVal"
                              size="small"
                              value={tips}
                              onChange={this.handleChangeTips}
                            />
                          </div>
                          <div className="confirmButton">
                            <Button
                              type="primary"
                              size="small"
                              onClick={() => {
                                this.handleClickTips(false);
                              }}
                            >
                              Confirm
                            </Button>
                          </div>
                        </div>
                        <div className="option" />
                      </div>
                    </Row>
                  ) : null}

                  <Row className="cartCharges">
                    <div className="cartItem">
                      <div className="name">
                        <FormattedMessage id="total" defaultMessage="Dropbee" />
                      </div>
                      <div className="price">
                        {this.state.canDetour ? (
                          <div style={{ width: '100%' }}>
                            {`$${(total + tips + 0.5).toFixed(2)}`}
                          </div>
                        ) : (
                          <div style={{ width: '100%' }}>{`$${(total + tips).toFixed(2)}`}</div>
                        )}
                      </div>
                      <div className="option" />
                    </div>
                  </Row>
                </Col>
                {this.props.isAuthenticated ? (
                  <Col md={24} lg={12} className="payment">
                    <Title level={3} style>
                      <FormattedMessage id="payment" defaultMessage="Dropbee" />
                    </Title>
                    <div className="paymentArea">
                      {this.state.stripe ? (
                        <StripeProvider stripe={this.state.stripe}>
                          <Elements>
                            <CheckoutForm
                              cartItems={this.props.cartItems}
                              cartDeliveryType={this.props.cartDeliveryType}
                              cartAddress={this.props.cartAddress}
                              cartLongitude={this.props.cartLongitude}
                              cartLatitude={this.props.cartLatitude}
                              canDetour={this.state.canDetour}
                              address={this.props.address}
                              longitude={this.props.longitude}
                              latitude={this.props.latitude}
                              unitNumber={this.state.unitNumber}
                              subtotal={this.props.subtotal}
                              clearItems={this.props.clearItems}
                              hours={this.props.hours}
                              minutes={this.props.minutes}
                              firstName={this.props.firstName}
                              lastName={this.props.lastName}
                              phoneNumber={this.props.phoneNumber}
                              lang={this.props.lang}
                              tips={this.state.tips}
                            />
                          </Elements>
                        </StripeProvider>
                      ) : null}
                    </div>
                  </Col>
                ) : (
                  <Col md={24} lg={12} className="notLogin">
                    <div className="alertMsg">
                      {' '}
                      <FormattedMessage id="notloginMsg" defaultMessage="Dropbee" />
                    </div>
                    <div>
                      <Link to="/login">
                        <Button type="primary" block className="loginbtn">
                          <FormattedMessage id="pleaseLogin" defaultMessage="Dropbee" />
                        </Button>
                      </Link>
                    </div>
                    <div>
                      <Link to="/signUp">
                        <span className="signUpbtn">
                          <FormattedMessage id="signupAccount" defaultMessage="Dropbee" />
                        </span>
                      </Link>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  phoneNumber: state.user.phoneNumber,
  subtotal: state.cart.subtotal,
  deliveryFee: state.cart.deliveryFee,
  tax: state.cart.tax,
  total: state.cart.total,
  cartItems: state.cart.items,
  isAuthenticated: state.auth.token !== null,
  cartAddress: state.cart.cartAddress,
  cartDeliveryType: state.cart.cartDeliveryType,
  cartLatitude: state.cart.cartLatitude,
  cartLongitude: state.cart.cartLongitude,
  address: state.cart.address,
  latitude: state.cart.latitude,
  longitude: state.cart.longitude,
  hours: state.cart.cartHours,
  minutes: state.cart.cartMinutes,
  lang: state.locale.lang || 'en',
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
  removeItem: (item, taxRate) => dispatch(actions.removeItem(item, taxRate)),
  clearItems: () => dispatch(actions.clearItems()),
  checkAddress: () => dispatch(actions.checkAddress()),
  checkCartAddress: () => dispatch(actions.checkCartAddress()),
  checkCartDeliveryType: () => dispatch(actions.checkCartDeliveryType()),
  checkCartTime: () => dispatch(actions.checkCartTime()),
  checkItems: () => dispatch(actions.checkItems()),
  updateBilling: taxRate => dispatch(actions.updateBilling(taxRate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cart);
