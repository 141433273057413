import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Input,
  Table,
  Select,
  Checkbox,
  Radio,
} from 'antd';
import { connect } from 'react-redux';
import './SellerItems.css';
import shortid from 'shortid';
import axios from '../../../axios';

const { Column } = Table;

export class SellerItems extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      editMode: '',
      // item information
      itemId: '',
      title: '',
      titleChinese: '',
      price: '',
      pictureURL: 'https://dropbee.s3-us-west-1.amazonaws.com/food.svg',
      descriptionChinese: '',
      description: '',
      comment: '',
      category: '',
      available: false,
      isLunch: false,
      isDinner: false,
      isSupper: false,
      deleted: false,
      stock: '',
      priority: 5,
      addons: [],
      addonDescription: '',
      addonPrice: '',
      addonType: 'checkbox',
      originalSellerName: '',
      visible: false,
      // end: item information
      confirmLoading: false,
      categories: [],
      items: [],
      sellerId: props.match.params.sellerId,
      mode: 'seller',
    };
  }

  async componentDidMount() {
    await this.initSeller();
  }

  addItem = () => {
    this.setState({
      editMode: 'new',
      visible: true,
    });
  };

  handleCancel = (e) => {
    this.resetState();
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCheckedChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  handleSelectChange = (value) => {
    this.setState({ category: value });
    console.log(`selected ${value}`);
  };

  handleChangeType = (e) => {
    this.setState({ addonType: e.target.value });
    console.log(`selected ${e.target.value}`);
  };

  handleAddAddon = () => {
    let array = this.state.addons;
    if (!array) {
      array = [];
    }
    array.push({
      description: this.state.addonDescription,
      price: this.state.addonPrice,
      type: this.state.addonType,
      shortId: shortid.generate(),
    });
    this.setState({
      addons: array,
      addonDescription: '',
      addonPrice: '',
      addonType: 'checkbox',
    });
  };

  handleEditItem = (item) => {
    this.setState({
      editMode: 'edit',
      visible: true,
      itemId: item._id,
      title: item.title,
      titleChinese: item.titleChinese,
      description: item.description,
      descriptionChinese: item.descriptionChinese,
      comment: item.comment,
      category: item.category,
      price: item.price,
      pictureURL: item.pictureURL,
      available: item.available,
      isLunch: item.isLunch,
      isDinner: item.isDinner,
      isSupper: item.isSupper,
      deleted: item.deleted,
      priority: item.priority,
      stock: item.stock,
      originalSellerName: item.originalSellerName,
      addons: item.addons,
    });
  };

  initSeller = async () => {
    let response;
    let categoriesResponse;
    if (this.state.sellerId) {
      response = await axios.get(
        `/items/items/sellerId/${this.state.sellerId}`,
        {
          params: { deleted: true },
        }
      );
      categoriesResponse = await axios.get(
        `/items/categories/sellerId/${this.state.sellerId}`
      );
      this.setState({ mode: 'admin' });
    } else {
      response = await axios.get(
        `/items/items/sellerId/${this.props.sellerId}`,
        {
          params: { deleted: true },
        }
      );
      categoriesResponse = await axios.get(
        `/items/categories/sellerId/${this.props.sellerId}`
      );
    }
    if (response.data.result) {
      this.setState({
        items: response.data.result,
      });
    }

    this.setState({
      categories: categoriesResponse.data.result,
    });
    console.log(this.state);
  };

  handleOk = async (e) => {
    this.setState({ confirmLoading: true });
    const {
      itemId,
      title,
      titleChinese,
      price,
      pictureURL,
      category,
      description,
      descriptionChinese,
      comment,
      editMode,
      available,
      priority,
      isLunch,
      isDinner,
      isSupper,
      deleted,
      stock,
      originalSellerName,
      addons,
      mode,
      sellerId,
    } = this.state;
    if (pictureURL === '') {
      this.setState({
        pictureURL: 'https://dropbee.s3-us-west-1.amazonaws.com/food.svg',
      });
    }

    console.log(addons);

    if (editMode === 'new') {
      const newItem = {
        title,
        titleChinese,
        price,
        pictureURL,
        sellerId: this.props.sellerId,
        category,
        description,
        descriptionChinese,
        comment,
        available,
        isLunch,
        isDinner,
        isSupper,
        priority,
        stock,
        originalSellerName,
        addons,
        mode,
      };
      if (mode === 'admin') {
        newItem.sellerId = sellerId;
      }
      const response = await axios.post('/items/item/create', newItem, {
        headers: { 'access-token': localStorage.getItem('token') },
      });
      console.log(response.data);
    } else {
      const editedItem = {
        sellerId: this.props.sellerId,
        itemId,
        title,
        titleChinese,
        price,
        pictureURL,
        category,
        description,
        descriptionChinese,
        comment,
        available,
        isLunch,
        isDinner,
        isSupper,
        deleted,
        priority,
        stock,
        originalSellerName,
        addons,
        mode,
      };
      if (mode === 'admin') {
        editedItem.sellerId = sellerId;
      }
      const response = await axios.post('/items/item/update', editedItem, {
        headers: { 'access-token': localStorage.getItem('token') },
      });
      console.log(response.data);
    }

    this.resetState();
    await this.initSeller();
  };

  resetState = () => {
    this.setState({
      confirmLoading: false,
      visible: false,
      itemId: '',
      title: '',
      titleChinese: '',
      description: '',
      descriptionChinese: '',
      comment: '',
      category: '',
      price: '',
      available: false,
      addons: [],
      addonDescription: '',
      addonPrice: '',
      addonType: 'checkbox',
      isLunch: false,
      isDinner: false,
      isSupper: false,
      deleted: false,
      stock: '',
      priority: 5,
      originalSellerName: '',
      pictureURL: 'https://dropbee.s3-us-west-1.amazonaws.com/food.svg',
    });
  };

  render() {
    const {
      title,
      titleChinese,
      price,
      pictureURL,
      visible,
      confirmLoading,
      items,
      category,
      categories,
      description,
      descriptionChinese,
      comment,
      available,
      isLunch,
      isDinner,
      isSupper,
      deleted,
      stock,
      priority,
      addons,
      addonDescription,
      addonPrice,
      addonType,
      originalSellerName,
    } = this.state;

    const addModal = (
      <Modal
        title="Add Item"
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        confirmLoading={confirmLoading}
        maskClosable={false}
        destroyOnClose
      >
        <Row>
          <Col>
            <Row className="itemModalField">
              <Col span={6}>Title</Col>
              <Col span={18}>
                <Input
                  name="title"
                  value={title}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>中文标题</Col>
              <Col span={18}>
                <Input
                  name="titleChinese"
                  value={titleChinese}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Description</Col>
              <Col span={18}>
                <Input.TextArea
                  name="description"
                  value={description}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>中文描述</Col>
              <Col span={18}>
                <Input.TextArea
                  name="descriptionChinese"
                  value={descriptionChinese}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Price</Col>
              <Col span={18}>
                <Input
                  name="price"
                  value={price}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Picture URL</Col>
              <Col span={18}>
                <Input
                  name="pictureURL"
                  value={pictureURL}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Category</Col>
              <Col span={9}>
                <Input
                  name="category"
                  value={category}
                  onChange={this.handleChange}
                />
              </Col>
              <Col
                span={8}
                style={{
                  marginLeft: '10px',
                }}
              >
                <Select
                  style={{ width: 120 }}
                  value={category}
                  onChange={this.handleSelectChange}
                >
                  {categories.map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Available</Col>
              <Col span={18}>
                <Checkbox
                  name="available"
                  checked={available}
                  onChange={this.handleCheckedChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Lunch</Col>
              <Col span={18}>
                <Checkbox
                  name="isLunch"
                  checked={isLunch}
                  onChange={this.handleCheckedChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Dinner</Col>
              <Col span={18}>
                <Checkbox
                  name="isDinner"
                  checked={isDinner}
                  onChange={this.handleCheckedChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Supper</Col>
              <Col span={18}>
                <Checkbox
                  name="isSupper"
                  checked={isSupper}
                  onChange={this.handleCheckedChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Deleted</Col>
              <Col span={18}>
                <Checkbox
                  name="deleted"
                  checked={deleted}
                  onChange={this.handleCheckedChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Addons</Col>
              <Col span={18}>
                <Row>
                  {addons &&
                    addons.map((addon) => (
                      <div key={addon.shortId}>
                        {addon.description} {addon.price} {addon.type}
                      </div>
                    ))}
                  <Col />
                </Row>
                <Row>
                  <Col span={6}>
                    <Input
                      placeholder="Description"
                      name="addonDescription"
                      value={addonDescription}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col span={6}>
                    <Input
                      placeholder="Price"
                      name="addonPrice"
                      value={addonPrice}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col span={6}>
                    <Radio.Group
                      value={addonType}
                      onChange={this.handleChangeType}
                    >
                      <Radio value="checkbox">Checkbox</Radio>
                      <Radio value="radio">Radio</Radio>
                    </Radio.Group>
                  </Col>
                  <Col span={4}>
                    <Button
                      onClick={() => {
                        this.handleAddAddon();
                      }}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Stock</Col>
              <Col span={18}>
                <Input
                  name="stock"
                  value={stock}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Comment</Col>
              <Col span={18}>
                <Input.TextArea
                  name="comment"
                  value={comment}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Original Seller Name</Col>
              <Col span={18}>
                <Input
                  name="originalSellerName"
                  value={originalSellerName}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row className="itemModalField">
              <Col span={6}>Priority</Col>
              <Col span={18}>
                <Input
                  name="priority"
                  value={priority}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    );

    return (
      <div>
        <Row>
          <Col>
            <h1>Items</h1>
          </Col>
        </Row>
        <Row>
          <Button onClick={this.addItem}>Add Item</Button>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Table dataSource={items} rowKey={(record) => record._id}>
            <Column title="Title" dataIndex="title" key="title" />
            <Column title="标题" dataIndex="titleChinese" key="titleChinese" />
            <Column title="Price" dataIndex="price" key="price" />
            <Column
              title="Picture"
              key="picture"
              render={(text, record) => (
                <img
                  style={{ height: '64px', width: '64px', objectFit: 'cover' }}
                  alt={record.title}
                  src={record.pictureURL}
                />
              )}
            />
            <Column title="Category" dataIndex="category" key="category" />
            <Column
              title="Available"
              key="available"
              render={(text, record) => (
                <Row>
                  <Row>{record.available.toString()}</Row>
                </Row>
              )}
            />
            <Column
              title="Deleted"
              key="deleted"
              render={(text, record) => (
                <Row>
                  <Row>{record.deleted ? record.deleted.toString() : null}</Row>
                </Row>
              )}
            />
            <Column
              title="Status"
              key="status"
              render={(text, record) => (
                <Row>
                  <Row>
                    Lunch:
                    {record.isLunch ? record.isLunch.toString() : null}
                  </Row>
                  <Row>
                    Dinner:
                    {record.isDinner ? record.isDinner.toString() : null}
                  </Row>
                  <Row>
                    Supper:
                    {record.isSupper ? record.isSupper.toString() : null}
                  </Row>
                </Row>
              )}
            />
            <Column
              title="Priority"
              key="priority"
              render={(text, record) => (
                <Row>
                  <Row>
                    {record.priority ? record.priority.toString() : null}
                  </Row>
                </Row>
              )}
            />
            <Column
              title="Seller"
              dataIndex="originalSellerName"
              key="originalSellerName"
            />
            <Column
              title="Actions"
              key="actions"
              render={(text, record) => (
                <span>
                  <Button
                    size="small"
                    onClick={() => {
                      this.handleEditItem(record);
                    }}
                  >
                    Edit
                  </Button>
                </span>
              )}
            />
          </Table>
        </Row>
        {addModal}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sellerId: state.user.sellerId,
});

export default connect(mapStateToProps, null)(SellerItems);
