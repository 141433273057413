import React from 'react';

function NotFound() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Sorry, the page not found.</h1>
    </div>
  );
}

export default NotFound;
