import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import loading from '../../assets/images/statusImg/logo.svg';
import success from '../../assets/images/statusImg/success.svg';
import fail from '../../assets/images/statusImg/error.svg';
import './PaymentStatus.css';

export class PaymentStatus extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    console.log(this.props.location.state);
    if (this.props.location.state) {
      console.log(this.props.location.state.status);
    }
  }

  render() {
    return (
      <div>
        {this.props.location.state === undefined ? <Redirect to="/" /> : null}
        {this.props.location.state !== undefined
        && this.props.location.state.status === 'success' ? (
          <Row style={{ minHeight: '400px' }} type="flex" align="middle" justify="center">
            <Col className="statusBox" span={20}>
              <div className="statusImg status">
                <img src={success} alt="success" />
              </div>
              <div className="successMsg" style={{ marginTop: '20px' }}>
                <FormattedMessage id="payment.success" defaultMessage="Success!" />
              </div>
              <div className="gotoOrderButton">
                <Link to="/account/orders">
                  <Button>
                    <FormattedMessage id="payment.myorder" defaultMessage="My order" />
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          ) : null}
        {this.props.location.state !== undefined && this.props.location.state.status === 'fail' ? (
          <Row style={{ minHeight: '400px' }} type="flex" align="middle" justify="center">
            <Col className="statusBox" span={20}>
              <div className="statusImg status">
                <img src={fail} alt="fail" />
              </div>
              <div>
                <div className="lds-ellipsis">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <div className="errorMsg">
                <FormattedMessage id="payment.fail" defaultMessage="Whoops, please try again!" />
              </div>

              <div className="gotoOrderButton">
                <Link to="/account/orders">
                  <Button>My Orders</Button>
                </Link>
              </div>
            </Col>
          </Row>
        ) : null}
        {this.props.location.state !== undefined
        && this.props.location.state.status === 'loading' ? (
          <Row style={{ minHeight: '400px' }} type="flex" align="middle" justify="center">
            <Col className="statusBox" span={20}>
              <div className="statusImg">
                <img src={loading} alt="loading" />
              </div>
              <div>
                <div className="lds-ellipsis">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <div>
                <FormattedMessage id="payment.myorder" defaultMessage="Processing, please wait!" />
              </div>
            </Col>
          </Row>
          ) : null}
      </div>
    );
  }
}

export default connect(null)(PaymentStatus);
