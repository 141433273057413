import React from 'react';
import { Col, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export const SideMenu = props => (
  <div>
    <Col md={6} xs={0}>
      <Menu defaultSelectedKeys={[props.menuKey]} mode="inline">
        {props.menuLinks.map(link => (
          <Menu.Item key={link.key}>
            <Link to={link.href}>
              <FormattedMessage id={link.intlName} defaultMessage={link.intlName} />
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </Col>
    <Col md={0} xs={24} style={{ marginBottom: '20px' }}>
      <Menu defaultSelectedKeys={[props.menuKey]} mode="horizontal">
        {props.menuLinks.map(link => (
          <Menu.Item key={link.key}>
            <Link to={link.href}>
              <FormattedMessage id={link.intlName} defaultMessage={link.intlName} />
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </Col>
  </div>
);

SideMenu.propTypes = {};

export default SideMenu;
