import React, { Component } from 'react';
import {
  Row, Col, List, Pagination, Table, Tag, Button, Checkbox,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from '../../../axios';
import OrderStatus from './OrderStatus/OrderStatus';
import './SellerOrders.css';

const { Column } = Table;

export class SellerOrders extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      orders: [],
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      isPaid: true,
    };
  }

  async componentDidMount() {
    await this.fetchOrders();
  }

  handlePageIndexChange = async (page, pageSize) => {
    await this.setState({
      pageIndex: page,
    });
    await this.fetchOrders();
  };

  handlePageSizeChange = async (current, size) => {
    await this.setState({
      pageSize: size,
    });
    await this.fetchOrders();
  };

  handleCheckbox = async (e) => {
    await this.setState({
      isPaid: !e.target.checked,
    });
    console.log(this.state.isPaid);
    await this.fetchOrders();
  };

  fetchOrders = async () => {
    const response = await axios.get(`/orders/orders/sellerId/${this.props.sellerId}`, {
      params: {
        isPaid: this.state.isPaid,
        pageSize: this.state.pageSize,
        pageIndex: this.state.pageIndex,
      },
      headers: { 'access-token': localStorage.getItem('token') },
    });
    console.log(response.data.result);

    this.setState({
      orders: response.data.result.orders,
      total: response.data.result.count,
    });
  };

  render() {
    return (
      <div className="sellerOrders">
        <h1>Orders</h1>
        <Row>
          <Col>
            <Button
              onClick={() => {
                this.fetchOrders();
              }}
            >
              Reload
            </Button>
            <Checkbox
              style={{ marginLeft: '10px' }}
              checked={!this.state.isPaid}
              onChange={this.handleCheckbox}
            >
              Fail orders
            </Checkbox>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col>
            {this.state.orders.length !== 0 ? (
              <List
                itemLayout="horizontal"
                dataSource={this.state.orders}
                renderItem={order => (
                  <List.Item className="orderItem" style={{ marginBottom: '30px' }}>
                    {!order.isPaid ? <div className="mark" /> : null}
                    <Row>
                      <Row>
                        Mode:
                        {'\t'}
                        <Tag color="volcano">{`${order.deliveryType}`}</Tag>
                      </Row>
                      <Row>
                        Create Date:
                        {`\t${moment(order.createdAt).format('YYYY-MM-DD HH:mm')}`}
                      </Row>
                      <Row>
                        Estimated Date:
                        {`\t${moment(order.estimatedDate).format('YYYY-MM-DD HH:mm')}`}
                      </Row>
                      <Row>
                        Address:
                        {`\t${order.address}`}
                      </Row>
                      {order.canDetour ? (
                        <Row>
                          Unit:
                          {`\t${order.unitNumber}`}
                        </Row>
                      ) : null}
                      {order.canDetour ? (
                        <Row>
                          Shuttle Address:
                          {`\t${order.shuttleAddress}`}
                        </Row>
                      ) : null}
                      <Row>{`Name:\t${order.user.firstName} ${order.user.lastName}`}</Row>
                      <Row>{`Email:\t${order.user.email}`}</Row>
                      <Row>
                        Tel:
                        {'\t'}
                        <a href={`tel:${order.user.phoneNumber}`}>{order.user.phoneNumber}</a>
                      </Row>
                      <Row>
                        <Table
                          pagination={false}
                          dataSource={order.items}
                          rowKey={record => record._id}
                        >
                          <Column title="Title" dataIndex="title" key="title" />
                          <Column title="标题" dataIndex="titleChinese" key="titleChinese" />
                          <Column title="Price" dataIndex="price" key="price" />
                          <Column title="Count" dataIndex="count" key="count" />
                          <Column title="Comment" dataIndex="comment" key="comment" />
                          <Column
                            title="picture"
                            key="picture"
                            render={(text, record) => (
                              <div>
                                <img
                                  style={{ height: '64px', width: '64px', objectFit: 'cover' }}
                                  src={record.pictureURL}
                                  alt="food"
                                />
                              </div>
                            )}
                          />
                          <Column
                            title="Addons"
                            key="addons"
                            render={(text, record) => (
                              <Row>
                                {record.addons && record.addons.length !== 0
                                  ? record.addons.map(addon => (
                                    <div key={addon._id}>{addon.description}</div>
                                  ))
                                  : 'None'}
                              </Row>
                            )}
                          />
                          <Column
                            title="Restaurant"
                            dataIndex="originalSellerName"
                            key="originalSellerName"
                          />
                        </Table>
                      </Row>
                      <Row style={{ margin: '20px 0px' }}>
                        <Row style={{ marginBottom: '2px 0px' }}>
                          {`Subtotal:\t${order.subtotal}`}
                        </Row>
                        <Row style={{ marginBottom: '2px 0px' }}>{`Tax:\t${order.tax}`}</Row>
                        <Row style={{ marginBottom: '2px 0px' }}>{`Tips:\t${order.tips}`}</Row>
                        <Row style={{ marginBottom: '2px 0px' }}>
                          {`Fee:\t${order.deliveryFee}`}
                        </Row>
                        <Row>{`Total:\t${order.total}`}</Row>
                      </Row>
                      <Row>
                        {order.driverId !== null && order.driverId !== undefined ? (
                          <div>
                            <div className="line">
                              <div className="text">Driver Name</div>
                              <div className="content">
                                {`${order.driver.firstName} ${order.driver.lastName}`}
                              </div>
                            </div>
                            <div className="line">
                              <div className="text">Driver Phone Number</div>
                              <div className="content">{order.driver.phoneNumber}</div>
                            </div>
                          </div>
                        ) : null}
                      </Row>
                      <Row style={{ marginTop: '10px' }}>
                        <OrderStatus order={order} />
                      </Row>
                    </Row>
                  </List.Item>
                )}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Pagination
            defaultCurrent={this.state.pageIndex}
            current={this.state.pageIndex}
            onChange={this.handlePageIndexChange}
            defaultPageSize={this.state.pageSize}
            pageSize={this.state.pageSize}
            total={this.state.total}
            showSizeChanger
            pageSizeOptions={['5', '10', '15', '20']}
            onShowSizeChange={this.handlePageSizeChange}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sellerId: state.user.sellerId,
});

export default connect(
  mapStateToProps,
  null,
)(SellerOrders);
