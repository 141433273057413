import React, { Component } from 'react';
import {
  Row, Col, Input, Button, message,
} from 'antd';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import axios from '../../../axios';
// import PropTypes from 'prop-types';

export class DriverApplication extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      address: '',
      longitude: null,
      latitude: null,
      radius: null,
      redirect: false,
    };
  }

  handleChangeAddress = (address) => {
    this.setState({
      address,
    });
  };

  handleSelect = (address) => {
    console.log(address);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then((latLng) => {
        console.log('Success', latLng);
        this.setState({
          address,
          longitude: latLng.lng,
          latitude: latLng.lat,
        });
      })
      .catch(error => console.error('Error', error));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async () => {
    const {
      radius, address, longitude, latitude,
    } = this.state;
    try {
      const response = await axios.post(
        '/drivers/create',
        {
          address,
          longitude,
          latitude,
          radius,
        },
        { headers: { 'access-token': localStorage.getItem('token') } },
      );
      // refresh current user
      await this.props.getCurrentUser();
      console.log(response);
      this.setState({ redirect: true });
    } catch (error) {
      message.error('Try again');
    }
  };

  render() {
    const {
      address, longitude, latitude, radius, redirect,
    } = this.state;

    if (this.props.isDriver) {
      return <Row>Sorry</Row>;
    }

    return (
      <Row>
        {redirect ? <Redirect to="/driver" /> : null}
        <h1>Driver Application</h1>
        <Row>
          <Col span={3}>Address</Col>
          <Col span={20}>
            <PlacesAutocomplete
              value={address}
              onChange={this.handleChangeAddress}
              onSelect={this.handleSelect}
              googleCallbackName="initTwo"
            >
              {({
                getInputProps, suggestions, getSuggestionItemProps, loading,
              }) => (
                <div>
                  <Input
                    {...getInputProps({
                      placeholder: 'Enter your location',
                    })}
                  />
                  <div className="autocompleteDropDown">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestionItemActive'
                        : 'suggestionItem';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            {/* <Input name="address" value={address} onChange={this.handleChange} /> */}
          </Col>
        </Row>
        <Row style={{ display: 'none' }}>
          <Col span={3}>Longitude</Col>
          <Col span={20}>
            <Input name="longitude" value={longitude} onChange={this.handleChange} />
          </Col>
        </Row>
        <Row style={{ display: 'none' }}>
          <Col span={3}>Latitude</Col>
          <Col span={20}>
            <Input name="latitude" value={latitude} onChange={this.handleChange} />
          </Col>
        </Row>
        <Row>
          <Col span={3}>Radius</Col>
          <Col span={20}>
            <Input name="radius" value={radius} onChange={this.handleChange} />
          </Col>
        </Row>
        <Row>
          <Button
            onClick={() => {
              this.handleSubmit();
            }}
          >
            Apply
          </Button>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  isDriver: state.user.isDriver,
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverApplication);
