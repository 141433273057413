import './App.css';

import React, { Component } from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { message } from 'antd';
import * as actions from './store/actions';
import Home from './containers/Home/Home';
import Layout from './helper/Layout/Layout';
import Logout from './containers/Logout/Logout';
// import asyncComponent from './helper/asyncComponent/asyncComponent';
import translation from './translation';

import Cart from './containers/Cart/Cart';
import Login from './containers/Login/Login';
import SignUp from './containers/SignUp/SignUp';
import Shops from './containers/Shops/Shops';
import Shop from './containers/Shop/Shop';
import Account from './containers/Account/Account';
import Seller from './containers/Seller/Seller';
import Application from './containers/Application/Application';
import Admin from './containers/Admin/Admin';
import Driver from './containers/Driver/Driver';
import PaymentStatus from './containers/PaymentStatus/PaymentStatus';
import NotFound from './components/NotFound/NotFound';

// const asyncCart = asyncComponent(() => import('./containers/Cart/Cart'));
// const asyncLogin = asyncComponent(() => import('./containers/Login/Login'));
// const asyncSignUp = asyncComponent(() => import('./containers/SignUp/SignUp'));
// const asyncShops = asyncComponent(() => import('./containers/Shops/Shops'));
// const asyncShop = asyncComponent(() => import('./containers/Shop/Shop'));
// const asyncAccount = asyncComponent(() => import('./containers/Account/Account'));
// const asyncSeller = asyncComponent(() => import('./containers/Seller/Seller'));
// const asyncApplication = asyncComponent(() => import('./containers/Application/Application'));
// const asyncAdmin = asyncComponent(() => import('./containers/Admin/Admin'));
// const asyncDriver = asyncComponent(() => import('./containers/Driver/Driver'));
// const asyncPaymentStatus =
// asyncComponent(() => import('./containers/PaymentStatus/PaymentStatus'));
// const asyncNotFound = asyncComponent(() => import('./components/NotFound/NotFound'));

class App extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    await this.props.checkLocale();
    await this.props.checkToken();
    if (this.props.hasToken) {
      try {
        await this.props.checkUser();
      } catch (error) {
        // console.log(error);
        if (error.message === 'refresh token') {
          // try to refresh token
          try {
            await this.props.refreshToken();
            await this.props.checkUser();
          } catch (err) {
            message.error('You need re-login your account');
          }
        } else if (error.message === 're-login') {
          await this.props.onLogout();
          await this.props.updateUser();
        } else {
          message.error('You need re-login your account');
        }
      }
    }
    this.setState({
      loading: false,
    });
  }

  render() {
    const {
      lang, hasToken, userId, isSeller, isAdmin, isDriver,
    } = this.props;

    // const PrivateRoute = ({ component: Component, ...rest }) => (
    //   <Route
    //     {...rest}
    //     render={props => (this.props.hasToken && this.props.userId === true ? <Component {...props} /> : null)
    //     }
    //   />
    // );

    let routes = (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/signUp" component={SignUp} />
        <Route path="/login" component={Login} />
        <Route path="/shops" component={Shops} />
        <Route path="/logout" component={Logout} />
        <Route path="/shop/:sellerId" component={Shop} />
        <Route path="/cart" component={Cart} />
        <Route path="/payment" component={PaymentStatus} />
        <Route component={NotFound} />
        <Redirect to="/" />
      </Switch>
    );

    if (hasToken && userId) {
      routes = (
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/logout" component={Logout} />
          <Route path="/shops" component={Shops} />
          <Route path="/shop/:sellerId" component={Shop} />
          {isSeller ? <Route path="/seller" component={Seller} /> : null}
          <Route path="/account" component={Account} />
          <Route path="/cart" component={Cart} />
          <Route path="/apply" component={Application} />
          <Route path="/payment/status" component={PaymentStatus} />
          {isAdmin ? <Route path="/admin" component={Admin} /> : null}
          {isDriver ? <Route path="/driver" component={Driver} /> : null}
          <Redirect from="/login" to="/" />
          <Redirect from="/signUp" to="/" />
          <Route component={NotFound} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
      <IntlProvider locale={lang} messages={translation[lang]}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {!this.state.loading ? <Layout>{routes}</Layout> : null}
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  hasToken: state.auth.token,
  userId: state.user.userId,
  isAdmin: state.user.isAdmin,
  isSeller: state.user.isSeller,
  isDriver: state.user.isDriver,
  lang: state.locale.lang || 'en',
});

const mapDispatchToProps = dispatch => ({
  checkToken: () => dispatch(actions.authCheckState()),
  checkUser: () => dispatch(actions.getCurrentUser()),
  checkLocale: () => dispatch(actions.checkLocale()),
  refreshToken: () => dispatch(actions.refreshAccessToken()),
  onLogout: () => dispatch(actions.logout()),
  updateUser: () => dispatch(actions.updateInfo(null)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
