import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Checkbox,
  Modal,
  Input,
  Popconfirm,
  InputNumber
} from "antd";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { connect } from "react-redux";
import _ from "lodash";
import CutoffTimes from "./CutoffTimes/CutoffTimes";
import axios from "../../../axios";

export class SellerProfile extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      // seller information
      address: "",
      canDelivery: false,
      canPickup: false,
      canShuttle: false,
      pickupPoints: [],
      sellerLongitude: null,
      sellerLatitude: null,
      phoneNumber: null,
      pictureURL: null,
      available: false,
      taxRate: null,
      newLocation: {
        pointId: null,
        address: "",
        longitude: null,
        latitude: null,
        hours: 0,
        minutes: 0,
        duration: 20,
        pictureURL: "",
        streetView: "",
        comment: "",
        commentChinese: ""
      },
      lunchHours: null,
      lunchMinutes: null,
      dinnerHours: null,
      dinnerMinutes: null,
      supperHours: null,
      supperMinutes: null,
      newLocationType: null,
      visible: false,
      sellerId: props.match.params.sellerId,
      userId: null,
      isOfficial: null,
      mode: "seller"
    };
  }

  async componentDidMount() {
    await this.getSeller();
  }

  async getSeller() {
    // console.log(this.state.sellerId);
    let response;
    if (this.state.sellerId) {
      response = await axios.get(`/sellers/seller/${this.state.sellerId}`);
      const { userId } = response.data.result;
      const userResponse = await axios.get(`/users/user/userId/${userId}`, {
        headers: { "access-token": localStorage.getItem("token") }
      });
      this.setState({
        isOfficial: userResponse.data.result.isOfficial,
        mode: "admin",
        userId
      });
    } else {
      response = await axios.get(`/sellers/seller/${this.props.sellerId}`);
    }
    const seller = response.data.result;

    let pickupPoints = _.orderBy(
      seller.pickupPoints,
      ["hours", "minutes"],
      ["asc", "asc"]
    );

    if (!seller.pickupPoints) {
      pickupPoints = [];
    }

    let { address } = seller;
    if (!seller.address) {
      address = "";
    }
    console.log(pickupPoints);

    this.setState({
      canDelivery: seller.canDelivery,
      canPickup: seller.canPickup,
      canShuttle: seller.canShuttle,
      pickupPoints,
      address,
      sellerLongitude: seller.location.coordinates[0],
      sellerLatitude: seller.location.coordinates[1],
      sellerName: seller.name,
      sellerChineseName: seller.nameChinese,
      pictureURL: seller.pictureURL,
      phoneNumber: _.replace(seller.phoneNumber, "+1", ""),
      available: seller.available,
      taxRate: seller.taxRate
    });

    if (seller.cutoffTimeLunch) {
      this.setState({
        lunchHours: seller.cutoffTimeLunch.hours,
        lunchMinutes: seller.cutoffTimeLunch.minutes,
        dinnerHours: seller.cutoffTimeDinner.hours,
        dinnerMinutes: seller.cutoffTimeDinner.minutes,
        supperHours: seller.cutoffTimeSupper.hours,
        supperMinutes: seller.cutoffTimeSupper.minutes
      });
    }
  }

  // Pickup points handles
  // ============================
  handleDeleteButton = async pointId => {
    const response = await axios.post(
      "/sellers/seller/delete/point",
      {
        pointId,
        mode: this.state.mode,
        userId: this.state.userId
      },
      { headers: { "access-token": localStorage.getItem("token") } }
    );
    console.log(response.data);
    await this.getSeller();
  };

  // Modal handles
  // =============================
  showModal = (type, point) => {
    this.setState({
      visible: true,
      newLocation: {
        pointId: null,
        address: "",
        longitude: null,
        latitude: null,
        hours: 0,
        minutes: 0,
        duration: null,
        pictureURL: "https://dropbee.s3-us-west-1.amazonaws.com/store.svg"
      },
      newLocationType: type
    });
    if (point !== null && point !== undefined) {
      this.setState({
        newLocation: {
          pointId: point._id,
          address: point.address,
          longitude: point.location.coordinates[0],
          latitude: point.location.coordinates[1],
          hours: point.hours,
          minutes: point.minutes,
          duration: point.duration,
          pictureURL: point.pictureURL,
          streetView: point.streetView,
          comment: point.comment,
          commentChinese: point.commentChinese
        }
      });
    }
  };

  handleOk = async e => {
    if (
      this.state.newLocationType === "shuttle" &&
      this.state.newLocation.pointId === null
    ) {
      const response = await axios.post(
        "/sellers/seller/add/point",
        {
          mode: this.state.mode,
          userId: this.state.userId,
          type: this.state.newLocationType,
          address: this.state.newLocation.address,
          longitude: this.state.newLocation.longitude,
          latitude: this.state.newLocation.latitude,
          hours: this.state.newLocation.hours,
          minutes: this.state.newLocation.minutes,
          duration: this.state.newLocation.duration,
          pictureURL: this.state.newLocation.pictureURL,
          streetView: this.state.newLocation.streetView,
          comment: this.state.newLocation.comment,
          commentChinese: this.state.newLocation.commentChinese
        },
        {
          headers: {
            "access-token": localStorage.getItem("token")
          }
        }
      );
      console.log(response);
    } else if (
      this.state.newLocationType === "shuttle" &&
      this.state.newLocation.pointId !== null
    ) {
      const response = await axios.post(
        "/sellers/seller/update/point",
        {
          mode: this.state.mode,
          userId: this.state.userId,
          pointId: this.state.newLocation.pointId,
          type: this.state.newLocationType,
          address: this.state.newLocation.address,
          longitude: this.state.newLocation.longitude,
          latitude: this.state.newLocation.latitude,
          hours: this.state.newLocation.hours,
          minutes: this.state.newLocation.minutes,
          duration: this.state.newLocation.duration,
          pictureURL: this.state.newLocation.pictureURL,
          streetView: this.state.newLocation.streetView,
          comment: this.state.newLocation.comment,
          commentChinese: this.state.newLocation.commentChinese
        },
        { headers: { "access-token": localStorage.getItem("token") } }
      );
      console.log(response);
    } else {
      const response = await axios.post(
        "/sellers/seller/add/point",
        {
          mode: this.state.mode,
          userId: this.state.userId,
          type: this.state.newLocationType,
          address: this.state.newLocation.address,
          longitude: this.state.newLocation.longitude,
          latitude: this.state.newLocation.latitude
        },
        {
          headers: {
            "access-token": localStorage.getItem("token")
          }
        }
      );
      console.log(response);
    }

    this.setState({
      visible: false
    });
    await this.getSeller();
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  handleSelect = address => {
    console.log(address);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log("Success", latLng);
        this.setState({
          newLocation: {
            ...this.state.newLocation,
            address,
            longitude: latLng.lng,
            latitude: latLng.lat
          }
        });
      })
      .catch(error => console.error("Error", error));
  };

  handleChangeAddress = address => {
    this.setState({
      newLocation: {
        ...this.state.newLocation,
        address
      }
    });
  };

  handleHoursChange = value => {
    this.setState({
      newLocation: {
        ...this.state.newLocation,
        hours: value
      }
    });
  };

  handleMinutesChange = value => {
    this.setState({
      newLocation: {
        ...this.state.newLocation,
        minutes: value
      }
    });
  };

  // Other handles
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({
      newLocation: {
        ...this.state.newLocation,
        [name]: value
      }
    });
  };

  handlePictureURLChange = e => {
    this.setState({
      newLocation: {
        ...this.state.newLocation,
        pictureURL: e.target.value
      }
    });
  };

  // Delivery type handles
  // =========================
  handleCheckedChange = e => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  handleSaveDelivery = async () => {
    await axios.post(
      "/sellers/seller/update/delivery",
      {
        mode: this.state.mode,
        userId: this.state.userId,
        canDelivery: this.state.canDelivery,
        canPickup: this.state.canPickup,
        canShuttle: this.state.canShuttle
      },
      { headers: { "access-token": localStorage.getItem("token") } }
    );
    await this.getSeller();
  };

  // Information handles
  handleSaveInformation = async () => {
    const response = await axios.post(
      "/sellers/seller/update/information",
      {
        mode: this.state.mode,
        userId: this.state.userId,
        sellerName: this.state.sellerName,
        sellerChineseName: this.state.sellerChineseName,
        taxRate: this.state.taxRate,
        pictureURL: this.state.pictureURL,
        phoneNumber: `+1${this.state.phoneNumber}`,
        available: this.state.available
      },
      { headers: { "access-token": localStorage.getItem("token") } }
    );
    console.log(response);
    await this.getSeller();
  };

  render() {
    const popConfirm = id => (
      <Popconfirm
        title="Are you sure delete this point?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => {
          this.handleDeleteButton(id);
        }}
      >
        <Button size="small">Delete</Button>
      </Popconfirm>
    );

    const locationModal = (
      <Modal
        title="Search Address"
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        destroyOnClose
        maskClosable={false}
      >
        <Row>
          <PlacesAutocomplete
            className="searchInput"
            value={this.state.newLocation.address}
            onChange={this.handleChangeAddress}
            onSelect={this.handleSelect}
            googleCallbackName="initTwo"
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => (
              <div>
                <Input
                  className="searchInput"
                  {...getInputProps({
                    placeholder: "Enter your location",
                    className: "searchInput"
                  })}
                />
                <div className="autocompleteDropDown">
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? "suggestionItemActive"
                      : "suggestionItem";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Row>
        <Row>
          <Col style={{ margin: "10px 0" }}>
            <Input
              value={this.state.newLocation.longitude}
              name="longitude"
              onChange={this.handleInputChange}
              placeholder="Longitude"
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: "10px 0" }}>
            <Input
              value={this.state.newLocation.latitude}
              name="latitude"
              onChange={this.handleInputChange}
              placeholder="Latitude"
            />
          </Col>
        </Row>
        {this.state.newLocationType === "shuttle" ? (
          <Row>
            <Row style={{ margin: "10px 0" }}>
              <Col span={9}>
                Hours:
                <InputNumber
                  min={0}
                  max={23}
                  name="hours"
                  value={this.state.newLocation.hours}
                  onChange={this.handleHoursChange}
                />
              </Col>
              <Col span={9}>
                Minutes:
                <InputNumber
                  min={0}
                  max={60}
                  name="minutes"
                  value={this.state.newLocation.minutes}
                  onChange={this.handleMinutesChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span>Duration</span>
              </Col>
              <Col>
                <Input
                  name="duration"
                  value={this.state.newLocation.duration}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Row style={{ margin: "10px 0" }}>
              <Col>
                <span>Picture URL</span>
              </Col>
              <Col>
                <Input
                  name="pictureURL"
                  value={this.state.newLocation.pictureURL}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Row style={{ margin: "10px 0" }}>
              <Col>Street View</Col>
              <Col>
                <Input
                  name="streetView"
                  value={this.state.newLocation.streetView}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>Comment</Col>
              <Col>
                <Input.TextArea
                  name="comment"
                  value={this.state.newLocation.comment}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>中文注释</Col>
              <Col>
                <Input.TextArea
                  name="commentChinese"
                  value={this.state.newLocation.commentChinese}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
          </Row>
        ) : null}
      </Modal>
    );

    return (
      <div>
        <h1>Seller profile</h1>
        <Row>
          <Col span={24}>
            <h2>Shop Address</h2>
          </Col>
          <Col>
            <div>{this.state.address}</div>
          </Col>
          <Col>
            <div>{`Longitude: ${this.state.sellerLongitude}`}</div>
            <div>{`Latitude: ${this.state.sellerLatitude}`}</div>
          </Col>
          <Col span={24}>
            <Button
              style={{ marginTop: "10px" }}
              onClick={() => {
                this.showModal("delivery");
              }}
            >
              Change
            </Button>
          </Col>
        </Row>

        <Row style={{ margin: "20px 0px" }}>
          <Col span={24}>
            <h2>Delivery</h2>
          </Col>

          <Col>
            <Checkbox
              name="canDelivery"
              checked={this.state.canDelivery}
              onChange={this.handleCheckedChange}
            >
              Can delivery?
            </Checkbox>
            <Checkbox
              name="canPickup"
              checked={this.state.canPickup}
              onChange={this.handleCheckedChange}
            >
              Can pickup?
            </Checkbox>
            {this.props.isOfficial || this.state.isOfficial ? (
              <Checkbox
                name="canShuttle"
                checked={this.state.canShuttle}
                onChange={this.handleCheckedChange}
              >
                Can shuttle?
              </Checkbox>
            ) : null}
            <Col span={24}>
              <Button
                style={{ marginTop: "10px" }}
                onClick={() => {
                  this.handleSaveDelivery();
                }}
              >
                Save
              </Button>
            </Col>
          </Col>
        </Row>
        <Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={24}>
              <h2>Information</h2>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={4}>Name</Col>
            <Col span={20}>
              <Input
                value={this.state.sellerName}
                name="sellerName"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={4}>Chinese Name</Col>
            <Col span={20}>
              <Input
                value={this.state.sellerChineseName}
                name="sellerChineseName"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={4}>Available</Col>
            <Col span={20}>
              <Checkbox
                name="available"
                checked={this.state.available}
                onChange={this.handleCheckedChange}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={4}>Picture URL</Col>
            <Col span={20}>
              <Input
                value={this.state.pictureURL}
                name="pictureURL"
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={4}>Phone Number</Col>
            <Col span={20}>
              <Input
                name="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={4}>Tax Rate</Col>
            <Col span={20}>
              <Input
                placeholder="Such as, 0.0925 is 9.25%"
                name="taxRate"
                value={this.state.taxRate}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Button
              onClick={() => {
                this.handleSaveInformation();
              }}
            >
              Save
            </Button>
          </Row>
        </Row>
        {this.props.isOfficial || this.state.isOfficial ? (
          <Row style={{ marginTop: "20px" }}>
            <Col span={24}>
              <h2>Pickup points</h2>
            </Col>
            <Col>
              {this.state.pickupPoints.map(point => (
                <Row key={point._id} style={{ marginTop: "10px" }}>
                  <Col
                    span={18}
                  >{`${point.address} - ${point.hours}:${point.minutes}`}</Col>
                  <Col span={3}>
                    <Button
                      size="small"
                      onClick={() => {
                        this.showModal("shuttle", point);
                      }}
                    >
                      Change
                    </Button>
                  </Col>
                  <Col span={3}>{popConfirm(point._id)}</Col>
                </Row>
              ))}
              <Button
                style={{ marginTop: "10px" }}
                onClick={() => {
                  this.showModal("shuttle");
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
        ) : null}
        <CutoffTimes
          handleChange={this.handleChange}
          isOfficial={
            this.props.isOfficial
              ? this.props.isOfficial
              : this.state.isOfficial
          }
          mode={this.state.mode}
          userId={this.state.userId}
          lunchHours={this.state.lunchHours}
          lunchMinutes={this.state.lunchMinutes}
          dinnerHours={this.state.dinnerHours}
          dinnerMinutes={this.state.dinnerMinutes}
          supperHours={this.state.supperHours}
          supperMinutes={this.state.supperMinutes}
        />
        {locationModal}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sellerId: state.user.sellerId,
  isAdmin: state.user.isAdmin,
  isOfficial: state.user.isOfficial
});

export default connect(mapStateToProps)(SellerProfile);
