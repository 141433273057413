import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  // address bar information
  address: '',
  longitude: null,
  latitude: null,
  deliveryType: 'shuttle',
  shuttleAddress: '',
  shuttleAddressLongitude: '',
  shuttleAddressLatitude: '',
  hours: null,
  minutes: null,
  // cart information
  cartAddress: '',
  cartLongitude: null,
  cartLatitude: null,
  cartDeliveryType: '',
  cartDeliveryDate: null,
  cartHours: '',
  cartMinutes: '',
  cartLastUpdate: null,
  items: [],
  itemsCount: 0,
  subtotalPrice: 0,
  totalPrice: 0,
  updatedAt: null,
  // money
  subtotal: 0,
  deliveryFee: 0,
  tax: 0,
  total: 0,
};

export const updateBilling = (state, action) => {
  const updatedState = {
    subtotal: action.subtotal,
    deliveryFee: action.deliveryFee,
    tax: action.tax,
    total: action.total,
  };
  return updateObject(state, updatedState);
};

export const updateItems = (state, action) => {
  const updatedState = {
    items: action.items,
    itemsCount: action.itemsCount,
    cartLastUpdate: action.cartLastUpdate,
  };
  return updateObject(state, updatedState);
};

export const updateAddress = (state, action) => {
  const updatedState = {
    address: action.address,
    longitude: action.longitude,
    latitude: action.latitude,
  };
  return updateObject(state, updatedState);
};

export const updateTime = (state, action) => {
  const updatedState = {
    hours: Number(action.hours),
    minutes: Number(action.minutes),
  };
  return updateObject(state, updatedState);
};

export const updateCartTime = (state, action) => {
  const updatedState = {
    cartHours: Number(action.cartHours),
    cartMinutes: Number(action.cartMinutes),
  };
  return updateObject(state, updatedState);
};

export const updateCartAddress = (state, action) => {
  const updatedState = {
    cartAddress: action.cartAddress,
    cartLongitude: action.cartLongitude,
    cartLatitude: action.cartLatitude,
  };
  return updateObject(state, updatedState);
};

export const updateShuttleAddress = (state, action) => {
  const updatedState = {
    shuttleAddress: action.shuttleAddress,
    shuttleAddressLongitude: action.shuttleAddressLongitude,
    shuttleAddressLatitude: action.shuttleAddressLatitude,
  };
  return updateObject(state, updatedState);
};

export const updateDeliveryType = (state, action) => {
  const updatedState = {
    deliveryType: action.deliveryType,
  };
  return updateObject(state, updatedState);
};

export const updateCartDeliveryType = (state, action) => {
  const updatedState = {
    cartDeliveryType: action.cartDeliveryType,
  };
  return updateObject(state, updatedState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CART_UPDATE_ADDRESS:
      return updateAddress(state, action);
    case actionTypes.CART_UPDATE_DELIVERY_TYPE:
      return updateDeliveryType(state, action);
    case actionTypes.CART_UPDATE_CART_ADDRESS:
      return updateCartAddress(state, action);
    case actionTypes.CART_UPDATE_CART_DELIVERY_TYPE:
      return updateCartDeliveryType(state, action);
    case actionTypes.CART_UPDATE_ITEMS:
      return updateItems(state, action);
    case actionTypes.CART_UPDATE_SHUTTLE_ADDRESS:
      return updateShuttleAddress(state, action);
    case actionTypes.CART_UPDATE_CART_TIME:
      return updateCartTime(state, action);
    case actionTypes.CART_UPDATE_TIME:
      return updateTime(state, action);
    case actionTypes.CART_UPDATE_BILLING:
      return updateBilling(state, action);
    default:
      return state;
  }
};

export default reducer;
