export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const USER_UPDATE_PHONE_NUMBER = 'USER_UPDATE_PHONE_NUMBER';
export const USER_UPDATE_ADDRESS = 'USER_UPDATE_ADDRESS';
export const USER_UPDATE_INFO = 'USER_UPDATE_INFO';

export const CART_UPDATE_ADDRESS = 'CART_UPDATE_ADDRESS';
export const CART_UPDATE_SHUTTLE_ADDRESS = 'CART_UPDATE_SHUTTLE_ADDRESS';
export const CART_UPDATE_CART_ADDRESS = 'CART_UPDATE_CART_ADDRESS';
export const CART_UPDATE_DELIVERY_TYPE = 'CART_UPDATE_DELIVERY_TYPE';
export const CART_UPDATE_CART_DELIVERY_TYPE = 'CART_UPDATE_CART_DELIVERY_TYPE';
export const CART_UPDATE_ITEMS = 'CART_UPDATE_ITEMS';
export const CART_UPDATE_TIME = 'CART_UPDATE_TIME';
export const CART_UPDATE_CART_TIME = 'CART_UPDATE_CART_TIME';
export const CART_UPDATE_BILLING = 'CART_UPDATE_BILLING';

export const SELLER_UPDATE_INFO = 'SELLER_UPDATE_INFO';

export const LOCALE_SET = 'LOCALE_SET';
