import axios from '../../axios';
import * as actionTypes from './actionTypes';
import { CLIENT_ID, CLIENT_SECRET } from '../../config';

export const authStart = () => ({
  type: actionTypes.AUTH_START,
});

export const authSuccess = (token, tokenExpires, refreshToken, refreshTokenExpires) => ({
  type: actionTypes.AUTH_SUCCESS,
  token,
  tokenExpires,
  refreshToken,
  refreshTokenExpires,
});

export const authFail = error => ({
  type: actionTypes.AUTH_FAIL,
  error,
});

export const logout = () => {
  localStorage.clear();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = expirationTime => (dispatch) => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationTime * 1000);
};

export const getAccessToken = code => async (dispatch) => {
  const response = await axios.post('/oauth2/token', {
    clientId: CLIENT_ID,
    clientSecret: CLIENT_SECRET,
    code,
    grantType: 'authorizationCode',
  });
  console.log(response);
  localStorage.setItem('token', response.data.result.accessToken);
  localStorage.setItem('tokenExpires', response.data.result.accessTokenExpires);
  localStorage.setItem('refreshToken', response.data.result.refreshToken);
  localStorage.setItem('refreshTokenExpires', response.data.result.refreshTokenExpires);
  return dispatch(
    authSuccess(
      response.data.result.accessToken,
      response.data.result.accessTokenExpires,
      response.data.result.refreshToken,
      response.data.result.refreshTokenExpires,
    ),
  );
};

export const refreshAccessToken = () => async (dispatch) => {
  const refreshToken = localStorage.getItem('refreshToken');
  const response = await axios.post('/oauth2/token/refresh', {
    clientId: CLIENT_ID,
    clientSecret: CLIENT_SECRET,
    refreshToken,
    grantType: 'refreshToken',
  });
  console.log(response);
  localStorage.setItem('token', response.data.result.accessToken);
  localStorage.setItem('tokenExpires', response.data.result.accessTokenExpires);
  localStorage.setItem('refreshToken', response.data.result.refreshToken);
  localStorage.setItem('refreshTokenExpires', response.data.result.refreshTokenExpires);
  return dispatch(
    authSuccess(
      response.data.result.accessToken,
      response.data.result.accessTokenExpires,
      response.data.result.refreshToken,
      response.data.result.refreshTokenExpires,
    ),
  );
};

export const auth = (phoneNumber, smsCode) => async (dispatch) => {
  dispatch(authStart());
  try {
    const codeResponse = await axios.post('/oauth2/code', {
      phoneNumber,
      smsCode,
      clientId: CLIENT_ID,
      responseType: 'code',
    });
    console.log(codeResponse);
    const { code } = codeResponse.data.result;
    return dispatch(getAccessToken(code));
  } catch (error) {
    console.log('error happened');
    console.table(error.response.data);
    dispatch(authFail(error));
    throw error;
  }
};

export const setAuthRedirectPath = path => ({
  type: actionTypes.SET_AUTH_REDIRECT_PATH,
  path,
});

export const authCheckState = () => (dispatch) => {
  const token = localStorage.getItem('token');
  const tokenExpires = localStorage.getItem('tokenExpires');
  const refreshToken = localStorage.getItem('refreshToken');
  const refreshTokenExpires = localStorage.getItem('refreshTokenExpires');
  if (!token) {
    // dispatch(logout());
  } else {
    return dispatch(authSuccess(token, tokenExpires, refreshToken, refreshTokenExpires));
  }
};
