/* global google */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { message } from 'antd';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Zhi1 } from 'zhi1';
import * as actions from '../../store/actions';
import Heading from '../../components/HomePage/Heading/Heading';

export class Home extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      redirect: false,
      rerollDishURLs: [],
      address: '',
    };
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    await this.props.checkDeliveryType();
    await this.props.checkAddress();
    // read local address
    this.setState({
      address: this.props.address,
    });

    // shuffle dishes svg pic
    let dishArray = Array.from(Array(21).keys());
    dishArray = _.drop(dishArray, 1);
    const shuffleArray = _.shuffle(dishArray);
    const firstThreeDish = [
      `https://dropbee.s3-us-west-1.amazonaws.com/homepageDishesIcons/${shuffleArray[0]}.svg`,
      `https://dropbee.s3-us-west-1.amazonaws.com/homepageDishesIcons/${shuffleArray[1]}.svg`,
      `https://dropbee.s3-us-west-1.amazonaws.com/homepageDishesIcons/${shuffleArray[2]}.svg`,
    ];
    this.setState({
      rerollDishURLs: firstThreeDish,
    });
  }

  handleAddressChange = (address) => {
    this.setState({
      address,
    });
  };

  handleDeliveryTypeChange = (type) => {
    console.log(type);
    this.props.updateDeliveryType(type);
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        // console.log('Success', latLng);
        this.setState({
          address,
        });
        this.props.updateAddress(address, latLng.lng, latLng.lat);
      })
      .catch((error) => console.error('Error', error));
  };

  handleSearchButton = () => {
    if (
      (this.props.address !== '' && this.props.latitude !== null) ||
      this.props.latitude !== null
    ) {
      Zhi1('demoData-dropbee').feTracking({
        id: 'Google_Address', // 不可改, 唯一id
        value: '成功跳转', // 自定义, 具体想保存的元信息,
        increment: 1, // 自定义,距离的增值, 默认为1, 如记录收入等数据时按需加入
        labelRecord: true, // 是否要将此点作为标签记录在录像里
      });

      this.setState({ redirect: true });
    } else {
      message.warn('Oops, please enter your address to search.');
    }
  };

  getCurrentLocation = () => {
    this.setState({
      address: 'Loading...',
    });
    // this.props.updateAddress('loading...');
    if ('geolocation' in navigator) {
      // check if geolocation is supported/enabled on current browser
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geocoder = new google.maps.Geocoder();
          const location = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          geocoder.geocode({ latLng: location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                this.setState({
                  address: results[1].formatted_address,
                });
                this.props.updateAddress(
                  results[1].formatted_address,
                  position.coords.longitude,
                  position.coords.latitude
                );
              }
            }
          });
        },
        () => {
          message.error(
            'Cannot get location, check your network or location permission'
          );
        }
      );
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div>
        {this.state.redirect ? <Redirect to="/shops" /> : null}
        <Heading
          address={this.state.address}
          handleAddressChange={this.handleAddressChange}
          handleDeliveryTypeChange={this.handleDeliveryTypeChange}
          handleChange={this.handleChange}
          handleSelect={this.handleSelect}
          handleSearchButton={this.handleSearchButton}
          getCurrentLocation={this.getCurrentLocation}
          deliveryType={this.props.deliveryType}
          rerollDishURLs={this.state.rerollDishURLs}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  address: state.cart.address,
  longitude: state.cart.longitude,
  latitude: state.cart.latitude,
  deliveryType: state.cart.deliveryType,
});

const mapDispatchToProps = (dispatch) => ({
  checkAddress: () => dispatch(actions.checkAddress()),
  updateAddress: (add, lon, lat) =>
    dispatch(actions.updateAddress(add, lon, lat)),
  checkDeliveryType: () => dispatch(actions.checkDeliveryType()),
  updateDeliveryType: (type) => dispatch(actions.updateDeliveryType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
