import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  sellerId: null,
  address: null,
  longitude: null,
  latitude: null,
  name: null,
  nameChinese: null,
  phoneNumber: null,
  approval: null,
};

export const updateSellerInfo = (state, action) => {
  const updatedState = {
    sellerId: action.sellerInfo._id,
    address: action.sellerInfo.address,
    longitude: action.sellerInfo.location.coordinates[0],
    latitude: action.sellerInfo.location.coordinates[1],
    name: action.sellerInfo.name,
    nameChinese: action.sellerInfo.nameChinese,
    phoneNumber: action.sellerInfo.phoneNumber,
    approval: action.sellerInfo.approval,
  };
  return updateObject(state, updatedState);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELLER_UPDATE_INFO:
      return updateSellerInfo(state, action);
    default:
      return state;
  }
};
