import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  userId: null,
  phoneNumber: null,
  isSeller: false,
  sellerId: null,
  isAdmin: false,
  isDriver: false,
  driverId: null,
  isOfficial: false,
  email: null,
  firstName: null,
  lastName: null,
};

export const updateUserInfo = (state, action) => {
  const updatedState = {
    userId: action.userId,
    isAdmin: action.isAdmin,
    isSeller: action.isSeller,
    isOfficial: action.isOfficial,
    isDriver: action.isDriver,
    driverId: action.driverId,
    sellerId: action.sellerId,
    firstName: action.firstName,
    lastName: action.lastName,
    email: action.email,
    phoneNumber: action.phoneNumber,
  };
  return updateObject(state, updatedState);
};

export const updatePhoneNumber = (state, action) => {
  const updatedState = {
    phoneNumber: action.phoneNumber,
  };
  return updateObject(state, updatedState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_UPDATE_PHONE_NUMBER:
      return updatePhoneNumber(state, action);
    case actionTypes.USER_UPDATE_INFO:
      return updateUserInfo(state, action);
    default:
      return state;
  }
};

export default reducer;
