import { message } from 'antd';
import * as actionTypes from './actionTypes';

import axios from '../../axios';

export const updateInfo = (userInfo) => {
  if (userInfo === null) {
    return {
      type: actionTypes.USER_UPDATE_INFO,
      userId: null,
      isAdmin: false,
      isSeller: false,
      isDriver: false,
      isOfficial: false,
      sellerId: null,
      driverId: null,
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
    };
  }
  return {
    type: actionTypes.USER_UPDATE_INFO,
    userId: userInfo.id,
    isAdmin: userInfo.isAdmin,
    isSeller: userInfo.isSeller,
    isOfficial: userInfo.isOfficial,
    isDriver: userInfo.isDriver,
    driverId: userInfo.driverId,
    sellerId: userInfo.sellerId,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    email: userInfo.email,
    phoneNumber: userInfo.phoneNumber,
  };
};

export const updatePhoneNumber = (phoneNumber) => {
  localStorage.setItem('phoneNumber', phoneNumber);
  return {
    type: actionTypes.USER_UPDATE_PHONE_NUMBER,
    phoneNumber,
  };
};

export const getCurrentUser = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get('/users/current/user', {
      headers: { 'access-token': token },
    });
    // console.log(response.data.result);
    const user = response.data.result;
    const userId = user._id;
    localStorage.setItem('userId', userId);
    const userInfo = user;
    userInfo.id = userId;
    dispatch(updateInfo(userInfo));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        // check data
        if (error.response.data) {
          console.log(error.response.data);
          if (error.response.data.code === 104002) {
            // request token
            throw new Error('refresh token');
          } else if (error.response.data.code === 104001) {
            message.error('You need re-login your account');
            throw new Error('re-login');
          } else {
            message.error('You need re-login your account');
          }
        }
      }
    } else {
      console.log(error.message);
      if (error.message === 'Network Error') {
        message.error('Network Error, please try again.');
      } else {
        message.error('You need re-login your account');
      }
    }
  }
};

export const getUserByPhoneNumber = phoneNumber => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`/users/user/phoneNumber/${phoneNumber}`, {
      headers: { 'access-token': token },
    });
    const user = response.data.result;
    // console.log(response.data);
    const userId = user._id;
    localStorage.setItem('userId', userId);
    // format user id
    const userInfo = user;
    userInfo.id = userId;
    dispatch(updateInfo(userInfo));
  } catch (error) {
    console.error(error);
    console.log(error.message);
    if (error.message === 'Request failed with status code 401') {
      // clear localStorage
      localStorage.clear();
      message.error('Please refresh page and login again');
    } else if (error.message === 'Network Error') {
      message.error('Network error, please try again.');
    }
  }
};
