import React, { Component } from 'react';
import { Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import SignUpForm from '../../components/HomePage/SignUpForm/SignUpForm';
import axios from '../../axios';
import * as actions from '../../store/actions';
import { PHONE_NUMBER } from '../../config';

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: PHONE_NUMBER,
      smsCode: '',
      firstName: '',
      lastName: '',
      email: '',
      counter: 0,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  submitUserInformation = async () => {
    await axios.post(
      '/users/user/update',
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        isSignUp: true,
      },
      {
        headers: {
          'access-token': localStorage.getItem('token'),
        },
      },
    );
  };

  handleSubmit = async (phoneNumber, smsCode, firstName, lastName) => {
    const { auth, getCurrentUser } = this.props;
    if (
      this.state.firstName === ''
      || this.state.lastName === ''
      || this.state.email === ''
      || this.state.smsCode === ''
      || this.state.phoneNumber === ''
    ) {
      message.warn('Please fill out all.');
    } else {
      await auth(phoneNumber, smsCode);
      await this.submitUserInformation();
      await getCurrentUser();
    }
  };

  handleSendSMS = async (phoneNumber) => {
    if (!phoneNumber.match(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/)) {
      message.warn('Phone number is wrong.');
    } else {
      const response = await axios.post('/users/sms', { phoneNumber });
      console.log(response.data.result);
      if (process.env.NODE_ENV === 'development') {
        if (response.data.code === 101006) {
          message.success(`Please wait ${response.data.result.time} seconds.`);
        } else if (response.data.code === 200001) {
          message.success(response.data.result);
        }
      }

      if (response.data.code === 200001) {
        this.props.updatePhoneNumber(phoneNumber);
        this.setState({
          counter: 30,
        });
      } else if (response.data.code === 101006) {
        this.setState({ counter: response.data.result.time });
      }

      this.interval = setInterval(() => {
        if (this.state.counter <= 0) {
          clearInterval(this.interval);
        }
        this.tick();
      }, 1000);
    }
  };

  // accessToken:
  // data_access_expiration_time: 1562321588
  // email: "blueandhack@gmail.com"
  // expiresIn: 6412
  // first_name: "Yoga"
  // id: "2649145935127337"
  // last_name: "Lin"
  // reauthorize_required_in: 7776000
  // signedRequest:
  // userID: "2649145935127337"

  handleResponseFacebook = async (response) => {
    const { getAccessToken, getCurrentUser } = this.props;

    console.log(response);
    const firstName = response.first_name;
    const lastName = response.last_name;
    const email = response.email;
    const accessToken = response.accessToken;
    const userID = response.userID;
    try {
      const thirdPartResponse = await axios.post('/users/sso', {
        platform: 'facebook',
        userAccessToken: accessToken,
        userID,
        firstName,
        lastName,
        email,
      });
      console.log(thirdPartResponse);
      await getAccessToken(thirdPartResponse.data.result.code);
      await getCurrentUser();
      // when got code try to login
    } catch (error) {
      message.error('Sorry, can not login with Facebook.');
    }
  };

  tick = () => {
    this.setState(prevState => ({
      counter: prevState.counter - 1,
    }));
  };

  interval;

  render() {
    const {
      phoneNumber, smsCode, firstName, lastName, email, counter,
    } = this.state;

    return (
      <div style={{ marginTop: '10%' }}>
        <Row type="flex" justify="center" align="middle">
          <Col>
            <SignUpForm
              phoneNumber={phoneNumber}
              smsCode={smsCode}
              firstName={firstName}
              lastName={lastName}
              email={email}
              counter={counter}
              handleChange={this.handleChange}
              handleSendSMS={this.handleSendSMS}
              handleSubmit={this.handleSubmit}
              handleResponseFacebook={this.handleResponseFacebook}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.auth.error,
});

const mapDispatchToProps = dispatch => ({
  updatePhoneNumber: phoneNumber => dispatch(actions.updatePhoneNumber(phoneNumber)),
  auth: (phoneNumber, smsCode) => dispatch(actions.auth(phoneNumber, smsCode)),
  getAccessToken: code => dispatch(actions.getAccessToken(code)),
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUp);
