import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import {
  createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import thunk from 'redux-thunk';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import ReactGA from 'react-ga';
import userReducer from './store/reducers/user';
import authReducer from './store/reducers/auth';
import cartReducer from './store/reducers/cart';
import sellerReducer from './store/reducers/seller';
import localeReducer from './store/reducers/locale';

import App from './App';
import * as serviceWorker from './serviceWorker';

// const composeEnhancers = process.env.NODE_ENV === 'development'
//   ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//   : null || compose;

// Add Google Analytics
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-115763464-5');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

addLocaleData(en);
addLocaleData(zh);

let composeEnhancers = compose;
const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

if (process.env.NODE_ENV === 'development' && typeof composeWithDevToolsExtension === 'function') {
  composeEnhancers = composeWithDevToolsExtension;
}

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  cart: cartReducer,
  seller: sellerReducer,
  locale: localeReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
