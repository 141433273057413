/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Layout, Menu, Row, Col, Icon, Badge,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import textLogo from '../../../assets/images/logo-text.png';
import devLogo from '../../../assets/images/Dropbee_Dev_Logo.png';
// import menuSvg from '../../../assets/images/icons/menu.svg';
import languageIcon from '../../../assets/images/icons/language.png';
import './HomeNav.css';

const { Header } = Layout;
const { SubMenu } = Menu;

const HomeNav = (props) => {
  const settingsTitle = (
    <span>
      <Icon type="user" />
      Hi,
      {props.firstName || 'Hey'}
    </span>
  );

  const menuSetting = <Icon type="user" />;

  return (
    <Header className="header" style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
      <Row className="header_row" type="flex" justify="center" align="middle">
        <Col xs={0} sm={1} md={1} lg={1} xl={1} xxl={1} />
        <Col xs={10} sm={9} md={9} lg={9} xl={9} xxl={9} className="title">
          <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
            {process.env.NODE_ENV === 'production' ? (
              <img className="logoImg" src={textLogo} alt="Dropbee" />
            ) : (
              <img className="logoImg" src={devLogo} alt="Dropbee" />
            )}
          </Link>
        </Col>
        <Col xs={14} sm={13} md={13} lg={13} xl={13} xxl={13}>
          <Row type="flex" align="middle" justify="end">
            {!props.isAuthenticated ? (
              <div>
                <Row>
                  <Col xs={0} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <Link
                      to="/cart"
                      style={{ textDecoration: 'none', color: 'black', marginRight: '24px' }}
                    >
                      <Badge count={props.cartItemsCount} offset={[10, -3]}>
                        <Icon type="shopping" />
                      </Badge>
                    </Link>
                  </Col>
                  <Col
                    xs={0}
                    sm={16}
                    md={16}
                    lg={16}
                    xl={16}
                    xxl={16}
                    style={{ padding: '0px 10px' }}
                  >
                    <Icon type="user" />
                    <Link
                      to="/login"
                      style={{ textDecoration: 'none', color: 'black', marginLeft: '5px' }}
                    >
                      <FormattedMessage id="login" defaultMessage="Login" />
                    </Link>
                    {' / '}
                    <Link to="/signUp" style={{ textDecoration: 'none', color: 'black' }}>
                      <FormattedMessage id="signup" defaultMessage="Login" />
                    </Link>
                  </Col>
                  <Col xs={0} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <button
                      type="submit"
                      style={{
                        border: 'none',
                        padding: '0',
                        background: 'none',
                        cursor: 'pointer',
                        outline: '0',
                      }}
                      onClick={() => {
                        props.changeLanguageButton();
                      }}
                    >
                      <img src={languageIcon} alt="language" style={{ maxWidth: '32px' }} />
                    </button>
                  </Col>
                  <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <Menu mode="horizontal">
                      <Menu.Item key="cart">
                        <Link to="/cart">
                          <Badge count={props.cartItemsCount} offset={[3, -2]}>
                            <Icon type="shopping" />
                          </Badge>
                        </Link>
                      </Menu.Item>
                      <SubMenu title={menuSetting}>
                        <Menu.Item>
                          <Link to="/login" style={{ textDecoration: 'none', color: 'black' }}>
                            <FormattedMessage id="login" defaultMessage="Login" />
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to="/signUp" style={{ textDecoration: 'none', color: 'black' }}>
                            <FormattedMessage id="signup" defaultMessage="Login" />
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <button
                            type="submit"
                            style={{
                              border: 'none',
                              padding: '0',
                              background: 'none',
                              cursor: 'pointer',
                              outline: '0',
                            }}
                            onClick={() => {
                              props.changeLanguageButton();
                            }}
                          >
                            <img src={languageIcon} alt="language" style={{ maxWidth: '32px' }} />
                            <FormattedMessage id="switchLanguage" defaultMessage="Language" />
                          </button>
                        </Menu.Item>
                      </SubMenu>
                    </Menu>
                  </Col>
                </Row>
              </div>
            ) : (
              <Menu mode="horizontal">
                <Menu.Item key="cart">
                  <Link to="/cart">
                    <Badge count={props.cartItemsCount} offset={[3, -2]}>
                      <Icon type="shopping" />
                    </Badge>
                  </Link>
                </Menu.Item>
                <SubMenu title={settingsTitle}>
                  <Menu.Item key="account">
                    <Link to="/account">
                      {' '}
                      <FormattedMessage id="account" defaultMessage="signup" />
                    </Link>
                  </Menu.Item>

                  {props.isSeller ? (
                    <Menu.Item key="seller">
                      <Link to="/seller">
                        {' '}
                        <FormattedMessage id="seller" defaultMessage="sign up" />
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {props.isDriver ? (
                    <Menu.Item key="driver">
                      <Link to="/driver">Driver</Link>
                    </Menu.Item>
                  ) : null}
                  {props.isAdmin ? (
                    <Menu.Item key="admin">
                      <Link to="/admin"> Admin</Link>
                    </Menu.Item>
                  ) : null}
                  <Menu.Item>
                    <button
                      type="submit"
                      style={{
                        border: 'none',
                        padding: '0',
                        background: 'none',
                        cursor: 'pointer',
                        outline: '0',
                      }}
                      onClick={() => {
                        props.changeLanguageButton();
                      }}
                    >
                      <img src={languageIcon} alt="language" style={{ maxWidth: '32px' }} />
                      <FormattedMessage id="switchLanguage" defaultMessage="Language" />
                    </button>
                  </Menu.Item>
                  <Menu.Item key="logout">
                    <Link to="/logout">
                      {' '}
                      <FormattedMessage id="logout" defaultMessage="sign up" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            )}
          </Row>
        </Col>
        <Col xs={0} sm={1} md={1} lg={1} xl={1} xxl={1} />
      </Row>
    </Header>
  );
};

export default HomeNav;
