import moment from 'moment';
import { LOCALE_SET } from './actionTypes';

import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';

export const checkLocale = () => {
  let lang = localStorage.getItem('lang');

  if (lang === undefined || lang === null) {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.indexOf('zh') !== -1) {
      lang = 'zh';
      localStorage.setItem('lang', 'zh');
    } else {
      lang = 'en';
      localStorage.setItem('lang', 'en');
    }
  }

  if (lang === 'zh') moment.locale('zh-cn');
  else if (lang === 'en') moment.locale('en-gb');

  return {
    type: LOCALE_SET,
    lang,
  };
};

export const setLocale = (lang) => {
  localStorage.setItem('lang', lang);
  if (lang === 'zh') moment.locale('zh-cn');
  else moment.locale('en-gb');
  return {
    type: LOCALE_SET,
    lang,
  };
};
