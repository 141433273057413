import React, { Component } from 'react';
import {
  Row, Col, Input, message, Button,
} from 'antd';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import GoogleMapReact from 'google-map-react';
import axios from '../../../axios';
import { GOOGLE_MAPS_API_KEY } from '../../../config';
// import PropTypes from 'prop-types';

export class Profile extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      address: '',
      longitude: null,
      latitude: null,
      radius: null,
      loading: true,
      map: null,
      maps: null,
      circles: [],
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    const driverResponse = await axios.get('/drivers/current/driver', {
      headers: { 'access-token': localStorage.getItem('token') },
    });
    const driver = driverResponse.data.result;
    // console.log(driver);
    await this.setState({
      address: driver.address,
      longitude: driver.location.coordinates[0],
      latitude: driver.location.coordinates[1],
      radius: driver.radius,
    });
    this.setState({
      loading: false,
    });
  }

  handleChangeAddress = async (address) => {
    await this.setState({
      address,
    });
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    await this.setState({ [name]: value });
    await this.handleDrawCircle();
  };

  handleUpdate = async () => {
    const {
      radius, address, longitude, latitude,
    } = this.state;
    try {
      const response = await axios.post(
        '/drivers/update/driver',
        {
          address,
          longitude,
          latitude,
          radius,
        },
        { headers: { 'access-token': localStorage.getItem('token') } },
      );
      console.log(response);
    } catch (error) {
      message.error('Try again');
    }
  };

  handleSelect = (address) => {
    console.log(address);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(async (latLng) => {
        console.log('Success', latLng);
        await this.setState({
          address,
          longitude: latLng.lng,
          latitude: latLng.lat,
        });
        await this.handleDrawCircle();
      })
      .catch(error => console.error('Error', error));
  };

  apiIsLoaded = (map, maps) => {
    this.setState({
      map,
      maps,
    });
    this.handleDrawCircle();
  };

  handleDrawCircle = async () => {
    await this.state.circles.forEach((circle) => {
      circle.setMap(null);
    });
    await this.state.circles.push(
      new this.state.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.3,
        map: this.state.map,
        center: { lat: this.state.latitude, lng: this.state.longitude },
        radius: this.state.radius * 1609.344,
      }),
    );
    await this.setState({
      circles: this.state.circles,
    });
  };

  render() {
    const {
      address, longitude, latitude, radius,
    } = this.state;

    const AnyReactComponent = ({ text }) => <div />;

    return (
      <>
        <h1>Profile</h1>
        <Row>
          <Row>
            <Col span={3}>Address</Col>
            <Col span={20}>
              <PlacesAutocomplete
                value={address}
                onChange={this.handleChangeAddress}
                onSelect={this.handleSelect}
                googleCallbackName="initTwo"
              >
                {({
                  getInputProps, suggestions, getSuggestionItemProps, loading,
                }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        placeholder: 'Enter your location',
                      })}
                    />
                    <div className="autocompleteDropDown">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestionItemActive'
                          : 'suggestionItem';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              {/* <Input name="address" value={address} onChange={this.handleChange} /> */}
            </Col>
          </Row>
          <Row>
            <Col span={3}>Longitude</Col>
            <Col span={20}>
              <Input name="longitude" value={longitude} onChange={this.handleChange} />
            </Col>
          </Row>
          <Row>
            <Col span={3}>Latitude</Col>
            <Col span={20}>
              <Input name="latitude" value={latitude} onChange={this.handleChange} />
            </Col>
          </Row>
          <Row>
            <Col span={3}>Radius</Col>
            <Col span={20}>
              <Input name="radius" value={radius} onChange={this.handleChange} />
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              style={{ marginTop: '20px' }}
              onClick={() => {
                this.handleUpdate();
              }}
            >
              Update
            </Button>
          </Row>
        </Row>

        <div style={{ height: '40vh', width: '100%' }}>
          {!this.state.loading ? (
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
              defaultCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
              defaultZoom={10}
              center={{ lat: this.state.latitude, lng: this.state.longitude }}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                this.apiIsLoaded(map, maps);
              }}
            >
              <AnyReactComponent
                lat={this.state.latitude}
                lng={this.state.longitude}
                // text="My Marker"
              />
            </GoogleMapReact>
          ) : null}
        </div>
      </>
    );
  }
}

export default Profile;
