import React, { Component } from 'react';
import {
  Row, Col, Select, message,
} from 'antd';
import axios from '../../../../axios';

export class StatusButton extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      orderId: '',
      status: '',
    };
  }

  async componentDidMount() {
    await this.setState({
      orderId: this.props.order._id,
      status: this.props.order.status,
    });
  }

  handleChangeOrderStatus = async (value) => {
    await this.setState({ status: value });
    await this.handleSubmitOrderStatus();
    console.log(this.state.status);
  };

  handleSubmitOrderStatus = async () => {
    try {
      await axios.post(
        '/orders/update/order/status',
        {
          orderId: this.state.orderId,
          orderStatus: this.state.status,
        },
        { headers: { 'access-token': localStorage.getItem('token') } },
      );
      message.success('Successful');
    } catch (error) {
      message.error(error);
    }
  };

  render() {
    return (
      <Row>
        <Col>
          <Select
            style={{ width: 120 }}
            onSelect={this.handleChangeOrderStatus}
            value={this.state.status}
            // onChange={() => {
            //   this.handleSubmitOrderStatus();
            // }}
          >
            <Select.Option value="Sent">已接单</Select.Option>
            <Select.Option value="out for delivery">正在送餐</Select.Option>
            <Select.Option value="delivered">送达</Select.Option>
            <Select.Option value="canceled">已取消</Select.Option>
          </Select>

        </Col>
      </Row>
    );
  }
}

export default StatusButton;
