import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import {
  Row, Col, Input, Button,
} from 'antd';
import axios from '../../../axios';

export class Settings extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      driverRate: 0,
    };
  }

  async componentDidMount() {
    const settingsResponse = await axios.get('/settings/settings', {
      headers: { 'access-token': localStorage.getItem('token') },
    });
    const settings = settingsResponse.data.result;
    // console.log(settings);
    this.setState({
      driverRate: settings.driverRate,
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSave = async () => {
    const response = await axios.post(
      '/settings/update/settings',
      { driverRate: this.state.driverRate },
      { headers: { 'access-token': localStorage.getItem('token') } },
    );
    console.log(response);
  };

  render() {
    return (
      <div>
        <h2>Settings</h2>
        <Row>
          <Row>
            <Col span={3}>Driver Rate</Col>
            <Col span={19}>
              <Input name="driverRate" value={this.state.driverRate} onChange={this.handleChange} />
            </Col>
          </Row>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Button
            onClick={() => {
              this.handleSave();
            }}
          >
            Save
          </Button>
        </Row>
      </div>
    );
  }
}

export default Settings;
