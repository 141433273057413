import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import {
  Radio, Button, message, notification,
} from 'antd';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { FormattedMessage, IntlProvider } from 'react-intl';

import { Redirect } from 'react-router-dom';
import axios from '../../../axios';
import './CheckoutForm.css';
import translation from '../../../translation';
import americanexpress from '../../../assets/images/payments/americanexpress.png';
import dinersclub from '../../../assets/images/payments/dinersclub.png';
import discover from '../../../assets/images/payments/discover.png';
import jcb from '../../../assets/images/payments/jcb.png';
import mastercard from '../../../assets/images/payments/mastercard.png';
import unionpay from '../../../assets/images/payments/unionpay.png';
import visa from '../../../assets/images/payments/visa.png';
import powered_by_stripe from '../../../assets/images/powered_by_stripe.svg';

const RadioGroup = Radio.Group;

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      disableWhenProcessing: true,
      listCards: [],
      cardId: 'newCard',
      loading: true,
      status: undefined,
    };
  }

  async componentDidMount() {
    const { listCards } = this.state;
    const cardsResponse = await axios.get('/orders/stripe/list/cards', {
      headers: {
        'access-token': localStorage.getItem('token'),
      },
    });
    const cards = cardsResponse.data.result;
    if (cards.length !== 0) {
      this.setState({
        cardId: cards[0].id,
      });
    }
    this.setState({
      listCards: cardsResponse.data.result,
      loading: false,
    });

    if (this.props.phoneNumber) {
      this.setState({ disableWhenProcessing: false });
    } else {
      notification.open({
        duration: null,
        message: (
          <IntlProvider locale={this.props.lang} messages={translation[this.props.lang]}>
            <FormattedMessage id="verifyPhonenumber" defaultMessage="Verify phone" />
          </IntlProvider>
        ),
        placement: 'bottomLeft',
        icon: null,
        description: (
          <IntlProvider locale={this.props.lang} messages={translation[this.props.lang]}>
            <div>
              <FormattedMessage id="verifyPhonenumber2" defaultMessage="Verify phone at " />
              <a style={{ padding: '0px 10px' }} href="/account/profile">
                <FormattedMessage id="profilepage" defaultMessage="Profile page" />
              </a>
            </div>
          </IntlProvider>
        ),
      });
    }
    console.log(listCards);
  }

  componentWillUnmount() {
    notification.destroy();
  }

  getBrandImg = (brand) => {
    switch (brand) {
      case 'American Express':
        return americanexpress;
      case 'Diners Club':
        return dinersclub;
      case 'Discover':
        return discover;
      case 'JCB':
        return jcb;
      case 'MasterCard':
        return mastercard;
      case 'UnionPay':
        return unionpay;
      case 'Visa':
        return visa;
      default:
        return visa;
    }
  };

  handleCheckout = async (event) => {
    await this.setState({
      disableWhenProcessing: true,
    });

    let isNewCard = true;
    if (this.state.cardId !== 'newCard') {
      isNewCard = false;
    }
    let stripeToken = null;
    if (isNewCard) {
      event.preventDefault();
      const tokenResponse = await this.props.stripe.createToken({
        name: `${this.props.firstName} ${this.props.lastName}`,
      });
      if (tokenResponse.error) {
        console.log(tokenResponse.error);
        message.warn('Please typing correct card number.');
        await this.setState({ disableWhenProcessing: false });
      } else {
        stripeToken = tokenResponse.token.id;
      }
    }

    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const order = {
      items: this.props.cartItems,
      deliveryType: this.props.cartDeliveryType,
      address: this.props.cartAddress,
      longitude: this.props.cartLongitude,
      latitude: this.props.cartLatitude,
      subtotal: this.props.subtotal,
      hours: this.props.hours,
      minutes: this.props.minutes,
      tips: this.props.tips,
      canDetour: this.props.canDetour,
    };
    if (this.props.cartDeliveryType==='delivery' ){
      order.address = this.props.address + " "+ this.props.unitNumber;
    }
    else if (this.props.canDetour) {
      order.address = this.props.address;
      order.longitude = this.props.longitude;
      order.latitude = this.props.latitude;
      order.shuttleAddress = this.props.cartAddress;
      order.shuttleLongitude = this.props.cartLongitude;
      order.shuttleLatitude = this.props.cartLatitude;
      order.unitNumber = this.props.unitNumber;
    }

    const response = await axios.post('/orders/create', order, {
      headers: { 'access-token': localStorage.getItem('token') },
    });
    console.log(response.data);
    const orderId = response.data.result._id;
    const checkoutResponse = await axios.post(
      '/orders/stripe/checkout',
      {
        stripeToken,
        isNewCard,
        orderId,
        cardId: this.state.cardId,
      },
      { headers: { 'access-token': localStorage.getItem('token') } },
    );

    console.log(checkoutResponse);

    // this.setState({ redirect: true });
    this.setState({
      status: 'success',
    });
    this.props.clearItems();
  };

  handleUserChooseChange = (e) => {
    // console.log(e.target.value);
    this.setState({
      cardId: e.target.value,
    });
  };

  render() {
    return (
      <div>
        {this.state.status !== undefined ? (
          <Redirect
            to={{
              pathname: '/payment/status',
              state: { status: this.state.status },
            }}
          />
        ) : null}
        {this.state.loading ? null : (
          <div>
            <div className="checkout" style={{ margin: '40px 0' }}>
              <RadioGroup
                className="savedCards"
                onChange={this.handleUserChooseChange}
                value={this.state.cardId}
              >
                {this.state.listCards.map(card => (
                  <Radio className="option" key={card.id} value={card.id}>
                    <span className="card">
                      <img src={this.getBrandImg(card.brand)} alt="card logo" />
                      {`**** **** **** ${card.last4}`}
                    </span>
                  </Radio>
                ))}
                <Radio className="option newcard" value="newCard">
                  <FormattedMessage id="checkoutForm.addnewcard" defaultMessage="Dropbee" />
                </Radio>
              </RadioGroup>
              {this.state.cardId === 'newCard' ? <CardElement className="cdInfo" /> : null}
            </div>
            <div className="paymentButton">
              <Button
                disabled={this.state.disableWhenProcessing}
                className="btn"
                block
                type="primary"
                size="large"
                onClick={this.handleCheckout}
              >
                <FormattedMessage id="placeOrder" defaultMessage="Dropbee" />
              </Button>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <img src={powered_by_stripe} alt="Stripe logo" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
