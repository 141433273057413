export const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://api.idropbee.com/v1/'
  : 'http://127.0.0.1:2019/v1/';

export const CLIENT_ID = 'p0UDS0R4HtgllHQwulZGmutEoE2Igwi1';
export const CLIENT_SECRET = 'ysy2xuvTnYNgp4Nr1jKBc5qT6RVcYGLc';

export const MAPBOX_TOKEN = 'pk.eyJ1IjoiYmx1ZWFuZGhhY2siLCJhIjoiY2p0NWVjdDY3MDVqbTN5bTlsNjhqOTAzeiJ9._89zZncrDhU7EWZcxMqO1g';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyC79E9wSOXbTFfBL6gZmG8T57uYjNdyocY';

export const STRIPE_KEY = process.env.NODE_ENV === 'production'
  ? 'pk_live_ydtkUGMVDn93LcSgoX2b825D00LEj6flXB'
  : 'pk_test_GwHj8pUTlOdcQKjlO8INBbeB00SbSa1BDq';
// export const STRIPE_ACCOUNT_ID = 'acct_1EEAHLKUZXa7eqkG';

export const BRAINTREE_MERCHANT_ID = process.env.NODE_ENV === 'production' ? 'xpz6j6pk69xsstc8' : 'xpz6j6pk69xsstc8';
export const BRAINTREE_PUBLIC_KEY = process.env.NODE_ENV === 'production' ? 'kfxp655rnwrfpq47' : 'kfxp655rnwrfpq47';

export const PHONE_NUMBER = process.env.NODE_ENV === 'production' ? '' : '5205286665';

export const CA_TAX_RATE = 0.0925;

export const CUT_OFF_LUNCH = 10;
export const CUT_OFF_DINNER = 14;

export const FACEBOOK_ID = process.env.NODE_ENV === 'production' ? '262323684376249' : '1483617518436476';
export const FACEBOOK_REDIRECT = process.env.NODE_ENV === 'production'
  ? 'https://idropbee.com/login'
  : 'https://localhost:3000/login';
