import React, { Component } from 'react';
import {
  Row, Col, Tag, Pagination, Collapse, Divider, Switch,
} from 'antd';
import moment from 'moment';
import axios from '../../../axios';
import ReleaseButton from './ReleaseButton/ReleaseButton';
import './CurrentOrders.css';
import StatusButton from './StatusButton/StatusButton';

// import PropTypes from 'prop-types';
// const { Column } = Table;
const { Panel } = Collapse;
const customPanelStyle = {
  background: 'white',
  borderRadius: 10,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
  boxShadow: '0px 8px 10px rgba(221, 221, 221, 0.3)',
};

const SortRestaurants = ({ orderDish }) => {
  const stores = [];

  orderDish.forEach((item) => {
    if (stores.indexOf(item.originalSellerName) < 0) {
      stores.push(item.originalSellerName);
    }
  });
  return stores.length > 0
    ? stores.map(store => (
      <Tag key={store} color="magenta">
        {store}
      </Tag>
    ))
    : null;
};

const SortDishByRestaurant = ({ orderDish }) => {
  const stores = [];
  orderDish.forEach((item) => {
    if (!stores[item.originalSellerName]) {
      stores[item.originalSellerName] = [];
    }

    stores[item.originalSellerName].push(item);
  });
  const numableArr = [];

  Object.keys(stores).forEach((a) => {
    numableArr.push({
      name: a,
      items: stores[a],
    });
  });

  return (
    <div>
      {numableArr.map(store => (
        <Row key={store.name}>
          <Col span={24} style={{ fontWeight: '500', margin: '0px 0px 5px 0px' }}>
            {store.name}
          </Col>
          <Col span={24} style={{}}>
            {store.items.map(item => (
              <div key={item._id} style={{ marginBottom: '4px' }}>
                <Tag color="geekblue">{`${item.titleChinese} x${item.count}  `}</Tag>
                <div style={{ fontSize: '0.9em', color: 'grey' }}>
                 
                  {item.addons.map(i => i.description).join('\t|\t')}
                  
                </div>
                <div style={{ fontSize: '0.9em', color: 'grey' }}>{item.comment}</div>
              </div>
            ))}
          </Col>
        </Row>
      ))}
    </div>
  );
};

export class CurrentOrders extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      orders: [],
      pickupLocation: true,
      deliveryLocation: true,
      deliveryTime: true,
    };
  }

  async componentDidMount() {
    await this.fetchOrders();
  }

  handlePageIndexChange = async (page, pageSize) => {
    await this.setState({
      pageIndex: page,
    });
    await this.fetchOrders();
  };

  handlePageSizeChange = async (current, size) => {
    await this.setState({
      pageSize: size,
    });
    await this.fetchOrders();
  };

  fetchOrders = async () => {
    const { pageSize, pageIndex } = this.state;
    const ordersResponse = await axios.get('/drivers/driver/current/orders', {
      params: { pageSize, pageIndex },
      headers: { 'access-token': localStorage.getItem('token') },
    });

    this.setState({
      total: ordersResponse.data.result.count,
      orders: ordersResponse.data.result.orders,
    });
  };

  changeSetting = (e, type) => {
    switch (type) {
      case 'pickupLocation':
        this.setState({
          pickupLocation: e,
        });
        break;
      case 'deliveryLocation':
        this.setState({
          deliveryLocation: e,
        });
        break;
      case 'deliveryTime':
        this.setState({
          deliveryTime: e,
        });
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div>
        <h1>已接单</h1>
        {/* <Row>
          <Col>
            {this.state.orders.length !== 0 ? (
              <List
                itemLayout="horizontal"
                dataSource={this.state.orders}
                renderItem={order => (
                  <List.Item className="orderItem" style={{ marginBottom: '30px' }}>
                    {!order.isPaid ? <div className="mark" /> : null}
                    <Row>
                      <Row>
                        Mode:
                        {'\t'}
                        <Tag color="volcano">{`${order.deliveryType}`}</Tag>
                      </Row>
                      <Row>
                        Create Date:
                        {`\t${moment(order.createdAt).format('YYYY-MM-DD HH:mm')}`}
                      </Row>
                      <Row>
                        Estimated Date:
                        {`\t${moment(order.estimatedDate).format('YYYY-MM-DD HH:mm')}`}
                      </Row>
                      <Row>
                        Address:
                        {`\t${order.address}`}
                      </Row>
                      {order.canDetour ? (
                        <Row>
                          Unit:
                          {`\t${order.unitNumber}`}
                        </Row>
                      ) : null}
                      {order.canDetour ? (
                        <Row>
                          Shuttle Address:
                          {`\t${order.shuttleAddress}`}
                        </Row>
                      ) : null}
                      <Row>{`Name:\t${order.user.firstName} ${order.user.lastName}`}</Row>
                      <Row>
                        Tel:
                        {'\t'}
                        <a href={`tel:${order.user.phoneNumber}`}>{order.user.phoneNumber}</a>
                      </Row>
                      <Row>
                        <Table
                          pagination={false}
                          dataSource={order.items}
                          rowKey={record => record._id}
                        >
                          <Column title="Title" dataIndex="title" key="title" />
                          <Column title="标题" dataIndex="titleChinese" key="titleChinese" />
                          <Column title="Price" dataIndex="price" key="price" />
                          <Column title="Count" dataIndex="count" key="count" />
                          <Column title="Comment" dataIndex="comment" key="comment" />
                          <Column
                            title="picture"
                            key="picture"
                            render={(text, record) => (
                              <div>
                                <img
                                  style={{ height: '64px', width: '64px', objectFit: 'cover' }}
                                  src={record.pictureURL}
                                  alt="food"
                                />
                              </div>
                            )}
                          />
                          <Column
                            title="Addons"
                            key="addons"
                            render={(text, record) => (
                              <Row>
                                {record.addons && record.addons.length !== 0
                                  ? record.addons.map(addon => (
                                    <div key={addon._id}>{addon.description}</div>
                                  ))
                                  : 'None'}
                              </Row>
                            )}
                          />
                          <Column
                            title="Restaurant"
                            dataIndex="originalSellerName"
                            key="originalSellerName"
                          />
                        </Table>
                      </Row>
                      <Row style={{ margin: '10px' }}>
                        <ReleaseButton
                          {...this.state}
                          order={order}
                          fetchOrders={this.fetchOrders}
                        />
                      </Row>
                    </Row>
                  </List.Item>
                )}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Pagination
            defaultCurrent={this.state.pageIndex}
            current={this.state.pageIndex}
            onChange={this.handlePageIndexChange}
            defaultPageSize={this.state.pageSize}
            pageSize={this.state.pageSize}
            total={this.state.total}
            showSizeChanger
            pageSizeOptions={['5', '10', '15', '20']}
            onShowSizeChange={this.handlePageSizeChange}
          />
        </Row>
      */}

        <Col span={24}>
          <Row style={{ marginBottom: '16px' }}>
            <Switch
              style={{ marginRight: '10px' }}
              checkedChildren="显示送餐点"
              unCheckedChildren="隐藏送餐点"
              defaultChecked
              onChange={(e) => {
                this.changeSetting(e, 'deliveryLocation');
              }}
            />
            <Switch
              style={{ marginRight: '10px' }}
              checkedChildren="显示取餐点"
              unCheckedChildren="隐藏取餐点"
              defaultChecked
              onChange={(e) => {
                this.changeSetting(e, 'pickupLocation');
              }}
            />
            <Switch
              checkedChildren="显示送餐时间"
              unCheckedChildren="隐藏送餐时间"
              defaultChecked
              onChange={(e) => {
                this.changeSetting(e, 'deliveryTime');
              }}
            />
          </Row>
          <Row>
            <Col span={24} className="currentOrderListCol">
              <Collapse
                bordered={false}
                // defaultActiveKey={['1']}
                expandIcon={() => null}
                className="currentOrderCollapse"
              >
                {this.state.orders.length !== 0
                  ? this.state.orders.map((order, index) => (
                    <Panel
                      key={order._id}
                      style={customPanelStyle}
                      className="currentOrder"
                      header={(
                        <Row className="currentOrderHeader" type="flex" align="middle">
                          <Col className="info" xs={20} md={18}>
                            {this.state.deliveryTime ? (
                              <div className="time">
                                {`送餐时间: ${moment(order.estimatedDate).calendar()}`}
                              </div>
                            ) : null}

                            {this.state.pickupLocation ? (
                              <div className="from">
                                {'取餐点: '}
                                <SortRestaurants orderDish={order.items} />
                              </div>
                            ) : null}

                            {this.state.deliveryLocation ? (
                              <div className="to">{`送餐地点: ${order.address}`}</div>
                            ) : null}
                          </Col>
                          <Col className="ops" xs={4} md={6}>
                            <Row type="flex" justify="center" align="middle">
                              <Col>
                                <div>
                                  <Tag>{order.deliveryType}</Tag>
                                </div>
                                <div>{order.status}</div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
)}
                    >
                      <Divider />
                      <div className="DishesSortByRestaurants">
                        <SortDishByRestaurant orderDish={order.items} />
                      </div>

                      <div>
                        {`
                            总价: ${order.total.toFixed(2)}
                          `}
                      </div>
                      <Row>{`电话: ${order.user.phoneNumber}`}</Row>

                      <Row type="flex" align="middle">
                        <Col span={4} style={{ marginTop: '8px' }}>
                          <ReleaseButton order={order} fetchOrders={this.fetchOrders} />
                        </Col>
                        <Col span={20}>
                          <Row type="flex" justify="end" align="middle">
                            <Col>
                              <StatusButton order={order} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Panel>
                  ))
                  : null}
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Pagination
              defaultCurrent={this.state.pageIndex}
              current={this.state.pageIndex}
              onChange={this.handlePageIndexChange}
              defaultPageSize={this.state.pageSize}
              pageSize={this.state.pageSize}
              total={this.state.total}
              showSizeChanger
              pageSizeOptions={['5', '10', '15', '20']}
              onShowSizeChange={this.handlePageSizeChange}
            />
          </Row>
        </Col>
      </div>
    );
  }
}

export default CurrentOrders;
