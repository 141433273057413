import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  lang: 'en',
};

export const updateLang = (state, action) => {
  const updatedState = {
    lang: action.lang,
  };
  return updateObject(state, updatedState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCALE_SET:
      return updateLang(state, action);
    default:
      return state;
  }
};

export default reducer;
