import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

export class Logout extends Component {
  async componentDidMount() {
    await this.props.onLogout();
    await this.props.clearItems();
    await this.props.updateUser();
  }

  render() {
    return <Redirect to="/" />;
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(actions.logout()),
  updateUser: () => dispatch(actions.updateInfo(null)),
  clearItems: () => dispatch(actions.clearItems()),
});

export default connect(
  null,
  mapDispatchToProps,
)(Logout);
