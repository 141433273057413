import React from 'react';
import {
  Col, Row, Select, Input, Icon,
} from 'antd';
import PlacesAutocomplete from 'react-places-autocomplete';
// import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './AddressSearchBar.css';

const AddressSearchBar = (props) => {
  if (props.loading) {
    return null;
  }
  return (
    <div>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Col xs={24} md={16} style={{ padding: '5px' }}>
          <div className="getLocation">
            <button
              type="submit"
              style={{
                color: 'red',
                border: 'none',
                padding: '0',
                background: 'none',
                cursor: 'pointer',
                outline: '0',
              }}
              onClick={props.getCurrentLocation}
            >
              <Icon type="environment" />
            </button>
          </div>
          <PlacesAutocomplete
            className="autoComplete"
            value={props.address}
            onChange={props.handleAddressChange}
            onSelect={props.handleSelect}
          >
            {({
              getInputProps, suggestions, getSuggestionItemProps, loading,
            }) => (
              <div>
                <Input
                  {...getInputProps({
                    placeholder: 'Enter your location',
                    className: 'searchInput',
                  })}
                />

                <div className="autocompleteDropDown">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active ? 'suggestionItemActive' : 'suggestionItem';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Col>

        <Col xs={24} md={4} style={{ padding: '5px' }}>
          <Select
            // defaultValue={props.deliveryType}
            value={props.deliveryType}
            onSelect={props.handleDeliveryTypeChange}
            className="selectMode"
          >
            <Select.Option value="shuttle">
              <FormattedMessage id="shuttle" defaultMessage="shuttle" />
            </Select.Option>
            <Select.Option value="pickup">
              <FormattedMessage id="pickup" defaultMessage="pickup" />
            </Select.Option>
            <Select.Option value="delivery">
              <FormattedMessage id="delivery" defaultMessage="delivery" />
            </Select.Option>
          </Select>
        </Col>
        {/* <Col xs={24} md={4} style={{ padding: '5px' }}>
        <Button
          className="searchButton"
          type="primary"
          onClick={() => {
            props.handleSearchButton();
          }}
        >
          {' Search '}
        </Button>
      </Col> */}
      </Row>
    </div>
  );
};

AddressSearchBar.propTypes = {};

export default AddressSearchBar;
