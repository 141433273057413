import React from "react";
import { Row, Col, Button, Input, Modal, InputNumber } from "antd";
import { FormattedMessage } from "react-intl";
import CheckboxGroup from "./CheckboxGroup/CheckboxGroup";
import RadioGroup from "./RadioGroup/RadioGroup";
import "./DishModal.css";
// import PropTypes from 'prop-types';

const DishModal = props => (
  <Modal
    width="auto"
    className="dishModal"
    visible={props.addItemVisible}
    onCancel={props.handleCancel}
    footer={null}
    destroyOnClose
    // okText={<FormattedMessage id="addtocart" defaultMessage="??" />}
    centered
  >
    <Row type="flex" style={{ height: "100%" }}>
      {props.item.pictureURL ===
      "https://dropbee.s3-us-west-1.amazonaws.com/food.svg" ? null : (
        <Col sm={12}>
          <img className="dishImg" src={props.item.pictureURL} alt="food" />
        </Col>
      )}
      <Col
        sm={
          props.item.pictureURL ===
          "https://dropbee.s3-us-west-1.amazonaws.com/food.svg"
            ? 24
            : 12
        }
        className="rightContainer info"
        style={{ padding: "20px" }}
      >
        <div className="infomation">
          {props.lang === "en" ? (
            <div className="name">{props.item.title}</div>
          ) : (
            <div className="name">{props.item.titleChinese}</div>
          )}

          <div className="price">{`$ ${props.item.price}`}</div>
          {props.lang === "en" ? (
            <div style={{ marginTop: "8px", fontSize: "0.9em" }}>
              {props.item.description}
            </div>
          ) : (
            <div style={{ marginTop: "8px", fontSize: "0.9em" }}>
              {props.item.descriptionChinese || props.item.description}
            </div>
          )}

          {props.reorderRadioGroup && props.reorderRadioGroup.length ? (
            <RadioGroup
              handleRadioChange={props.handleRadioChange}
              reorderRadioGroup={props.reorderRadioGroup}
            />
          ) : null}

          {props.reorderCheckboxGroup && props.reorderCheckboxGroup.length ? (
            <CheckboxGroup
              handleCheckboxChange={props.handleCheckboxChange}
              reorderCheckboxGroup={props.reorderCheckboxGroup}
            />
          ) : null}

          <InputNumber
            style={{ borderRadius: "0px", margin: "8px 0px 16px 0px" }}
            min={1}
            max={10}
            value={props.item.count}
            name="item.count"
            onChange={props.handleCountChange}
          />
          <FormattedMessage id="note" defaultMessage="note">
            {placeholder => (
              <Input.TextArea
                style={{ borderRadius: "0px" }}
                placeholder={placeholder}
                rows={3}
                value={props.item.comment}
                name="item.comment"
                onChange={props.handleCommentChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="ops">
          <Row>
            <Col span={24}>
              <div className="buttons">
                <Row gutter={16}>
                  <Col xs={8}>
                    <Button
                      className="cancel"
                      block
                      onClick={() => {
                        props.handleCancel();
                      }}
                    >
                      <FormattedMessage id="cancel" defaultMessage="cancel" />
                    </Button>
                  </Col>
                  <Col xs={16}>
                    <Button
                      className="confirm"
                      type="primary"
                      block
                      onClick={() => {
                        props.handleAddCartItems();
                      }}
                      disabled={props.hideDetails}
                    >
                      <FormattedMessage id="add" defaultMessage="add" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </Modal>
);

export default DishModal;
