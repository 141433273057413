import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Switch, Route, Link } from 'react-router-dom';
import SellerApplication from './SellerApplication/SellerApplication';
import DriverApplication from './DriverApplication/DriverApplication';

export class Application extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div style={{ padding: '20px 10%' }}>
        <Link to="/apply/seller">
          <Button>Apply Seller(Restaurant)</Button>
        </Link>
        <Link to="/apply/driver">
          <Button style={{ marginLeft: '20px' }}>Apply Driver</Button>
        </Link>
        <Switch>
          <Route path="/apply/seller" component={SellerApplication} />
          <Route path="/apply/driver" component={DriverApplication} />
        </Switch>
      </div>
    );
  }
}

export default Application;
