import React, { Component } from 'react';
import { Row, Col, Alert } from 'antd';
// import PropTypes from 'prop-types'
import './DistanceAlert.css';
import { FormattedMessage } from 'react-intl';


export class DistanceAlert extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Alert
              className="DistanceOrderAlert"
              message={(
                <span>
                  <FormattedMessage id="distanceIntroduction" default="??" />
                </span>
)}
            />
            <div className={this.props.distance / 1609.344 < 1 ? 'deliveryable avaliable' : 'deliveryable unavaliable'}>
              { (this.props.distance / 1609.344).toFixed(2)}
              {' '}
              {' mile. '}
              { this.props.distance / 1609.344 < 1 ? (

                <FormattedMessage id="diliveryable" defaultMessage="Detour avaliable" />

              ) : (
<FormattedMessage id="onlyPickup" defaultMessage="Pick up food at pick up point">
                </FormattedMessage>
)
              }

            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DistanceAlert;
