import {
  Button, Row, Col, Divider,
} from 'antd';
import React from 'react';

import PropTypes from 'prop-types';
import './LoginForm.css';
import { FormattedMessage } from 'react-intl';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import facebookIcon from '../../../assets/images/icons/facebook.svg';
import { FACEBOOK_ID, FACEBOOK_REDIRECT } from '../../../config';

function LoginForm(props) {
  const {
    phoneNumber,
    smsCode,
    handleChange,
    handleSubmit,
    handleSendSMS,
    counter,
    exist,
    facebookLoading,
  } = props;

  return (
    <div className="login_form">
      <div className="login_title">
        <FormattedMessage id="login" defaultMessage="login" />
      </div>
      {facebookLoading ? (
        <Row>
          <Col>
            <div>Login with Facebook,</div>
            {' '}
            <div>if you having trouble login with Facebook,</div>
            {' '}
            <div>please refresh the page.</div>
          </Col>
        </Row>
      ) : (
        <Row>
          <div className="phoneNUmber">
            <Row type="flex" justify="center" align="middle">
              <Col xs={24}>
                <FormattedMessage id="phoneNumber" defaultMessage="phone number">
                  {placeholder => (
                    <input
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleChange}
                      type="tel"
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
              </Col>
            </Row>
          </div>
        </Row>
      )}
      {!facebookLoading && exist ? (
        <Row type="flex" justify="center" align="middle">
          <div className="phoneNUmber" span={24}>
            <Row type="flex" justify="center" align="middle">
              <Col xs={18}>
                <FormattedMessage id="verificationCode" defaultMessage="code">
                  {placeholder => (
                    <input
                      name="smsCode"
                      value={smsCode}
                      onChange={handleChange}
                      type="text"
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
              </Col>

              <Col xs={6}>
                {counter <= 0 ? (
                  <Button
                    type="primary"
                    size="small"
                    shape="round"
                    ghost
                    style={{ fontSize: '12px' }}
                    onClick={() => {
                      handleSendSMS(`+1${phoneNumber}`);
                    }}
                  >
                    <FormattedMessage id="send" defaultMessage="send" />
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    size="small"
                    shape="round"
                    ghost
                    disabled
                    style={{ fontSize: '12px' }}
                    onClick={() => {
                      handleSendSMS(`+1${phoneNumber}`);
                    }}
                  >
                    {`${counter} seconds`}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Row>
      ) : null}
      {!facebookLoading && exist ? (
        <Row className="buttons" type="flex" justify="center" align="middle">
          <Col span={24}>
            <Row type="flex" justify="center" align="middle">
              <Button
                type="primary"
                shape="round"
                style={{ fontSize: '13px' }}
                onClick={() => {
                  handleSubmit(`+1${phoneNumber}`, smsCode);
                }}
              >
                <FormattedMessage id="login" defaultMessage="login" />
              </Button>
            </Row>
          </Col>
          <Divider />

          <Row type="flex" justify="center" align="middle">
            <FacebookLogin
              appId={FACEBOOK_ID}
              fields="first_name,last_name,email"
              // onClick={componentClicked}
              redirectUri={FACEBOOK_REDIRECT}
              render={renderProps => (
                <input
                  style={{ height: '32px', padding: '5px' }}
                  type="image"
                  onClick={renderProps.onClick}
                  src={facebookIcon}
                  alt="facebook"
                />
              )}
              callback={props.handleResponseFacebook}
            />
          </Row>
        </Row>
      ) : null}
      <Row type="flex" justify="center" align="middle">
        <Col span={24}>
          {!facebookLoading && exist ? (
            <div>
              <a href="/signUp" className="signUp">
                <FormattedMessage id="signupAccount" defaultMessage="Dropbee Sign up" />
              </a>
            </div>
          ) : (
            <div>
              <div>
                <a href="/signUp" className="signUp only">
                  <FormattedMessage id="signupAccount" defaultMessage="Dropbee Sign up" />
                </a>
              </div>

              <div>
                <FacebookLogin
                  appId={FACEBOOK_ID}
                  fields="first_name,last_name,email"
                  // onClick={componentClicked}
                  render={renderProps => (
                    <input
                      style={{ height: '32px' }}
                      type="image"
                      onClick={renderProps.onClick}
                      src={facebookIcon}
                      alt="facebook"
                    />
                  )}
                  callback={props.handleResponseFacebook}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

LoginForm.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  smsCode: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSendSMS: PropTypes.func.isRequired,
  counter: PropTypes.number.isRequired,
};

export default LoginForm;
