import React from 'react';
import {
  Row, Col, notification, Icon,
} from 'antd';

// import fb from '../../../assets/images/icons/facebook.svg';
import WeChat from '../../../assets/images/icons/WeChat.png';
// import stripe from '../../../assets/images/powered_by_stripe.svg';

import './Footer.css';

// import { black } from 'ansi-colors';

const openNotification = () => {
  notification.open({
    message: '请拨打电话与我们联系',
    description: '若有关参观合作事宜, 请拨打 520-461-9076 ',
  });
};

const Footer = props => (
  <div className="Footer">
    <Row className="footerMain" type="flex" align="middle" justify="center">
      <Col className="icons">
        <a
          href="https://www.facebook.com/dropbeeofficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <img src={fb} style={{ width: '32px' }} alt="facebook" /> */}
          <Icon style={{ fontSize: '32px' }} type="facebook" />
        </a>
        <a
          href="https://www.instagram.com/dropbee_official/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <img src={instagram} style={{ width: '32px' }} alt="instagram" /> */}
          <Icon style={{ fontSize: '32px' }} type="instagram" />
        </a>
        <a id="wechat" href="/#" rel="noopener noreferrer">
          <Icon style={{ fontSize: '32px' }} type="wechat" />
          <img src={WeChat} alt="WeChat" />
        </a>
      </Col>
    </Row>

    <Row type="flex" align="middle" justify="center">
      <Col
        xs={0}
        sm={18}
        md={18}
        lg={12}
        xl={8}
        xxl={8}
        style={{ textAlign: 'center', paddingBottom: '10px' }}
      >
        <Row>
          <Col span={5}>
            <a href="https://menupedia.io/" target="_blank" rel="noopener noreferrer">
              About
            </a>
          </Col>
          <Col span={5}>
            <div>
              <button
                type="submit"
                style={{
                  border: 'none',
                  padding: '0',
                  background: 'none',
                  cursor: 'pointer',
                  outline: '0',
                  color: '#DF242F',
                }}
                onClick={openNotification}
              >
                Restaurant
              </button>
            </div>
          </Col>
          <Col span={5}>
            <a href="https://forms.gle/GgHNsfcMzx3MFEPSA" target="_blank" rel="noopener noreferrer">
              Contact
            </a>
          </Col>
          <Col span={5}>
            <a
              href="https://menupedia.io/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </Col>
          <Col span={4}>
            <a
              href="https://menupedia.io/termsofservice.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Term
            </a>
          </Col>
        </Row>
      </Col>
      <Col
        xs={16}
        sm={0}
        md={0}
        lg={0}
        xl={0}
        xxl={0}
        style={{ textAlign: 'center', paddingBottom: '10px' }}
      >
        <a href="https://menupedia.io/" target="_blank" rel="noopener noreferrer">
          About
        </a>
        <div>
          <button
            type="submit"
            style={{
              border: 'none',
              padding: '0',
              background: 'none',
              cursor: 'pointer',
              outline: '0',
              color: '#DF242F',
            }}
            onClick={openNotification}
          >
            Restaurant
          </button>
        </div>
        <div>
          <a href="https://forms.gle/GgHNsfcMzx3MFEPSA" target="_blank" rel="noopener noreferrer">
            Contact
          </a>
        </div>
        <div>
          <a
            href="https://menupedia.io/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
        </div>
        <div>
          <a
            href="https://menupedia.io/termsofservice.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Term
          </a>
        </div>
      </Col>
    </Row>

    <Row type="flex" align="middle" justify="center" style={{ margin: '5px 10px', padding: '0px' }}>
      © 2019 Menupedia LLC
    </Row>
  </div>
);

export default Footer;
