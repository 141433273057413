import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const checkSeller = () => {};

export const updateSeller = sellerInfo => ({
  type: actionTypes.SELLER_UPDATE_INFO,
  sellerInfo,
});

export const getSeller = sellerId => async (dispatch) => {
  const sellerResponse = await axios.get(`/sellers/seller/${sellerId}`);
  const sellerInfo = sellerResponse.data.result;
  dispatch(updateSeller(sellerInfo));
};
