export default {
  en: {
    slogan: 'Get your lunch with $1 delivery fee!',
    login: 'Login',
    signup: 'Sign up',
    account: 'Account',
    seller: 'Seller',
    logout: 'Log out',
    shuttle: 'Shuttle',
    delivery: 'Delivery',
    pickup: 'Pickup',
    search: 'Search',
    address: 'Address',
    orders: 'Orders',
    items: 'Items',
    overview: 'Overview',
    users: 'Users',
    sellers: 'Sellers',
    logs: 'Logs',
    profile: 'Profile',
    currentOrders: 'Current',
    mode: 'Mode',
    time: 'Time',
    add: 'Add',
    orderDetail: 'Order detail',
    pickupLocation: 'Pickup Address: ',
    shuttleLocation: 'Shuttle Address: ',
    deliveryLocation: 'Delivery Address: ',
    pickupTime: 'Pick up time: ',
    payment: 'Payment',
    subtotal: 'Subtotal',
    deliveryfee: 'Delivery fee',
    tax: 'Tax',
    total: 'Total',
    placeOrder: 'Place Order',
    notloginMsg: 'You are not login yet, please login before checkout',
    pleaseLogin: 'Login',
    signupAccount: 'Sign up account',
    phoneNumber: 'Phone Number',
    verificationCode: 'Verification code',
    send: 'Send',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    addtocart: 'Add to cart',
    cancel: 'Cancel',
    note: 'Note',
    img: 'Details',
    soldout: 'Sold out',
    from: 'From: ',
    tomorrow: '(Tomorrow)',

    alreadyHaveAccount: 'Already have an account?',
    noDish: 'No dish at this time, try a different time.',
    changePhonenumberAlert:
      'If you want to change the phone number, please contact blueandhack@gmail.com via email.',

    needCleanCart:
      'You have item(s) in your shopping cart from different location , do you want to check out before continue?',
    goCheckout: 'Go to check out',
    cleanCart: 'Clean cart and add this item',
    'street-view': 'Street View',
    'shops.official': 'Deliver by official Dropbee drivers',
    'home.dropbeetitle1': 'Dropbee pre-set location delivery',
    'home.dropbeeSubtitle':
      'Dropbee is an authentic Asian cuisine food delivery service. We aim to provide a extraordinary food experience',
    'home.dropbeeBf1': 'Only $1 delivery shuttle mode, no service fee, minimum requirement',
    'home.dropbeeBf2': 'Get the food as premised time, no more waiting',
    'home.dropbeeBf3': 'Hand pick authentic Asian cuisine, Try something new and unforgettable',
    'home.dropbeeBf4': 'Authentic teas and desserts',
    'home.dropbeeBf5': 'New dishes reroll everyday ',
    'home.dropbeeBf6': 'Order our lunch, stay in your work place and save your time',
    'home.dropbeeBf7':
      "You don't need to drive out, in case your parking spot is taken when you back",
    'home.dropbeeBf8': 'Home kitchen specialties',
    'home.detourMode':
      ' Detour mode delivery food to your door if you live 1 mile from the shuttle location.',
    'home.bundleMode': 'One order, bundle different restaurant dishes.',

    'home.dropbeetitle2': 'How we work?',
    'home.dropbeeStep1':
      'Select shuttle mode, Enter your address, and your best fit pick up location and time',
    'home.dropbeeStep1.2': '(Make sure you are able to show up at the pre-set time)',
    'home.dropbeeStep2': 'Browse menu, menu updates everyday. Order what you like in advance ',
    'home.dropbeeStep2.2':
      '(In order to get the fresh food, order before 10:30AM if pick up time is 12:00 )',
    'home.dropbeeStep3': 'The driver will arrive the pick up location on time ',
    'home.dropbeeStep3.2':
      '(we will notify you via message, please fill your last name, so we know how to call you correctly)',
    'home.dropbeeStep4': 'Pick up you food at preset location, enjoy your meal ',
    'home.dropbeeStep4.2':
      '(If you have any question, using online support or call customer service: 520-461-9076)',

    'checkoutForm.addnewcard': 'Add new card',
    'shops.noshop':
      'Whoops, looks like we are not yet there, please come back later or tell us come to your place',

    cutoffTimeL: 'Lunch Cutoff Time ',
    cutoffTimeD: 'Dinner Cutoff Time ',
    cutoffTimeS: 'Supper Cutoff Time ',
    deliveryDate: 'Delivery Time: ',
    cartEmptyMessage: 'No item found in your shopping cart.',
    chooseDish: 'Order your dishes',

    'order.createTime': 'Order Place @: ',
    'order.pickUpTime': 'Pick up @: ',
    'payment.success': ' Thank you! your order has been placed!',
    'payment.fail':
      'Beeps! We cannot process your credit card. please order again and try other cards, if it happens again, please contact customer service.',
    'payment.processing': " Processing your payment... Please don't refresh the page.",
    'payment.myorder': 'My order',
    iagree: 'I agree to the ',
    termofuse: 'Term of Use',
    privacypolicy: 'Privacy Policy',
    switchLanguage: '中文',

    verifyPhonenumber: 'Verify phone number',
    verifyPhonenumber2: 'Please verify your phone number at ',
    profilepage: 'profile page',
    tipsInfo:
      'We recommend that you tip your driver. Dropbee drivers work very hard to provide you with a great delivery experience, and they always receive 100% of the tips.',
    tips: 'Tips',

    distanceIntroduction:
      'Good news! We can deliver to your door within 1 mile from our shuttle point! ',
    currentDistance: 'Distance to the shuttle point: ',
    diliveryable: 'Detour available, change the address when you checkout',
    onlyPickup: 'Pick up your food at shuttle point',
    detour: 'Detour to your place(+$0.5)',
    canNotDetour:
      'Sorry, the distance over then 1 mile, please go back home page change your address.',
    detourExplain:
      'We will deliver food to your door. Please enter your apartment name, unit number, or other notes.',
    detourDelivery: 'Detour Address',
    addressNote: 'Apt/Unit',

    'steper.send': 'Paid',
    'steper.confirm': 'Confirm',
    'steper.outfordelivery': 'Out for delivery',
    'steper.delivered': 'Delivered',
    'steper.orderplaced': 'Order sent',
    'steper.recieveOrder': "We've received the order",
    'steper.onWay': 'Your meal is on the way',
    'steper.deliveredOrder': 'Your meal is delivered, enjoy your meal!',

    updated: 'Updated: ',

    enteraddresFindPickupPoint: '☝️Please enter your address to find your nearest pickup point',
    settings: 'Settings',
  },
  zh: {
    slogan: '小蜜蜂定点送餐',
    login: '登录',
    signup: '注册',
    account: '账号',
    seller: '店面',
    logout: '退出',
    shuttle: '定点取餐',
    delivery: '送货上门',
    pickup: '到店取餐',
    search: '搜索',
    address: '地址',
    orders: '订单',
    items: '商品',
    overview: '总览',
    users: '用户',
    sellers: '商家',
    logs: '日志',
    profile: '个人信息',
    currentOrders: '当前订单',
    mode: '送餐模式',
    time: '时间',
    add: '添加',

    orderDetail: '订单详情',
    pickupLocation: '餐厅取餐地址: ',
    shuttleLocation: '定点取餐地址: ',
    deliveryLocation: '送餐上门地址: ',
    pickupTime: '取餐时间: ',
    payment: '支付',
    subtotal: '小计',
    deliveryfee: '运送费',
    tax: '税',
    total: '总计',
    placeOrder: '下单',
    notloginMsg: '您还没有登录, 请先登录.',
    pleaseLogin: '登录账号',
    signupAccount: '创建账号',
    phoneNumber: '手机号码',
    verificationCode: '短信验证码',
    send: '发送',
    firstName: '名',
    lastName: '姓',
    email: '邮箱',
    addtocart: '添加购物车',
    cancel: '取消',
    note: '备注',
    img: '详细信息',
    soldout: '售罄',
    from: '来源: ',
    tomorrow: '(明天)',

    alreadyHaveAccount: '已有账号? 点此处登录',
    noDish: '当前时间没有菜品供应, 请选择其他时间',
    changePhonenumberAlert: '如果需要更改手机号请联系 blueandhack@gmail.com',

    needCleanCart: '您的购物车内有物品是送到不同地点, 是否要结算购物车内的菜品？',
    goCheckout: '前去清算',
    cleanCart: '清空购物车,再加入此菜',
    'street-view': '街景地图',
    'shops.official': '官方送餐，保证及时送达',

    'home.dropbeetitle1': 'Dropbee 定时定点送 (Shuttle)',
    'home.dropbeeSubtitle':
      'Dropbee 定点送是个性化定时定点中餐送餐服务,我们从以下多个角度帮您解决人生最大哲学问题之一: 今天吃什么? ',
    'home.dropbeeBf1': '最便宜外卖费仅需1美元。',
    'home.dropbeeBf2': '无需等待，提前下单，到点即拿。',
    'home.dropbeeBf3': '主打正宗中餐。',
    'home.dropbeeBf4': '正宗甜品，奶茶',
    'home.dropbeeBf5': '每日都有不同的菜品可供选择。',
    'home.dropbeeBf6': '待在办公室/学校 即可享用外面的大餐，无需中午花额外时间外出吃饭。',
    'home.dropbeeBf7': '不需要中午冒着停车位被抢的风险外出。',
    'home.dropbeeBf8': '加州供应各类私厨的独家菜品。',
    'home.detourMode': '顺路送餐，预设点1mile范围内可以送餐上门。',
    'home.bundleMode': '下一次单，多种餐厅菜品任意组合组合！',

    'home.dropbeetitle2': '我们如何解决您的中饭问题？',
    'home.dropbeeStep1': '选择班车模式，输入地址，选择提取点. 请选取最合适的提货时间和提货地点',
    'home.dropbeeStep1.2': '(如中午 12:00 Student Union 确保中午12点您会出现在该地点)',

    'home.dropbeeStep2': '每日精选中餐，选你所想。下单支付，短信确认',
    'home.dropbeeStep2.2': '(为保证新鲜及时的菜品，当天点中餐需要在11点之前下单)',
    'home.dropbeeStep3': 'Dropbee司机按时到达提货地点。',
    'home.dropbeeStep3.2': '(我们会有短信通知)',

    'home.dropbeeStep4': '到取餐点处取餐，享用今日份美味! 如有疑问请拨打客服热线。',
    'home.dropbeeStep4.2':
      '(若有疑问, 请使用在线客服，或者拨打电话520-461-9076, 有什么餐厅想加入也可以告诉我们)',

    'checkoutForm.addnewcard': '添加新卡',
    'shops.noshop': '抱歉，我们在附近没有找到合适的送餐点，请与我们联系，或者尝试其他地址',

    cutoffTimeL: '午餐截止当天 ',
    cutoffTimeD: '晚餐截止当天 ',
    cutoffTimeS: '夜宵截止当天 ',

    deliveryDate: '送餐时间 ',
    cartEmptyMessage: '购物车里什么都没有',
    chooseDish: '去选餐',

    'order.createTime': '下单时间: ',
    'order.pickUpTime': '提货时间: ',

    'payment.success': '恭喜,下单成功! 请按时到取餐哦！',
    'payment.fail':
      '啊哦, 我们无法从您的卡上扣款, 请尝试重新下单,并换一张卡支付, 若仍有问题, 请联系客服！',
    'payment.processing': '正在处理支付中，请不要刷新页面。',
    'payment.myorder': '我的订单',
    iagree: '我同意',
    termofuse: '使用条款',
    privacypolicy: '隐私保护指引',
    switchLanguage: 'English',

    verifyPhonenumber: '验证手机',
    verifyPhonenumber2: '下单前, 请先验证手机号码',
    profilepage: '个人信息',
    tipsInfo: '完全自愿, 打赏100%归于司机',
    tips: '打赏',
    distanceIntroduction: '好消息, 只要你在我们某个预设点1mile内, 我们现在能送餐到你的地方了! ',
    currentDistance: '目前位置离送餐点距离为: ',
    diliveryable: '可以顺路送餐到你的地址,在结账时可修改地址',
    onlyPickup: '请在取餐点取餐',
    detour: '顺路送餐(+$0.5)',
    canNotDetour: '对不起，此地址距离您的地址超过1 mile，请您返回主页更改您的地址',
    detourExplain: '我们将会送货上门, 请在下方输入公寓名地址或其他信息',
    detourDelivery: '送餐地址',
    addressNote: '公寓/单元号:',

    'steper.send': '支付成功',
    'steper.confirm': '蜜蜂确认',
    'steper.outfordelivery': '配送中',
    'steper.delivered': '已送达',
    'steper.orderplaced': '已下单,若有问题请联系客服',
    'steper.recieveOrder': 'Dropbee已经确认此单',
    'steper.onWay': '正在运送中,请耐心等待',
    'steper.deliveredOrder': '本单已送达, 请享用',
    updated: '更新于: ',
    enteraddresFindPickupPoint: '👆请输入地址, 查找最近的取餐点',
    settings: '设置',
  },
};
