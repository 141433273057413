/* global google */

import React, { Component } from "react";
import { Row, Col, Tabs, Typography, Modal, Button, message, Tag } from "antd";
// import GoogleMapReact from 'google-map-react';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import LazyLoad from "react-lazyload";
import _ from "lodash";
import moment from "moment";
import randomColor from "randomcolor";
import axios from "../../axios";
import * as actions from "../../store/actions";
import ShuttleComponent from "./ShuttleComponent/ShuttleComponent";
import DishModal from "./DishModal/DishModal";

import "./Shop.css";
import { checkValidity } from "../../util";
import AddressSearchBar from "./AddressSearchBar/AddressSearchBar";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

export class Shop extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      redirect: false,
      hideDetails: true,
      seller: {},
      items: [],
      filterItems: [],
      addItemVisible: false,
      warningVisible: false,
      item: {
        _id: "",
        sellerId: "",
        title: "",
        titleChinese: "",
        description: "",
        descriptionChinese: "",
        pictureURL: "",
        price: "",
        comment: "",
        category: "",
        addons: [],
        count: 1
      },
      addons: [],
      checkboxGroup: [],
      reorderCheckboxGroup: [],
      radioGroup: null,
      reorderRadioGroup: [],
      categories: [],
      shuttlePoints: [],
      loading: true,
      deliveryDate: "",
      lunchCutoffTime: null,
      dinnerCutoffTime: null,
      supperCutoffTime: null,
      distance: null,
      address: "",
      map: null,
      maps: null
    };
    this.circles = [];
  }

  async componentDidMount() {
    await this.initShop();
  }

  initShop = async () => {
    this.setState({ loading: true });
    const {
      match: { params }
    } = this.props;
    await this.props.checkAddress();
    await this.props.checkDeliveryType();
    await this.props.checkCartAddress();
    await this.props.checkCartDeliveryType();

    if (this.props.address === null || this.props.address === undefined) {
      await this.setState({
        address: ""
      });
    } else {
      await this.setState({
        address: this.props.address
      });
    }

    // pull seller
    const sellerResponse = await axios.get(
      `/sellers/seller/${params.sellerId}`,
      {
        params: {
          longitude: this.props.longitude,
          latitude: this.props.latitude,
          deliveryType: this.props.deliveryType
        }
      }
    );
    this.setState({ seller: sellerResponse.data.result });

    if (
      this.props.address === undefined ||
      this.props.address === "" ||
      this.props.latitude === null ||
      this.props.longitude === null
    ) {
      this.setState({
        hideDetails: true
      });
    } else {
      this.setState({
        hideDetails: false
      });
    }

    // This only for shuttle mode
    // set shuttle points
    if (
      this.props.deliveryType === "shuttle" &&
      this.state.hideDetails === false
    ) {
      // set up shuttle points

      let points = _.orderBy(
        sellerResponse.data.result.pickupPoints,
        ["hours", "minutes"],
        ["asc", "asc"]
      );

      points = points.map(p => {
        if (!p.duration) {
          p.duration = 20;
        }

        const endTime = this.timePeriod(p.hours, p.minutes, p.duration);
        return {
          ...p,
          ...{
            endTime,
            userPoint: false,
            color: randomColor({ luminosity: "bright" })
          }
        };
      });

      points.push({
        userPoint: true,
        address: this.props.address,
        longitude: this.props.longitude,
        latitude: this.props.latitude
      });

      // console.log(points);

      const lunchCutoffTime = this.state.seller.cutoffTimeLunch;
      const dinnerCutoffTime = this.state.seller.cutoffTimeDinner;
      const supperCutoffTime = this.state.seller.cutoffTimeSupper;

      this.setState({
        lunchCutoffTime,
        dinnerCutoffTime,
        supperCutoffTime
      });

      // filter points by local time
      const deliveryDateResponse = await axios.get(
        "/orders/check/delivery/date",
        {
          params: {
            sellerId: this.state.seller._id,
            latitude: this.props.latitude,
            longitude: this.props.longitude
          }
        }
      );
      const deliveryDate = deliveryDateResponse.data.result;

      // console.log(deliveryDate);

      this.setState({ section: deliveryDate.section });

      if (deliveryDate.section === "morning") {
        this.setState({
          deliveryDate: `Today Lunch, Dinner and Supper (${deliveryDate.date})`
        });
      } else if (deliveryDate.section === "noon") {
        this.setState({
          deliveryDate: `Today Dinner and Supper (${deliveryDate.date})`
        });
        // points = _.filter(points, (object) => {
        //   if (object.hours > CUT_OFF_DINNER) {
        //     return true;
        //   }
        //   return false;
        // });
        points = points.map(point => {
          if (
            point.hours < dinnerCutoffTime.hours ||
            (point.hours === dinnerCutoffTime.hours &&
              points.minutes < dinnerCutoffTime.minutes)
          ) {
            return { ...point, ...{ tomorrow: true } };
          }
          return { ...point, ...{ tomorrow: false } };
        });
      } else if (deliveryDate.section === "evening") {
        this.setState({ deliveryDate: `Today Supper (${deliveryDate.date})` });
        points = points.map(point => {
          if (
            point.hours < supperCutoffTime.hours ||
            (point.hours === supperCutoffTime.hours &&
              points.minutes < supperCutoffTime.minutes)
          ) {
            return { ...point, ...{ tomorrow: true } };
          }
          return { ...point, ...{ tomorrow: false } };
        });
      } else {
        await this.setState({
          deliveryDate: `Tomorrow Lunch, Dinner and Supper (${deliveryDate.date})`
        });
      }

      await this.setState({ shuttlePoints: points });

      // check current shuttle address
      if (this.state.shuttlePoints.length > 1) {
        await this.props.checkShuttleAddress(
          this.state.shuttlePoints[0].address,
          this.state.shuttlePoints[0].location.coordinates[0],
          this.state.shuttlePoints[0].location.coordinates[1]
        );
        // Check Times
        await this.props.checkTime(
          this.state.shuttlePoints[0].hours,
          this.state.shuttlePoints[0].minutes
        );
        await this.props.checkCartTime();
      }

      if (this.state.shuttlePoints.length !== 1) {
        await this.checkDistance();
      }

      // console.log(`${this.props.hours} ${this.props.minutes}`);
    }

    // pull all of items
    const response = await axios.get(
      `/items/items/sellerId/${params.sellerId}`
    );
    if (response.data.result) {
      let rawItems = response.data.result;
      // check priority
      rawItems.forEach(element => {
        if (!element.hasOwnProperty("priority")) {
          element.priority = 5;
        }
      });

      // order priority
      rawItems = _.orderBy(
        rawItems,
        ["priority", "createdAt"],
        ["desc", "asc"]
      );
      // console.log(rawItems);
      await this.setState({
        items: rawItems
      });
    }

    if (
      this.state.hideDetails === false &&
      this.props.deliveryType === "shuttle"
    ) {
      await this.handleFilterItems(this.props.hours, this.props.minutes);
    } else {
      await this.setState({
        filterItems: this.state.items
      });
      const categories = [
        ...new Set(this.state.filterItems.map(x => x.category))
      ];
      await this.setState({ categories });
    }

    // pull seller information
    await this.props.getSeller(params.sellerId);

    // pull all categories
    // const categoriesResponse = await axios.get(`/items/categories/sellerId/${params.sellerId}`);
    // this.setState({ categories: categoriesResponse.data.result });
    this.setState({ loading: false });

    // print all of information about addresses
    // console.log(
    //   `address:${this.props.address} longitude:${this.props.longitude} latitude:${
    //     this.props.latitude
    //   } shuttle address:${this.props.shuttleAddress} shuttle longitude:${
    //     this.props.shuttleAddressLongitude
    //   } shuttle latitude:${this.props.shuttleAddressLatitude}`,
    // );
  };

  timePeriod = (h, m, minsLater) => {
    const t = moment()
      .hour(h)
      .minute(m)
      .add(minsLater, "m");
    const endHour = t.hour();
    const endMin = t.minute();
    return { endHour, endMin };
  };

  formatTime = time => {
    if (typeof time === "number") {
      if (time < 10) {
        return `0${String(time)}`;
      }
      return time;
    }
    return time;
  };

  // getRandomColor = () => {
  //   const letters = '123456789ABCDEF';
  //   let color = '#';
  //   for (let i = 0; i < 6; i += 1) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  checkDistance = async () => {
    const distanceResponse = await axios.get("/orders/check/distance", {
      params: {
        originLatitude: this.props.shuttleAddressLatitude,
        originLongitude: this.props.shuttleAddressLongitude,
        destinationLatitude: this.props.latitude,
        destinationLongitude: this.props.longitude
      }
    });
    await this.setState({
      distance: distanceResponse.data.result
    });
  };

  handleClickAddress = async item => {
    // console.log(item);
    const element = document.getElementById(item._id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest"
    });
    await this.props.updateShuttleAddress(
      item.address,
      item.location.coordinates[0],
      item.location.coordinates[1]
    );
    await this.props.updateTime(item.hours, item.minutes);
    this.handleFilterItems(item.hours, item.minutes);
    await this.handleDrawCircle();
    await this.checkDistance();
  };

  handleFilterItems = async (hours, minutes) => {
    const {
      supperCutoffTime,
      dinnerCutoffTime,
      lunchCutoffTime,
      items
    } = this.state;
    // filter items
    // console.log(hours);
    // console.log(minutes);
    if (
      (hours < 24 && hours > supperCutoffTime.hours) ||
      (hours < 24 &&
        hours === supperCutoffTime.hours &&
        minutes >= supperCutoffTime.minutes)
    ) {
      // console.log('Supper');
      await this.setState({
        filterItems: _.filter(items, { isSupper: true })
      });
    } else if (
      (hours < supperCutoffTime.hours && hours > dinnerCutoffTime.hours) ||
      (hours === supperCutoffTime.hours &&
        minutes < supperCutoffTime.minutes &&
        hours >= dinnerCutoffTime.hours) ||
      (hours <= supperCutoffTime.hours &&
        hours === dinnerCutoffTime.hours &&
        minutes >= dinnerCutoffTime.minutes)
    ) {
      // console.log('Dinner');
      await this.setState({
        filterItems: _.filter(items, { isDinner: true })
      });
    } else if (
      (hours < dinnerCutoffTime.hours && hours > lunchCutoffTime.hours) ||
      (hours === dinnerCutoffTime.hours &&
        minutes < dinnerCutoffTime.minutes &&
        hours >= lunchCutoffTime.hours) ||
      (hours <= dinnerCutoffTime.hours &&
        hours === lunchCutoffTime.hours &&
        minutes >= lunchCutoffTime.minutes)
    ) {
      // console.log('Lunch');
      await this.setState({
        filterItems: _.filter(items, { isLunch: true })
      });
    } else {
      await this.setState({
        filterItems: []
      });
      // console.log('breakfast');
    }
    // console.log(this.state.filterItems)
    // distinct categories
    const categories = [
      ...new Set(this.state.filterItems.map(x => x.category))
    ];
    await this.setState({ categories });
  };

  handleChooseItem = async item => {
    // console.log(item.addons);
    await this.setState({
      addItemVisible: true,
      item: {
        ...this.state.item,
        _id: item._id,
        sellerId: item.sellerId,
        title: item.title,
        titleChinese: item.titleChinese,
        description: item.description,
        descriptionChinese: item.descriptionChinese,
        pictureURL: item.pictureURL,
        price: item.price,
        comment: item.comment,
        category: item.category,
        addons: [],
        count: 1
      },
      addons: item.addons,
      checkboxGroup: [],
      radioGroup: null,
      reorderCheckboxGroup: [],
      reorderRadioGroup: []
    });
    await this.handleReorderCheckboxGroup();
    await this.handleReorderRadioGroup();
  };

  handleReorderCheckboxGroup = async () => {
    // get all checkbox
    await this.setState({ reorderCheckboxGroup: [] });
    const checkboxGroup = _.filter(this.state.addons, { type: "checkbox" });
    const reorder = [];
    _.each(checkboxGroup, item => {
      reorder.push({
        label: `${item.description} $${item.price}`,
        value: item.shortId
      });
    });
    await this.setState({
      reorderCheckboxGroup: reorder
    });
  };

  handleReorderRadioGroup = async () => {
    // get all radio
    await this.setState({ reorderRadioGroup: [] });
    const radioGroup = _.filter(this.state.addons, { type: "radio" });
    this.setState({
      reorderRadioGroup: radioGroup
    });
  };

  // Add Item Modal handles
  // =====================================

  handleCountChange = async value => {
    if (await checkValidity(value, "isNumeric")) {
      this.setState({
        item: {
          ...this.state.item,
          count: value
        }
      });
    } else {
      await this.setState({
        item: {
          ...this.state.item,
          count: 1
        }
      });
      message.warn("Please input number");
    }
  };

  handleCommentChange = e => {
    this.setState({
      item: {
        ...this.state.item,
        comment: e.target.value
      }
    });
  };

  handleCheckboxChange = async values => {
    // console.log(values);
    // check the checkbox
    const array = [];
    _.each(values, value => {
      const checkboxItem = _.find(this.state.addons, { shortId: value });
      array.push(checkboxItem);
    });
    await this.setState({
      checkboxGroup: array
    });
    // console.log(this.state.checkboxGroup);
  };

  handleRadioChange = async e => {
    const radioShortId = e.target.value;
    // console.log(radioShortId);
    const radioItem = _.find(this.state.addons, { shortId: radioShortId });
    await this.setState({
      radioGroup: radioItem
    });
    // console.log(this.state.radioGroup);
  };

  handleAddCartItems = () => {
    // console.log(this.state.item);

    if (
      this.props.deliveryType === "shuttle" &&
      this.state.shuttlePoints.length === 0
    ) {
      message.warn("Sorry, no shuttle points.");
    } else if (
      // fist check delivery type
      this.props.cartDeliveryType !== null &&
      this.props.cartDeliveryType !== undefined &&
      this.props.cartDeliveryType !== ""
    ) {
      // if deliveryType not equals
      if (this.props.deliveryType !== this.props.cartDeliveryType) {
        if (
          this.props.deliveryType === "shuttle" &&
          this.state.shuttlePoints.length === 0
        ) {
          message.error("Sorry, no points");
        } else {
          console.log("cart delivery type not equals");
          this.setState({
            addItemVisible: false,
            warningVisible: true
          });
        }
      } else if (this.props.deliveryType === "shuttle") {
        if (this.state.shuttlePoints.length === 0) {
          message.error("Sorry, no points");
        } else if (this.props.cartAddress !== this.props.shuttleAddress) {
          // shuttle point not equals
          console.log("shuttle point not equals");
          this.setState({
            addItemVisible: false,
            warningVisible: true
          });
        } else if (
          this.props.cartAddress === this.props.shuttleAddress &&
          this.props.cartHours !== this.props.hours &&
          this.props.cartMinutes !== this.props.minutes
        ) {
          console.log("shuttle point and time not equals");
          this.setState({
            addItemVisible: false,
            warningVisible: true
          });
        } else {
          this.addItemToCart();
        }
      } else if (this.props.deliveryType === "pickup") {
        if (this.props.cartAddress !== this.props.sellerAddress) {
          // seller address point not equals
          console.log("seller address point not equals");
          this.setState({
            addItemVisible: false,
            warningVisible: true
          });
        } else {
          this.addItemToCart();
        }
      } else if (this.props.deliveryType === "delivery") {
        if (this.props.cartAddress !== this.props.address) {
          // delivery point not equals
          console.log("delivery point not equals");
          this.setState({
            addItemVisible: false,
            warningVisible: true
          });
        } else {
          this.addItemToCart();
        }
      }
    } else {
      this.addItemToCart();
    }
  };

  // handleChange = (e) => {
  //   const names = e.target.name.split('.');
  //   const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
  //   this.setState((state) => {
  //     state[names[0]][names[1]] = value;
  //     return { [names[0]]: state[names[0]] };
  //   });
  // };

  // Question Modal handles
  // ======================
  handleClearCartAndAddItem = async () => {
    await this.props.clearItems();
    await this.addItemToCart();
    await this.resetState();
  };

  handleCheckoutButton = () => {
    this.setState({
      warningVisible: false,
      redirect: true
    });
  };

  // Address Searcher Bar
  // ======================
  handleAddressChange = address => {
    this.setState({
      address
    });
  };

  handleSelect = async address => {
    await geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(async latLng => {
        // console.log('Success', latLng);
        await this.setState({
          address
        });
        await this.props.updateAddress(address, latLng.lng, latLng.lat);
        await this.initShop();
      })
      .catch(error => console.error("Error", error));
  };

  getCurrentLocation = () => {
    this.setState({
      address: "Loading..."
    });
    // this.props.updateAddress('loading...');
    if ("geolocation" in navigator) {
      // check if geolocation is supported/enabled on current browser
      navigator.geolocation.getCurrentPosition(
        position => {
          const geocoder = new google.maps.Geocoder();
          const location = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          geocoder.geocode({ latLng: location }, async (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                this.setState({
                  address: results[1].formatted_address
                });
                this.props.updateAddress(
                  results[1].formatted_address,
                  position.coords.longitude,
                  position.coords.latitude
                );
                await this.initShop();
              }
            }
          });
        },
        () => {
          this.setState({
            hideDetails: true
          });
          message.error(
            "Cannot get location, check your network or location permission"
          );
        }
      );
    }
  };

  handleDeliveryTypeChange = type => {
    console.log(type);
    this.props.updateDeliveryType(type);
  };

  // Helper functions
  // ======================
  apiIsLoaded = (map, maps) => {
    this.setState({
      map,
      maps
    });
    this.handleDrawCircle();
  };

  handleDrawCircle = async () => {
    await this.circles.forEach(circle => {
      circle.setMap(null);
    });

    await this.circles.push(
      new this.state.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.1,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.1,
        map: this.state.map,
        center: {
          lat: Number(this.props.shuttleAddressLatitude),
          lng: Number(this.props.shuttleAddressLongitude)
        },
        radius: 1 * 1609.344
      })
    );
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  addItemToCart = async () => {
    if (this.props.deliveryType === "shuttle") {
      // console.log(this.props.shuttleAddress);
      this.props.updateCartAddress(
        this.props.shuttleAddress,
        this.props.shuttleAddressLongitude,
        this.props.shuttleAddressLatitude
      );
      this.props.updateCartDeliveryType(this.props.deliveryType);
      this.props.updateCartTime(this.props.hours, this.props.minutes);
    } else if (this.props.deliveryType === "pickup") {
      this.props.updateCartAddress(
        this.props.sellerAddress,
        this.props.sellerLongitude,
        this.props.sellerLatitude
      );
      this.props.updateCartDeliveryType(this.props.deliveryType);
      localStorage.removeItem("shuttleAddress");
      localStorage.removeItem("shuttleAddressLongitude");
      localStorage.removeItem("shuttleAddressLatitude");
    } else if (this.props.deliveryType === "delivery") {
      this.props.updateCartAddress(
        this.props.address,
        this.props.longitude,
        this.props.latitude
      );
      this.props.updateCartDeliveryType(this.props.deliveryType);
      localStorage.removeItem("shuttleAddress");
      localStorage.removeItem("shuttleAddressLongitude");
      localStorage.removeItem("shuttleAddressLatitude");
    }

    // const array = this.state.checkboxGroup.push(this.state.radioGroup);
    // console.log(this.state.checkboxGroup);
    const checkboxAndRadioArray = this.state.checkboxGroup;
    if (this.state.radioGroup !== null) {
      // console.log('add radio');
      // console.log(this.state.radioGroup)
      checkboxAndRadioArray.push(this.state.radioGroup);
    }

    // console.log(checkboxAndRadioArray);

    await this.setState({
      item: {
        ...this.state.item,
        addons: checkboxAndRadioArray
      }
    });

    // console.log(this.state.item);

    await this.props.addItem(this.state.item);
    await this.resetState();
  };

  resetState = async () => {
    await this.setState({
      addItemVisible: false,
      warningVisible: false,
      item: {
        ...this.state.item,
        _id: "",
        sellerId: "",
        title: "",
        description: "",
        pictureURL: "",
        price: "",
        comment: "",
        category: "",
        addons: [],
        count: 1
      },
      addons: [],
      checkboxGroup: [],
      radioGroup: null,
      reorderCheckboxGroup: [],
      reorderRadioGroup: []
    });
  };

  handleCancel = async e => {
    await this.setState({
      addItemVisible: false,
      warningVisible: false
    });
    await this.resetState();
  };

  render() {
    const { filterItems } = this.state;

    const showItems = sellerItems => (
      <Row gutter={8}>
        {sellerItems.map(item => (
          <Col
            key={item._id}
            xs={24}
            sm={12}
            md={8}
            lg={6}
            style={{ padding: "10px" }}
            onClick={() => {
              if (item.available) {
                this.handleChooseItem(item);
              }
            }}
          >
            <div
              className={
                item.pictureURL ===
                "https://dropbee.s3-us-west-1.amazonaws.com/food.svg"
                  ? "smallDishBox"
                  : "dishBox"
              }
              style={
                item.available
                  ? null
                  : { backgroundColor: "rgba(0, 0, 0, 0.8)", zIndex: "10" }
              }
            >
              <div className="dishImg">
                {item.addons && item.addons.length !== 0 ? (
                  <Tag
                    color="#108ee9"
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "2px",
                      zIndex: "20"
                    }}
                  >
                    Options
                  </Tag>
                ) : null}
                {item.pictureURL ===
                "https://dropbee.s3-us-west-1.amazonaws.com/food.svg" ? null : (
                  <LazyLoad>
                    <img src={item.pictureURL} alt="dish" />
                  </LazyLoad>
                )}
              </div>
              <div className="info">
                <Row>
                  <Col />
                </Row>
                <Row type="flex" align="middle" justify="center">
                  <Col xs={12}>
                    <Row>
                      {this.props.lang === "en" ? (
                        <Col sm={24}>{item.title}</Col>
                      ) : (
                        <Col sm={24}>{item.titleChinese || item.title}</Col>
                      )}

                      <Col sm={24}>{`$${item.price}`}</Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="right">
                    <div>
                      {item.available ? null : (
                        <FormattedMessage
                          id="soldout"
                          defaultMessage="Sold out"
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  align="middle"
                  justify="start"
                  className="fromStore"
                >
                  <Col>
                    <FormattedMessage id="from" defaultMessage="From: " />
                    {item.originalSellerName}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );

    const showAllItems = sellerItems => (
      <Row>
        {this.state.categories.map(category => (
          <Row key={category}>
            <Row
              style={{
                lineHeight: "1.75",
                fontWeight: "400",
                padding: "0 10px"
              }}
            >
              {category}
            </Row>
            {showItems(
              sellerItems.filter(
                item => !item.deleted && item.category === category
              )
            )}
          </Row>
        ))}
      </Row>
    );

    const warningFooter = () => (
      <div>
        <Button
          style={{ marginBottom: "10px" }}
          type="danger"
          onClick={() => {
            this.handleCheckoutButton();
          }}
        >
          <FormattedMessage id="goCheckout" defaultMessage="Dropbee" />
        </Button>
        <Button
          style={{ marginBottom: "10px" }}
          onClick={() => {
            this.handleClearCartAndAddItem();
          }}
          type="primary"
        >
          <FormattedMessage id="cleanCart" defaultMessage="Dropbee" />
        </Button>
      </div>
    );

    return (
      <div>
        {this.state.redirect ? <Redirect to="/cart" /> : null}
        <Row
          type="flex"
          align="middle"
          justify="center"
          className="shopContainerOuterArea"
        >
          <Col xs={24} sm={20} md={20} lg={20} xl={18} xxl={18}>
            <Row type="flex" justify="center">
              <Col
                span={24}
                style={{
                  margin: "20px",
                  minHeight: "60px",
                  padding: "20px 20px",
                  zIndex: "101"
                }}
              >
                <AddressSearchBar
                  loading={this.state.loading}
                  deliveryType={this.props.deliveryType}
                  address={this.state.address}
                  latitude={this.props.latitude}
                  longitude={this.props.longitude}
                  handleAddressChange={this.handleAddressChange}
                  handleDeliveryTypeChange={this.handleDeliveryTypeChange}
                  handleChange={this.handleChange}
                  handleSelect={this.handleSelect}
                  getCurrentLocation={this.getCurrentLocation}
                />
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={20}
            md={20}
            lg={20}
            xl={18}
            xxl={18}
            className="shopContainer"
          >
            {this.state.hideDetails && this.state.seller.name ? (
              <div className="noAddressCover">
                <span className="alert">
                  {" "}
                  <FormattedMessage
                    id="enteraddresFindPickupPoint"
                    defaultMessage="Dropbee want to get your address to list all pick up location"
                  />
                </span>
              </div>
            ) : null}
            <ShuttleComponent
              apiIsLoaded={this.apiIsLoaded}
              seller={this.state.seller}
              deliveryType={this.props.deliveryType}
              deliveryDate={this.state.deliveryDate}
              loading={this.state.loading}
              shuttlePoints={this.state.shuttlePoints}
              latitude={this.props.latitude}
              longitude={this.props.longitude}
              shuttleAddress={this.props.shuttleAddress}
              shuttleAddressLongitude={this.props.shuttleAddressLongitude}
              shuttleAddressLatitude={this.props.shuttleAddressLatitude}
              hours={this.props.hours}
              minutes={this.props.minutes}
              handleClickAddress={this.handleClickAddress}
              formatTime={this.formatTime}
              lunchCutoffTime={this.state.lunchCutoffTime}
              dinnerCutoffTime={this.state.dinnerCutoffTime}
              supperCutoffTime={this.state.supperCutoffTime}
              distance={this.state.distance}
              hideDetails={this.state.hideDetails}
            />
            {this.props.deliveryType !== "shuttle" ? (
              <Row>
                <Col sm={24}>
                  <Title level={4}>{this.props.sellerName}</Title>
                </Col>
                <Col sm={24}>
                  <Text>
                    <FormattedMessage id="mode" default="Mode" />
                    {": "}
                    <Tag
                      color={
                        this.props.deliveryType === "pickup"
                          ? "volcano"
                          : "purple"
                      }
                    >
                      <FormattedMessage
                        id={this.props.deliveryType}
                        default={this.props.deliveryType}
                      />
                    </Tag>
                  </Text>
                </Col>

                <Col sm={24}>
                  {this.props.deliveryType === "pickup" ? (
                    <Text>
                      <FormattedMessage
                        id="pickupLocation"
                        default="pickup address"
                      />

                      {` ${this.props.sellerAddress}`}
                    </Text>
                  ) : null}
                  {this.props.deliveryType === "shuttle" ? (
                    <Text>
                      Shuttle pickup address:
                      {` ${this.props.shuttleAddress}`}
                    </Text>
                  ) : null}
                  {this.props.deliveryType === "delivery" ? (
                    <Text>
                      <FormattedMessage
                        id="deliveryLocation"
                        default="Delivery location"
                      />
                      {` ${this.props.address}`}
                    </Text>
                  ) : null}
                </Col>
              </Row>
            ) : null}

            <Row style={{ marginTop: "10px" }}>
              <Col span={24}>
                <Tabs defaultActiveKey="all">
                  <TabPane tab="All" key="all">
                    {filterItems && filterItems.length === 0 ? (
                      <div
                        className="nodish"
                        style={{
                          height: "200px",
                          lineHeight: "200px",
                          textAlign: "center"
                        }}
                      >
                        <FormattedMessage id="noDish" default="nodish" />
                      </div>
                    ) : (
                      ""
                    )}
                    {showAllItems(filterItems.filter(item => !item.deleted))}
                  </TabPane>
                  {this.state.categories.map(category => (
                    <TabPane tab={category} key={category}>
                      {filterItems && filterItems.length === 0 ? (
                        <div
                          className="nodish"
                          style={{
                            height: "200px",
                            lineHeight: "200px",
                            textAlign: "center"
                          }}
                        >
                          <FormattedMessage id="noDish" default="nodish" />
                        </div>
                      ) : (
                        ""
                      )}

                      {showItems(
                        filterItems.filter(
                          item => !item.deleted && item.category === category
                        )
                      )}
                    </TabPane>
                  ))}
                </Tabs>
              </Col>
            </Row>
          </Col>

          <DishModal
            addItemVisible={this.state.addItemVisible}
            handleCancel={this.handleCancel}
            item={this.state.item}
            handleAddCartItems={this.handleAddCartItems}
            handleCommentChange={this.handleCommentChange}
            handleCountChange={this.handleCountChange}
            lang={this.props.lang}
            handleCheckboxChange={this.handleCheckboxChange}
            handleRadioChange={this.handleRadioChange}
            reorderCheckboxGroup={this.state.reorderCheckboxGroup}
            reorderRadioGroup={this.state.reorderRadioGroup}
            hideDetails={this.state.hideDetails}
          />
          <Modal
            title="Can not add to cart"
            visible={this.state.warningVisible}
            cancelText="Go to checkout"
            okText="Clear cart and add this item"
            onCancel={this.handleCancel}
            footer={warningFooter()}
            centered
          >
            <p>
              <FormattedMessage id="needCleanCart" default="??" />
            </p>
          </Modal>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sellerName: state.seller.name,
  sellerAddress: state.seller.address,
  sellerNameChinese: state.seller.nameChinese,
  sellerLongitude: state.seller.longitude,
  sellerLatitude: state.seller.latitude,
  // address bar information
  address: state.cart.address,
  latitude: state.cart.latitude,
  longitude: state.cart.longitude,
  cartAddress: state.cart.cartAddress,
  deliveryType: state.cart.deliveryType,
  // shuttle information
  shuttleAddress: state.cart.shuttleAddress,
  shuttleAddressLongitude: state.cart.shuttleAddressLongitude,
  shuttleAddressLatitude: state.cart.shuttleAddressLatitude,
  // cart information
  cartItems: state.cart.items,
  cartLatitude: state.cart.cartLatitude,
  cartLongitude: state.cart.cartLongitude,
  cartDeliveryType: state.cart.cartDeliveryType,
  cartHours: state.cart.cartHours,
  cartMinutes: state.cart.cartMinutes,
  hours: state.cart.hours,
  minutes: state.cart.minutes,
  // language
  lang: state.locale.lang
});

const mapDispatchToProps = dispatch => ({
  checkAddress: () => dispatch(actions.checkAddress()),
  updateAddress: (add, lon, lat) =>
    dispatch(actions.updateAddress(add, lon, lat)),
  updateDeliveryType: type => dispatch(actions.updateDeliveryType(type)),
  addItem: item => dispatch(actions.addItem(item)),
  clearItems: () => dispatch(actions.clearItems()),
  getSeller: sellerId => dispatch(actions.getSeller(sellerId)),
  checkDeliveryType: () => dispatch(actions.checkDeliveryType()),
  checkCartDeliveryType: () => dispatch(actions.checkCartDeliveryType()),
  checkShuttleAddress: (add, lon, lat) =>
    dispatch(actions.checkShuttleAddress(add, lon, lat)),
  checkCartAddress: () => dispatch(actions.checkCartAddress()),
  updateShuttleAddress: (add, lon, lat) =>
    dispatch(actions.updateShuttleAddress(add, lon, lat)),
  updateCartAddress: (add, lon, lat) =>
    dispatch(actions.updateCartAddress(add, lon, lat)),
  updateCartDeliveryType: type =>
    dispatch(actions.updateCartDeliveryType(type)),
  checkTime: (hours, minutes) => dispatch(actions.checkTime(hours, minutes)),
  checkCartTime: () => dispatch(actions.checkCartTime()),
  updateTime: (hours, minutes) => dispatch(actions.updateTime(hours, minutes)),
  updateCartTime: (hours, minutes) =>
    dispatch(actions.updateCartTime(hours, minutes))
});

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
