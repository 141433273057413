import React, { Component } from 'react';
import {
  Table, Row, Col, Button, Divider,
} from 'antd';
import { Link } from 'react-router-dom';
import axios from '../../../axios';
// import PropTypes from 'prop-types';

const { Column } = Table;

export class Sellers extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      sellers: [],
      count: 0,
    };
  }

  async componentDidMount() {
    const sellersResponse = await axios.get('/sellers/admin/sellers', {
      params: { pageIndex: 1, pageSize: 10 },
      headers: { 'access-token': localStorage.getItem('token') },
    });
    const { sellers, count } = sellersResponse.data.result;
    this.setState({
      sellers,
      count,
    });
    console.log(sellers, count);
  }

  render() {
    const { sellers, count } = this.state;
    return (
      <Row>
        <h1>Sellers</h1>
        <Row>
          <Button>Add</Button>
        </Row>
        <Row>
          <Col>
            <Table dataSource={sellers} rowKey={record => record._id} pagination={{ total: count }}>
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Address" dataIndex="address" key="address" />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <span>
                    <Button size="small">Edit</Button>
                    <Divider type="vertical" />
                    <Link to={`/admin/sellers/seller/${record._id}`}>
                      <Button size="small">Details</Button>
                    </Link>
                    <Divider type="vertical" />
                    <Link to={`/admin/sellers/items/${record._id}`}>
                      <Button size="small">Items</Button>
                    </Link>
                  </span>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Row>
    );
  }
}

export default Sellers;
