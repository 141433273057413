import React from 'react';
// import PropTypes from 'prop-types';
import { Row, Col, Checkbox } from 'antd';

const CheckboxGroup = props => (
  <div>
    <Row>
      <Col>
        <Checkbox.Group
          options={props.reorderCheckboxGroup}
          onChange={props.handleCheckboxChange}
        />
      </Col>
    </Row>
  </div>
);

CheckboxGroup.propTypes = {};

export default CheckboxGroup;
