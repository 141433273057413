import React, { Component } from 'react';
import {
  Row, Col, Tag, Collapse, Button, Divider, Pagination,
} from 'antd';
import moment from 'moment';
import axios from '../../../axios';
import ConfirmButton from './ConfirmButton/ConfirmButton';
import './Order.css';

const { Panel } = Collapse;

const customPanelStyle = {
  background: 'white',
  borderRadius: '5px',
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
  boxShadow: '0px 8px 10px rgba(221, 221, 221, 0.3)',
};
// import PropTypes from 'prop-types';

const SortDishByRestaurant = ({ orderDish }) => {
  const stores = [];
  orderDish.forEach((item) => {
    if (!stores[item.originalSellerName]) {
      stores[item.originalSellerName] = [];
    }

    stores[item.originalSellerName].push(item);
  });
  const numableArr = [];

  Object.keys(stores).forEach((a) => {
    numableArr.push({
      name: a,
      items: stores[a],
    });
  });

  return (
    <div>
      {numableArr.map(store => (
        <Row key={store.name}>
          <Col span={24} style={{ fontWeight: '500', margin: '0px 0px 5px 0px' }}>
            {store.name}
          </Col>
          <Col span={24} style={{}}>
            {store.items.map(item => (
              <Tag key={item._id} style={{ marginBottom: '5px' }} color="geekblue">
                {item.titleChinese}
              </Tag>
            ))}
          </Col>
        </Row>
      ))}
    </div>
  );
};

export class Orders extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      orders: [],
      settings: {},
    };
  }

  async componentDidMount() {
    await this.fetchDriverRate();
    await this.fetchOrders();
  }

  fetchDriverRate = async () => {
    const settingsResponse = await axios.get('/settings/settings', {
      headers: { 'access-token': localStorage.getItem('token') },
    });
    const settings = settingsResponse.data.result;
    this.setState({
      settings,
    });
  };

  handlePageIndexChange = async (page, pageSize) => {
    await this.setState({
      pageIndex: page,
    });
    await this.fetchOrders();
  };

  handlePageSizeChange = async (current, size) => {
    await this.setState({
      pageSize: size,
    });
    await this.fetchOrders();
  };

  fetchOrders = async () => {
    const { pageSize, pageIndex } = this.state;
    const ordersResponse = await axios.get('/drivers/driver/orders', {
      params: { pageSize, pageIndex },
      headers: { 'access-token': localStorage.getItem('token') },
    });
    // console.log(ordersResponse.data.result);
    this.setState({
      total: ordersResponse.data.result.count,
      orders: ordersResponse.data.result.orders,
    });
  };

  render() {
    return (
      <div>
        <h1>当前订单</h1>
        <Row type="flex">
          <Col span={24}>
            <Button
              style={{
                borderRadius: '2em',
                padding: '0px 50px',
              }}
              onClick={() => {
                this.fetchOrders();
              }}
            >
              刷新
            </Button>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={24} className="orderListCol">
                <Collapse
                  bordered={false}
                  // defaultActiveKey={['1']}
                  expandIcon={() => null}
                  className="pendingOrderCollapse"
                >
                  {this.state.orders.length !== 0
                    ? this.state.orders.map((order, index) => (
                      <Panel
                        key={order._id}
                        className="pendingOrder"
                        header={(
                          <Row className="pendingOrderHeader" type="flex" align="middle" justify="center">
                            <Col className="info" sx={24} md={18}>
                              <Row >
                                <Col span={24}>
                                <div className="time">
                                {`送餐时间: ${moment(order.estimatedDate).calendar()}`}
                              </div>
                              <div className="to">{`送餐地点: ${order.address}`}</div>

                              {order.canDetour ? (
                                <Row>
                                  {`顺路送餐地址: ${order.shuttleAddress} ####:${
                                    order.unitNumber
                                  }`}
                                </Row>
                              ) : null}
                              </Col>
                              </Row>
                              
                            </Col>
                            <Col className="ops" sx={24} md={6}>
                              <Row
                                type="flex"
                                justify="center"
                                align="middle"
                                style={{ marginTop: '10px' }}
                              >
                                {`类型: ${order.deliveryType}`}
                                <ConfirmButton order={order} fetchOrders={this.fetchOrders} />
                              </Row>
                            </Col>
                          </Row>
)}
                        style={customPanelStyle}
                      >
                        <Divider />
                        <div className="DishesSortByRestaurants">
                          <SortDishByRestaurant orderDish={order.items} />
                        </div>

                        <div style={{ marginTop: '8px', fontWeight: '500' }}>
                          {`
                            税前:
                            ${order.subtotal.toFixed(2)}  \t
                            油费:${order.deliveryFee.toFixed(2)}  \t
                            小费:${order.tips}\t
                            税:  ${order.tax.toFixed(2)}\t
                            总价: ${order.total.toFixed(2)}
                          `}
                        </div>
                        <div style={{ color: 'gray', fontWeight: '700' }}>
                          {' '}
                            本单可赚
                          {' '}
                          <span style={{ color: '#B21818' }}>
                              $
                            {(
                              order.subtotal * this.state.settings.driverRate
                                + order.deliveryFee
                                + order.tips
                            ).toFixed(2)}
                          </span>
                        </div>
                      </Panel>
                    ))
                    : null}
                </Collapse>
              </Col>
            </Row>
            <Row>
              <Pagination
                defaultCurrent={this.state.pageIndex}
                current={this.state.pageIndex}
                onChange={this.handlePageIndexChange}
                defaultPageSize={this.state.pageSize}
                pageSize={this.state.pageSize}
                total={this.state.total}
                showSizeChanger
                pageSizeOptions={['5', '10', '15', '20']}
                onShowSizeChange={this.handlePageSizeChange}
              />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Orders;
