import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import {
  Alert, DatePicker, Row, Col, Button,
} from 'antd';
import moment from 'moment';

import axios from '../../../axios';
import { GOOGLE_MAPS_API_KEY } from '../../../config';
// import PropTypes from 'prop-types';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

export class UserLogs extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      logs: [],
      centerLatitude: '',
      centerLongitude: '',
      selectedId: '',
      selectedLatitude: '',
      selectedLongitude: '',
      selectedCreatedAt: '',
      timeRangeStart: 0,
      timeRangeEnd: 0,
      filterPoints: [],
    };
  }

  async componentDidMount() {
    const logsResponse = await axios.get('/users/admin/userLogs', {
      headers: { 'access-token': localStorage.getItem('token') },
    });
    const logs = logsResponse.data.result;
    await this.setState({
      logs,
      centerLatitude: logs[logs.length - 1].latitude,
      centerLongitude: logs[logs.length - 1].longitude,
    });
  }

  handleClickPoint = async (point) => {
    this.setState({
      selectedId: point._id,
      selectedCreatedAt: point.createdAt,
      selectedLatitude: point.latitude,
      selectedLongitude: point.longitude,
    });
  };

  changeDate = async (date) => {
    if (date === 0) {
      this.setState({
        timeRangeStart: 0,
        timeRangeEnd: 0,
      });
      this.filter(true);
    } else {
      await this.setState({
        timeRangeStart: moment(date[0]).valueOf(),
        timeRangeEnd: moment(date[1]).valueOf(),
      });

      await this.filter();
    }
  };

  filter = async (clean) => {
    if (clean) {
      this.setState(prevStates => prevStates.logs.map((p) => {
        p.highlight = false;
        return p;
      }));
    }

    this.setState(prevStates => prevStates.logs.map((p) => {
      if (p.createdAt > prevStates.timeRangeStart && p.createdAt < prevStates.timeRangeEnd) {
        p.highlight = true;
      }
      return p;
    }));
  };

  render() {
    const {
      logs, centerLatitude, centerLongitude, selectedId,
    } = this.state;
    const RequestPointComponent = ({ point }) => (
      <div
        role="presentation"
        onClick={() => {
          this.handleClickPoint(point);
        }}
        style={
          point._id === this.state.selectedId
            ? {
              width: '10px',
              height: '10px',
              borderRadius: '60px',
              border: '6px solid rgb(139, 0, 0)',
              transform: 'translate(-50%, -50%)',
            }
            : point.highlight === true
              ? {
                width: '10px',
                height: '10px',
                borderRadius: '60px',
                border: '6px solid red',
                transform: 'translate(-50%, -50%)',
              }
              : {
                width: '10px',
                height: '10px',
                borderRadius: '60px',
                border: '6px solid rgb(0, 153, 255)',
                transform: 'translate(-50%, -50%)',
              }
        }
      >
        {/* {point._id} */}
      </div>
    );
    return (
      <div>
        <h1>User Search Logs</h1>
        <Row>
          <Col>
            <RangePicker format={dateFormat} showTime="true" onOk={this.changeDate} />
            <Button
              style={{ marginLeft: '10px' }}
              onClick={() => {
                this.changeDate(0);
              }}
              type="primary"
            >
              Reset
            </Button>
          </Col>
          <Col />
        </Row>
        {logs.length !== 0 ? (
          <div style={{ margin: '20px 0' }}>
            Last Request:
            {`\t${logs[logs.length - 1].ip} ${logs[logs.length - 1].latitude} ${
              logs[logs.length - 1].longitude
            } ${moment(logs[logs.length - 1].createdAt).format('YYYY-MM-DD HH:mm z')}`}
          </div>
        ) : null}
        {selectedId !== '' ? (
          <Alert
            style={{ marginBottom: '10px' }}
            message={`Latitude: ${this.state.selectedLatitude} Longitude: ${
              this.state.selectedLongitude
            } Date: ${moment(this.state.selectedCreatedAt).format('YYYY-MM-DD HH:mm z')}`}
            type="info"
          />
        ) : null}
        <div style={{ height: '70vh', width: '100%' }}>
          {logs.length !== 0 ? (
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
              defaultCenter={{
                lat: centerLatitude,
                lng: centerLongitude,
              }}
              defaultZoom={6}
            >
              {logs.map(point => (
                <RequestPointComponent
                  key={point._id}
                  lat={point.latitude}
                  lng={point.longitude}
                  point={point}
                />
              ))}
            </GoogleMapReact>
          ) : null}
        </div>
      </div>
    );
  }
}

export default UserLogs;
