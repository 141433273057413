import React, { Component } from 'react';
import {
  Row, Col, Input, Form, Button, message,
} from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from '../../../axios';
// import PropTypes from 'prop-types';

export class SellerApplication extends Component {
  // static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      redirect: false,
      name: '',
      nameChinese: '',
      address: '',
      phoneNumber: '',
    };
  }

  componentDidMount() {
    console.log(this.props.isSeller);
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async () => {
    const {
      name, nameChinese, address, phoneNumber,
    } = this.state;
    if (name === '' || address === '' || phoneNumber === '') {
      message.warn('Please fill every field');
    } else {
      try {
        const response = await axios.post(
          '/sellers/create',
          {
            name,
            nameChinese,
            address,
            phoneNumber: `+1${phoneNumber}`,
          },
          {
            headers: {
              'access-token': localStorage.getItem('token'),
            },
          },
        );
        console.log(response);
        this.setState({
          redirect: true,
        });
      } catch (error) {
        message.error('Error happened.');
      }
    }
  };

  render() {
    const {
      name, nameChinese, address, phoneNumber, redirect,
    } = this.state;
    const { isSeller } = this.props;
    return (
      <div>
        {redirect ? <Redirect to="/seller" /> : null}
        <h1 style={{ textAlign: 'center' }}>Seller Application</h1>
        {isSeller ? (
          <Row>
            <Col style={{ padding: '20px 0' }}>
              You have applied the seller, so you can not apply
            </Col>
          </Row>
        ) : (
          <Row type="flex" justify="center">
            <Col span={20}>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                  <Input placeholder="Name" name="name" value={name} onChange={this.handleChange} />
                </Form.Item>
                <Form.Item>
                  <Input
                    placeholder="Chinese Name"
                    name="nameChinese"
                    value={nameChinese}
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    placeholder="Address"
                    name="address"
                    value={address}
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isSeller: state.user.isSeller,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SellerApplication);
