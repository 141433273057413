import _ from 'lodash';
import * as shortId from 'shortid';
import * as actionTypes from './actionTypes';

const countItems = (items) => {
  const initialValue = 0;
  const itemsCount = items.reduce(
    (accumulator, currentValue) => accumulator + currentValue.count,
    initialValue,
  );
  return itemsCount;
};

export const checkAddress = () => {
  let address = localStorage.getItem('address');
  if (address === null || address === undefined) {
    address = '';
  }
  let longitude = localStorage.getItem('longitude');
  let latitude = localStorage.getItem('latitude');
  if (
    longitude === null
    || longitude === undefined
    || latitude === null
    || latitude === undefined
  ) {
    longitude = null;
    latitude = null;
  }

  return {
    type: actionTypes.CART_UPDATE_ADDRESS,
    address,
    longitude,
    latitude,
  };
};

export const updateAddress = (address, longitude, latitude) => {
  localStorage.setItem('address', address);
  localStorage.setItem('longitude', longitude);
  localStorage.setItem('latitude', latitude);
  return {
    type: actionTypes.CART_UPDATE_ADDRESS,
    address,
    longitude,
    latitude,
  };
};

export const checkShuttleAddress = (address, longitude, latitude) => {
  let shuttleAddress = localStorage.getItem('shuttleAddress');
  let shuttleAddressLatitude = localStorage.getItem('shuttleAddressLatitude');
  let shuttleAddressLongitude = localStorage.getItem('shuttleAddressLongitude');
  // console.log(shuttleAddress);
  if (
    shuttleAddress === null
    || shuttleAddress === undefined
    || shuttleAddressLongitude === undefined
    || shuttleAddressLongitude === null
  ) {
    shuttleAddress = address;
    shuttleAddressLongitude = longitude;
    shuttleAddressLatitude = latitude;
    localStorage.setItem('shuttleAddress', shuttleAddress);
    localStorage.setItem('shuttleAddressLatitude', latitude);
    localStorage.setItem('shuttleAddressLongitude', longitude);
  }
  return {
    type: actionTypes.CART_UPDATE_SHUTTLE_ADDRESS,
    shuttleAddress,
    shuttleAddressLongitude,
    shuttleAddressLatitude,
  };
};

export const updateShuttleAddress = (
  shuttleAddress,
  shuttleAddressLongitude,
  shuttleAddressLatitude,
) => {
  localStorage.setItem('shuttleAddress', shuttleAddress);
  localStorage.setItem('shuttleAddressLongitude', shuttleAddressLongitude);
  localStorage.setItem('shuttleAddressLatitude', shuttleAddressLatitude);
  return {
    type: actionTypes.CART_UPDATE_SHUTTLE_ADDRESS,
    shuttleAddress,
    shuttleAddressLongitude,
    shuttleAddressLatitude,
  };
};

export const checkTime = (hours, minutes) => {
  let shuttleHours = localStorage.getItem('hours');
  let shuttleMinutes = localStorage.getItem('minutes');
  if (shuttleHours === null || shuttleMinutes === null) {
    shuttleHours = hours;
    shuttleMinutes = minutes;
    localStorage.setItem('hours', hours);
    localStorage.setItem('minutes', minutes);
  }
  return {
    type: actionTypes.CART_UPDATE_TIME,
    hours: shuttleHours,
    minutes: shuttleMinutes,
  };
};

export const checkCartTime = () => {
  const cartHours = localStorage.getItem('cartHours');
  const cartMinutes = localStorage.getItem('cartMinutes');
  return {
    type: actionTypes.CART_UPDATE_CART_TIME,
    cartHours,
    cartMinutes,
  };
};

export const updateTime = (hours, minutes) => {
  localStorage.setItem('hours', hours);
  localStorage.setItem('minutes', minutes);
  return { type: actionTypes.CART_UPDATE_TIME, hours, minutes };
};

export const updateCartTime = (cartHours, cartMinutes) => {
  localStorage.setItem('cartHours', cartHours);
  localStorage.setItem('cartMinutes', cartMinutes);
  return { type: actionTypes.CART_UPDATE_CART_TIME, cartHours, cartMinutes };
};

export const checkDeliveryType = () => {
  let type = localStorage.getItem('deliveryType');
  if (type === null || type === undefined) {
    localStorage.setItem('deliveryType', 'shuttle');
    type = 'shuttle';
  }
  return {
    type: actionTypes.CART_UPDATE_DELIVERY_TYPE,
    deliveryType: type,
  };
};

export const updateDeliveryType = (type) => {
  localStorage.setItem('deliveryType', type);
  return {
    type: actionTypes.CART_UPDATE_DELIVERY_TYPE,
    deliveryType: type,
  };
};

export const checkCartAddress = () => {
  let cartAddress = localStorage.getItem('cartAddress');
  if (cartAddress === null || cartAddress === undefined) {
    cartAddress = '';
  }
  let cartLongitude = localStorage.getItem('cartLongitude');
  let cartLatitude = localStorage.getItem('cartLatitude');
  if (
    cartLongitude === null
    || cartLongitude === undefined
    || cartLatitude === null
    || cartLatitude === undefined
  ) {
    cartLongitude = null;
    cartLatitude = null;
  }

  return {
    type: actionTypes.CART_UPDATE_CART_ADDRESS,
    cartAddress,
    cartLongitude,
    cartLatitude,
  };
};

export const updateCartAddress = (cartAddress, cartLongitude, cartLatitude) => {
  // console.log(cartAddress);
  localStorage.setItem('cartAddress', cartAddress);
  localStorage.setItem('cartLongitude', cartLongitude);
  localStorage.setItem('cartLatitude', cartLatitude);
  return {
    type: actionTypes.CART_UPDATE_CART_ADDRESS,
    cartAddress,
    cartLongitude,
    cartLatitude,
  };
};

export const checkCartDeliveryType = () => {
  const cartDeliveryType = localStorage.getItem('cartDeliveryType');
  return {
    type: actionTypes.CART_UPDATE_CART_DELIVERY_TYPE,
    cartDeliveryType,
  };
};

export const updateCartDeliveryType = (type) => {
  localStorage.setItem('cartDeliveryType', type);
  return {
    type: actionTypes.CART_UPDATE_CART_DELIVERY_TYPE,
    cartDeliveryType: type,
  };
};

export const updateBilling = (taxRate) => {
  let cartItems = localStorage.getItem('cartItems');
  if (cartItems === null || cartItems === undefined) {
    cartItems = [];
  } else {
    cartItems = JSON.parse(cartItems);
  }
  const cartDeliveryType = localStorage.getItem('cartDeliveryType');
  let subtotal = 0;
  if (cartItems.length !== 0) {
    cartItems.forEach((item) => {
      subtotal += item.price * item.count;
      let addonsTotal = 0;
      item.addons.forEach((addon) => {
        addonsTotal += addon.price;
      });
      subtotal += addonsTotal;
    });
    console.log(subtotal);
    let fee;
    switch (cartDeliveryType) {
      case 'shuttle':
        fee = 1;
        break;
      case 'pickup':
        fee = 0;
        break;
      case 'delivery':
        fee = 5;
        break;
      default:
        break;
    }
    return {
      type: actionTypes.CART_UPDATE_BILLING,
      subtotal,
      deliveryFee: fee,
      tax: Math.round(subtotal * taxRate * 100) / 100,
      total: Math.round((subtotal * (1 + taxRate) + fee) * 100) / 100,
    };
  }
  return {
    type: actionTypes.CART_UPDATE_BILLING,
    subtotal: 0,
    deliveryFee: 0,
    tax: 0,
    total: 0,
  };
};

export const clearItems = () => {
  localStorage.removeItem('cartItems');
  localStorage.removeItem('cartAddress');
  localStorage.removeItem('cartLatitude');
  localStorage.removeItem('cartLongitude');
  localStorage.removeItem('cartDeliveryType');
  return {
    type: actionTypes.CART_UPDATE_ITEMS,
    items: [],
    itemsCount: 0,
    cartLastUpdate: new Date().getTime(),
  };
};

export const checkItems = () => (dispatch) => {
  // first check cart last update

  let items = localStorage.getItem('cartItems');
  const cartLastUpdate = localStorage.getItem('cartLastUpdate');
  if (cartLastUpdate === null || cartLastUpdate === undefined) {
    // clear cart
    dispatch(clearItems());

    items = [];
    const itemsCount = countItems(items);

    localStorage.setItem('cartItems', JSON.stringify(items));
    localStorage.setItem('cartLastUpdate', new Date().getTime());

    dispatch({
      type: actionTypes.CART_UPDATE_ITEMS,
      items,
      itemsCount,
      cartLastUpdate: new Date().getTime(),
    });
  } else {
    // check last update if too old
    const now = new Date().getTime();
    const offset = now - cartLastUpdate;
    if (offset > 7200000) {
      dispatch(clearItems());
      items = [];
      const itemsCount = countItems(items);

      localStorage.setItem('cartItems', JSON.stringify(items));
      localStorage.setItem('cartLastUpdate', new Date().getTime());

      dispatch({
        type: actionTypes.CART_UPDATE_ITEMS,
        items,
        itemsCount,
        cartLastUpdate: new Date().getTime(),
      });
    } else {
      if (items === null || items === undefined) {
        items = [];
      } else {
        items = JSON.parse(items);
      }
      const itemsCount = countItems(items);

      localStorage.setItem('cartItems', JSON.stringify(items));

      // dispatch(updateBilling());

      dispatch({
        type: actionTypes.CART_UPDATE_ITEMS,
        items,
        itemsCount,
        cartLastUpdate: new Date().getTime(),
      });
    }
  }
};

export const addItem = (item, taxRate) => async (dispatch) => {
  item.id = shortId.generate();
  // fist check localStorage
  let items = localStorage.getItem('cartItems');
  if (items === null || items === undefined) {
    // try to create new array
    items = [];
    items.push(item);
  } else {
    items = JSON.parse(items);
    items.push(item);
  }
  // update localStorage
  localStorage.setItem('cartItems', JSON.stringify(items));
  localStorage.setItem('cartLastUpdate', new Date().getTime());

  const itemsCount = countItems(items);
  dispatch({
    type: actionTypes.CART_UPDATE_ITEMS,
    items,
    itemsCount,
    cartLastUpdate: new Date().getTime(),
  });
};

export const removeItem = (item, taxRate) => (dispatch) => {
  // fist check localStorage
  let items = localStorage.getItem('cartItems');

  if (items === null || items === undefined) {
    items = [];
  } else {
    items = JSON.parse(items);
    _.remove(items, n => n.id === item.id);
  }

  // update localStorage
  localStorage.setItem('cartItems', JSON.stringify(items));
  localStorage.setItem('cartLastUpdate', new Date().getTime());

  const itemsCount = countItems(items);

  dispatch(updateBilling(taxRate));

  dispatch({
    type: actionTypes.CART_UPDATE_ITEMS,
    items,
    itemsCount,
    cartLastUpdate: new Date().getTime(),
  });
};
