import { Zhi1 } from 'zhi1';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from '../Aux/Aux';
import HomeNav from '../../components/HomePage/HomeNav/HomeNav';
import Footer from '../../components/HomePage/Footer/Footer';
import * as actions from '../../store/actions';
import './Layout.css';

export class Layout extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  async componentDidMount() {
    this.props.checkAddress();
    this.props.checkCartItems();
    // console.log(this.props.lang);
  }

  changeLanguage = (lang) => {
    this.props.updateLanguage(lang);
  };

  changeLanguageButton = () => {
    if (this.props.lang === 'en') {
      this.props.updateLanguage('zh');
    } else if (this.props.lang === 'zh') {
      this.props.updateLanguage('en');
    } else {
      this.props.updateLanguage('en');
    }
    Zhi1('demoData-dropbee')
      .feTracking({
        id: 'Language_switch', // 不可改, 唯一id
        value: this.props.lang|| 'issue', // 自定义, 具体想保存的元信息,
        increment: 1, // 自定义,距离的增值, 默认为1, 如记录收入等数据时按需加入
        labelRecord: true, // 是否要将此点作为标签记录在录像里
      });
  };

  render() {
    const {
      isAuthenticated,
      children,
      isSeller,
      isAdmin,
      cartItemsCount,
      firstName,
      isDriver,
    } = this.props;

    return (
      <Aux>
        <HomeNav
          isAuthenticated={isAuthenticated}
          isSeller={isSeller}
          isAdmin={isAdmin}
          isDriver={isDriver}
          cartItemsCount={cartItemsCount}
          firstName={firstName}
          changeLanguage={this.changeLanguage}
          changeLanguageButton={this.changeLanguageButton}
        />
        <main className="content" style={{ minHeight: '800px', flex: '1 0 auto' }}>
          {children}
        </main>
        <Footer changeLanguage={this.changeLanguage} />
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.token !== null,
  isSeller: state.user.isSeller,
  isAdmin: state.user.isAdmin,
  isDriver: state.user.isDriver,
  firstName: state.user.firstName,
  cartItemsCount: state.cart.itemsCount,
  lang: state.locale.lang,
});

const mapDispatchToProps = dispatch => ({
  checkAddress: () => dispatch(actions.checkAddress()),
  checkCartItems: () => dispatch(actions.checkItems()),
  updateLanguage: lang => dispatch(actions.setLocale(lang)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);
