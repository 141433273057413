import React from 'react';
import { Steps } from 'antd';
import './StatusStep.css';
import { FormattedMessage } from 'react-intl';

const { Step } = Steps;

const currentIndex = (status) => {
  switch (status) {
    case 'sent':
      return 0;
    case 'confirmed':
      return 1;
    case 'out for delivery':
      return 2;
    case 'delivered':
      return 4;
    default:
      return -1;
  }
};

const StatusSteper = props => (
  <Steps size="small" className="progressBar" current={currentIndex(props.orderStatus)}>
    <Step
      title={<FormattedMessage id="steper.send" defaultMessage="Dropbee" />}
      description={<FormattedMessage id="steper.orderplaced" defaultMessage="Dropbee" />}
    />
    <Step
      title={<FormattedMessage id="steper.confirm" defaultMessage="Dropbee" />}
      description={<FormattedMessage id="steper.recieveOrder" defaultMessage="Dropbee" />}
    />
    <Step
      title={<FormattedMessage id="steper.outfordelivery" defaultMessage="Out for Delivery" />}
      description={<FormattedMessage id="steper.onWay" defaultMessage="On the way" />}
    />
    <Step
      title={<FormattedMessage id="steper.delivered" defaultMessage="Delivered" />}
      description={<FormattedMessage id="steper.deliveredOrder" defaultMessage="Dropbee has delivered your food" />}
    />
  </Steps>
);

StatusSteper.propTypes = {};

export default StatusSteper;
