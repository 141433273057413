import React, { Component } from 'react';
import {
  Col, Row, List, Pagination,
} from 'antd';
import moment from 'moment';
import axios from '../../../axios';
// import PropTypes from 'prop-types';

export class Users extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      pageIndex: 1,
      pageSize: 10,
      users: [],
      total: 0,
    };
  }

  async componentDidMount() {
    await this.fetchUsers();
  }

  handlePageIndexChange = async (page, pageSize) => {
    await this.setState({
      pageIndex: page,
    });
    await this.fetchUsers();
  };

  handlePageSizeChange = async (current, size) => {
    await this.setState({
      pageSize: size,
    });
    await this.fetchUsers();
  };

  fetchUsers = async () => {
    const usersResponse = await axios.get('/users/admin/users', {
      params: {
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize,
      },
      headers: { 'access-token': localStorage.getItem('token') },
    });

    console.log(usersResponse.data.result);

    await this.setState({
      users: usersResponse.data.result.users,
      total: usersResponse.data.result.count,
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Col>
            <List
              dataSource={this.state.users}
              renderItem={user => (
                <List.Item>
                  <Row>
                    <Row>{`Name:\t${user.firstName} ${user.lastName}`}</Row>
                    <Row>{`Email:\t${user.email}`}</Row>
                    <Row>
                      Tel:
                      {'\t'}
                      <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>
                    </Row>
                    <Row>{user.lastIp ? `Last IP:\t${user.lastIp}` : 'Last IP:\tNone'}</Row>
                    <Row>
                      {user.lastLogin
                        ? `Last Login:\t${moment(user.lastLogin).format('YYYY-MM-DD HH:mm z')}`
                        : 'Last Login:\tNone'}
                    </Row>
                  </Row>
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Pagination
            defaultCurrent={this.state.pageIndex}
            current={this.state.pageIndex}
            onChange={this.handlePageIndexChange}
            defaultPageSize={this.state.pageSize}
            pageSize={this.state.pageSize}
            total={this.state.total}
            showSizeChanger
            pageSizeOptions={['2', '5', '10', '15', '20']}
            onShowSizeChange={this.handlePageSizeChange}
          />
        </Row>
      </div>
    );
  }
}

export default Users;
