import React from 'react';
import {
  Button, Row, Col, List, Modal, Alert,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import { GOOGLE_MAPS_API_KEY } from '../../../config';
import mapMarker from '../../../assets/images/icons/marker.svg';
import streetViewIcon from '../../../assets/images/icons/street-view.svg';
import mapStyles from './mapStyles';
// import PropTypes from 'prop-types';
import './ShuttleComponent.css';
import DistanceAlert from '../DistanceAlert/DistanceAlert';

const ShuttleComponent = (props) => {
  const mapInfo = (address, picture, streetView, comment, commentChinese) => {
    Modal.info({
      centered: true,
      icon: null,
      content: (
        <div className="mapDetailModal">
          <img style={{ width: '100%' }} src={picture} alt="location" />
          <Row className="more-info">
            <Col xs={24} sm={8}>
              <a href={streetView} target="_blank" rel="noopener noreferrer">
                <div className="street-view">
                  <img src={streetViewIcon} alt="street view" />
                  <div>Street View</div>
                </div>
              </a>
            </Col>
            <Col xs={24} sm={16}>
              <div className="text-detail">
                <div>
                  {props.lang === 'en' ? <div>{comment}</div> : <div>{commentChinese}</div>}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ),
      onOk() {},
    });
  };

  const PointComponent = ({
    picture,
    img,
    text,
    streetView,
    comment,
    commentChinese,
    point,
    color,
  }) => (
    <div>
      {/* <input
        type="image"
        style={{
          width: '20px',
          height: 'auto',
          transform: 'translate(-50%,-100%)',
          outline: 'none',
        }}
        src={mapMarker}
        alt="point"
      /> */}

      <div
        role="presentation"
        className="pin"
        style={
          props.shuttleAddress === text
          && props.hours === point.hours
          && props.minutes === point.minutes
            ? { background: color, zIndex: '99' }
            : { background: color, zIndex: '0' }
        }
        alt="point"
        onClick={() => {
          props.handleClickAddress(point);
        }}
      />

      <Button
        className="showMapLocation"
        type="primary"
        style={
          props.shuttleAddress === text
            ? { transform: 'translate(-50%,-17px)' }
            : { display: 'none' }
        }
        onClick={() => {
          mapInfo(text, picture, streetView, comment, commentChinese);
        }}
      >
        <FormattedMessage id="img" defaultMessage="Dropbee" />
      </Button>
    </div>
  );

  const UserPointComponent = ({ point }) => (
    <div className="userPoint">
      <div className="dot" />
      <div className="pulse" />
    </div>
  );

  // const defaultCenter = {
  //   latitude: props.latitude,
  //   longitude: props.longitude,
  // };

  return (
    <Row>
      {props.deliveryType === 'shuttle' ? (
        <Row>
          <Col span={24}>
            <Row className="shopHeader">
              <Col xs={24} style={{ textAlign: 'center' }}>
                <img className="storeLogo" src={props.seller.pictureURL} alt="shop" />
                <div
                  style={{
                    textAlign: 'center',
                    fontFamily: 'montserrat',
                    padding: '16px',
                    fontSize: '1.5em',
                    fontWeight: 'bold',
                  }}
                >
                  {props.seller.name}
                </div>

                {!props.hideDetails ? (
                  <div className="subtitle">
                    {!props.loading ? (
                      <div>
                        <FormattedMessage id="cutoffTimeL" defaultMessage="Dropbee" />
                        {' '}
                        {props.lunchCutoffTime.hours}
                        {`:${
                          props.lunchCutoffTime.minutes.toString().length === 1
                            ? `0${props.lunchCutoffTime.minutes}`
                            : props.lunchCutoffTime.minutes
                        }, `}
                        <FormattedMessage id="cutoffTimeD" defaultMessage="Dropbee" />
                        {' '}
                        {props.dinnerCutoffTime.hours}
                        {`:${
                          props.dinnerCutoffTime.minutes.toString().length === 1
                            ? `0${props.dinnerCutoffTime.minutes}`
                            : props.dinnerCutoffTime.minutes
                        }, `}
                        <FormattedMessage id="cutoffTimeS" defaultMessage="Dropbee" />
                        {' '}
                        {props.supperCutoffTime.hours}
                        {`:${
                          props.supperCutoffTime.minutes.toString().length === 1
                            ? `0${props.supperCutoffTime.minutes}`
                            : props.supperCutoffTime.minutes
                        }.`}
                        {/* <FormattedMessage id="cutoffTime" defaultMessage="??" /> */}
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {!props.hideDetails && props.shuttlePoints.length !== 1 ? (
                  <div>
                    <div className="time">
                      <FormattedMessage id="deliveryDate" defaultMessage="Dropbee" />
                      <span style={{ fontWeight: 'bold' }}>{props.deliveryDate}</span>
                    </div>
                    <div>
                      <DistanceAlert distance={props.distance} />
                    </div>
                  </div>
                ) : null}
                {props.shuttlePoints.length === 1 ? (
                  <div>
                    <div className="time">
                      <Alert type="warning" message="You are too far away from the shop" />
                    </div>
                  </div>
                ) : null}
              </Col>
            </Row>
            {!props.hideDetails ? (
              <Row>
                <Col xs={24} md={12} className="shopInfo">
                  <Row span={24} style={{ padding: '0px 0px ' }} gutter={8}>
                    <Col xs={24} sm={24} md={24} style={{ marginBottom: '8px' }}>
                      <div
                        className="scrollAbleAddressSelection"
                        style={{ maxHeight: '400px', overflow: 'scroll' }}
                      >
                        {!props.loading ? (
                          <List
                            size="small"
                            bordered={false}
                            dataSource={_.filter(props.shuttlePoints, ['userPoint', false])}
                            renderItem={item => (item.userPoint ? (
                              <div />
                            ) : (
                              <List.Item
                                  // className="addressItem"
                                key={item._id}
                                id={item._id}
                                onClick={() => {
                                  props.handleClickAddress(item);
                                }}
                                className={
                                    item.address === props.shuttleAddress
                                    && item.hours === props.hours
                                    && item.minutes === props.minutes
                                      ? 'addressItem'
                                      : ''
                                  }
                              >
                                <Row type="flex" justify="start" style={{ width: '100%' }}>
                                  <Col
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={2}
                                    style={{ minHeight: '30px' }}
                                  >
                                    <div style={{ background: item.color }} className="marker" />
                                  </Col>
                                  <Col
                                    xs={20}
                                    sm={20}
                                    md={20}
                                    lg={20}
                                    xl={20}
                                    xxl={22}
                                    style={{ minHeight: '30px' }}
                                  >
                                    {`${item.address.replace(', USA', '')} -`}
                                    <span className="time">
                                      {props.formatTime(item.hours)}
                                      {':'}
                                      {props.formatTime(item.minutes)}
                                      {' - '}
                                      {props.formatTime(item.endTime.endHour)}
                                      {':'}
                                      {props.formatTime(item.endTime.endMin)}
                                    </span>
                                    {item.tomorrow ? (
                                      <FormattedMessage id="tomorrow" defaultMessage="tomorrow" />
                                    ) : (
                                      <span />
                                    )}
                                  </Col>
                                </Row>
                              </List.Item>
                            ))
                            }
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={12} className="shopMap">
                  <div className="locationImg">
                    <div style={{ height: '460px', width: '100%' }}>
                      {!props.loading ? (
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                          // defaultCenter={{
                          //   lat: parseFloat(defaultCenter.latitude),
                          //   lng: parseFloat(defaultCenter.longitude),
                          // }}
                          options={{ styles: mapStyles }}
                          center={
                            props.shuttleAddressLongitude && props.shuttleAddressLatitude
                              ? {
                                lng: parseFloat(props.shuttleAddressLongitude),
                                lat: parseFloat(props.shuttleAddressLatitude),
                              }
                              : {
                                lng: parseFloat(props.longitude),
                                lat: parseFloat(props.latitude),
                              }
                          }
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={({ map, maps }) => {
                            props.apiIsLoaded(map, maps);
                          }}
                          defaultZoom={14}
                        >
                          {props.shuttlePoints.map(point => (point.userPoint ? (
                            <UserPointComponent
                              key="userPoint"
                              lat={point.latitude}
                              lng={point.longitude}
                            />
                          ) : (
                            <PointComponent
                              color={point.color}
                              className="point"
                              key={point._id}
                              lat={point.location.coordinates[1]}
                              lng={point.location.coordinates[0]}
                              img={mapMarker}
                              text={point.address}
                              picture={point.pictureURL}
                              streetView={point.streetView}
                              comment={point.comment}
                              commentChinese={point.commentChinese}
                              point={point}
                            />
                          )))}
                        </GoogleMapReact>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      ) : null}
    </Row>
  );
};

ShuttleComponent.propTypes = {};

export default ShuttleComponent;
