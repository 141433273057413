export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  let pattern;

  switch (rules) {
    case 'required':
      isValid = value.trim() !== '' && isValid;
      break;
    case 'minLength':
      isValid = value.length >= rules.minLength && isValid;
      break;
    case 'maxLength':
      isValid = value.length <= rules.maxLength && isValid;
      break;
    case 'isEmail':
      pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
      break;
    case 'isNumeric':
      pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
      break;
    case 'isTips':
      pattern = /^[+]?[0-9]{1,9}(?:\.[0-9]{1,9})?$/;
      isValid = pattern.test(value) && isValid;
      break;
    default:
      break;
  }

  return isValid;
};
