import React, { Component } from 'react';
import { Row, Col, Typography } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import axios from '../../axios';
import * as actions from '../../store/actions';
import './Shops.css';
// import warn from '../../assets/images/statusImg/error.svg';

const { Title } = Typography;

export class Shops extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      sellers: [],
      loaded: false,
    };
  }

  async componentDidMount() {
    const {
      checkAddress, checkDeliveryType, longitude, latitude, deliveryType,
    } = this.props;

    await checkAddress();
    await checkDeliveryType();
    // search sellers
    const response = await axios.get('/sellers/sellers/location', {
      params: {
        longitude,
        latitude,
        type: deliveryType,
      },
    });
    this.setState({ sellers: response.data.result, loaded: true });
  }

  render() {
    const { sellers, loaded } = this.state;
    if (!loaded) {
      return (
        <Row type="flex" align="middle" justify="center" style={{ minHeight: '80%' }}>
          <Col>
            <div className="lds-ripple">
              <div />
              <div />
            </div>
          </Col>
        </Row>
      );
    }
    if (sellers.length === 0) {
      return (
        <div
          className="shopsContainer"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItem: 'center',
            marginTop: '20vh',
          }}
        >
          {/* <img src={warn} style={{ height: '160px' }} /> */}
          <div
            className="text"
            style={{
              fontSize: '1.1em',
              textAlign: 'center',
              width: '80%',
              margin: '0 auto',
              marginTop: '40px',
            }}
          >
            <FormattedMessage id="shops.noshop" defaultMessage="Dropbee" />
          </div>
        </div>
      );
    }

    return (
      <div className="shopsContainer">
        <Row>
          <Col span={24} style={{ padding: '10px 10px' }}>
            <Title level={4}>{`Mode: ${this.props.deliveryType}`}</Title>
          </Col>
        </Row>

        <Row type="flex" justify="center" align="middle">
          {this.props.deliveryType === 'shuttle'
            && sellers.map(seller => (
              <Col key={seller._id} xs={24} sm={16} md={6} lg={6} className="restaurantCard">
                <div className="img">
                  <Link to={`/shop/${seller._id}`}>
                    <img src={seller.pictureURL} alt="shop" />
                  </Link>
                </div>
                <div className="info">
                  <div className="name">{seller.name}</div>
                </div>
              </Col>
            ))}
        </Row>
        {this.props.deliveryType === 'shuttle' && sellers.length !== 0 ? (
          <Redirect to={`/shop/${sellers[0]._id}`} />
        ) : null}
        <Row gutter={16}>
          {this.props.deliveryType === 'pickup'
            && sellers.map(seller => (
              <Col key={seller._id} xs={24} sm={12} xl={8}>
                <div className="restaurantCard">
                  <div className="img">
                    <Link to={`/shop/${seller._id}`}>
                      <img src={seller.pictureURL} alt="shop" />
                    </Link>
                  </div>
                  <div className="info">
                    <div className="name">{seller.name}</div>
                    <div className="distance"> 2.1 miles</div>
                  </div>
                </div>
              </Col>
            ))}
           

{this.props.deliveryType === 'delivery'
            && sellers.map(seller => (
              <Col key={seller._id} xs={24} sm={12} xl={8}>
                <div className="restaurantCard">
                  <div className="img">
                    <Link to={`/shop/${seller._id}`}>
                      <img src={seller.pictureURL} alt="shop" />
                    </Link>
                  </div>
                  <div className="info">
                    <div className="name">{seller.name}</div>
                    <div className="distance"> 2.1 miles</div>
                  </div>
                </div>
              </Col>
            ))}

        </Row>

      
      </div>
    );
  }
}

const mapStateToProps = state => ({
  address: state.cart.address,
  longitude: state.cart.longitude,
  latitude: state.cart.latitude,
  deliveryType: state.cart.deliveryType,
  cartDeliveryType: state.cart.cartDeliveryType,
});

const mapDispatchToProps = dispatch => ({
  checkAddress: () => dispatch(actions.checkAddress()),
  checkDeliveryType: () => dispatch(actions.checkDeliveryType()),
  checkCartDeliveryType: () => dispatch(actions.checkCartDeliveryType()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Shops);
