import { Button, Row, Col } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import facebookIcon from '../../../assets/images/icons/facebook.svg';
import './SignUpForm.css';

const SignUpForm = (props) => {
  const {
    phoneNumber,
    smsCode,
    firstName,
    lastName,
    handleChange,
    handleSendSMS,
    handleSubmit,
    email,
    counter,
  } = props;

  return (
    <div className="login_form">
      <div className="login_title">
        <FormattedMessage id="signupAccount" defaultMessage="Dropbee" />
      </div>
      <Row>
        <div className="phoneNUmber">
          <Row type="flex" justify="center" align="middle">
            <Col xs={24}>
              <FormattedMessage id="phoneNumber" defaultMessage="Dropbee">
                {placeholder => (
                  <input
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handleChange}
                    type="tel"
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </Col>
          </Row>
        </div>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <div className="phoneNUmber">
          <Row type="flex" justify="center" align="middle">
            <Col xs={18}>
              <FormattedMessage id="verificationCode" defaultMessage="Dropbee">
                {placeholder => (
                  <input
                    name="smsCode"
                    value={smsCode}
                    onChange={handleChange}
                    type="text"
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </Col>
            <Col xs={6}>
              {counter <= 0 ? (
                <Button
                  type="primary"
                  size="small"
                  shape="round"
                  ghost
                  style={{ fontSize: '12px' }}
                  onClick={() => {
                    handleSendSMS(`+1${phoneNumber}`);
                  }}
                >
                  <FormattedMessage id="send" defaultMessage="Dropbee" />
                </Button>
              ) : (
                <Button
                  type="primary"
                  size="small"
                  shape="round"
                  ghost
                  disabled
                  style={{ fontSize: '12px' }}
                  onClick={() => {
                    handleSendSMS(`+1${phoneNumber}`);
                  }}
                >
                  {`${counter} seconds`}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Row>
      <div className="phoneNUmber">
        <Row type="flex">
          <Col xs={12}>
            <FormattedMessage id="firstName" defaultMessage="Dropbee">
              {placeholder => (
                <input
                  name="firstName"
                  value={firstName}
                  onChange={handleChange}
                  type="text"
                  placeholder={placeholder}
                />
              )}
            </FormattedMessage>
          </Col>
          <Col xs={12}>
            <FormattedMessage id="lastName" defaultMessage="Dropbee">
              {placeholder => (
                <input
                  name="lastName"
                  value={lastName}
                  onChange={handleChange}
                  type="text"
                  placeholder={placeholder}
                />
              )}
            </FormattedMessage>
          </Col>
        </Row>
      </div>
      <div className="phoneNUmber">
        <Row type="flex">
          <Col xs={24}>
            <FormattedMessage id="email" defaultMessage="email">
              {placeholder => (
                <input
                  name="email"
                  value={email}
                  onChange={handleChange}
                  type="email"
                  placeholder={placeholder}
                />
              )}
            </FormattedMessage>
          </Col>
        </Row>
      </div>

      <Row type="flex" align="middle" justify="center">
        <span style={{ fontSize: '12px', marginTop: '10px' }}>
          <FormattedMessage id="iagree" defalut="I agree the" />
          <a
            href="https://menupedia.io/termsofservice.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="termofuse" defalut="terms" />
          </a>
          {' & '}
          <a
            href="https://menupedia.io/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="privacypolicy" defalut="privacy" />
          </a>
        </span>
      </Row>
      <Row className="buttons" type="flex" justify="center" align="middle">
        <Button
          type="primary"
          shape="round"
          style={{ fontSize: '13px' }}
          onClick={() => {
            handleSubmit(`+1${phoneNumber}`, smsCode, firstName, lastName);
          }}
        >
          <FormattedMessage id="signup" defaultMessage="signup" />
        </Button>
      </Row>
      <Row type="flex" justify="center" align="middle">
        {/* <span className="signUp">注册新账号</span> */}
        <a href="/login" className="signUp">
          <FormattedMessage id="alreadyHaveAccount" defaultMessage="Dropbee" />
        </a>
      </Row>
    </div>
  );
};

SignUpForm.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSendSMS: PropTypes.func.isRequired,
};

export default SignUpForm;
