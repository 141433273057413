import React, { Component } from 'react';
import { Col, Row, Statistic } from 'antd';
import axios from '../../../axios';
// import PropTypes from 'prop-types';

export class Overview extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      users: 0,
      totalOrders: 0,
    };
  }

  async componentDidMount() {
    const userCountResponse = await axios.get('/users/admin/total/users/count', {
      headers: { 'access-token': localStorage.getItem('token') },
    });
    const orderCountResponse = await axios.get('/orders/admin/total/orders/count', {
      headers: { 'access-token': localStorage.getItem('token') },
    });
    await this.setState({
      users: userCountResponse.data.result.count,
      totalOrders: orderCountResponse.data.result.count,
    });
  }

  render() {
    return (
      <div>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic title="Users" value={this.state.users} />
          </Col>
          <Col span={12}>
            <Statistic title="Total Orders" value={this.state.totalOrders} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Overview;
