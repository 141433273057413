import React, { Component } from 'react';
import {
  Row, Col, Input, Button, message,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import axios from '../../../axios';
import './Profile.css';

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      code: '',
      counter: 0,
    };

    this.handleSaveUserInfo = this.handleSaveUserInfo.bind(this);
  }

  async componentDidMount() {
    await this.getUserInformation();
  }

  async componentWillUnmount() {
    clearInterval(this.interval);
  }

  getUserInformation = async () => {
    const response = await axios.get(`/users/user/userId/${localStorage.getItem('userId')}`, {
      headers: { 'access-token': localStorage.getItem('token') },
    });
    const user = response.data.result;
    this.setState({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: _.replace(user.phoneNumber, '+1', ''),
    });
    console.log(response.data);
  };

  handleSendSms = async () => {
    const phoneNumber = `+1${this.state.phoneNumber}`;
    if (!phoneNumber.match(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/)) {
      message.warn('Phone number is wrong.');
    } else {
      try {
        const response = await axios.post(
          '/users/bind/sms',
          { phoneNumber },
          { headers: { 'access-token': localStorage.getItem('token') } },
        );
        console.log(response.data.result);
        if (process.env.NODE_ENV === 'development') {
          if (response.data.code === 101006) {
            message.success(`Please wait ${response.data.result.time} seconds.`);
          } else if (response.data.code === 200001) {
            message.success(response.data.result);
          }
        }
        if (response.data.code === 200001) {
          this.setState({
            counter: 30,
          });
        } else if (response.data.code === 101006) {
          this.setState({ counter: response.data.result.time });
        }

        this.interval = setInterval(() => {
          if (this.state.counter <= 0) {
            clearInterval(this.interval);
          }
          this.tick();
        }, 1000);
      } catch (error) {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.code === 101002) {
          message.warn('The phone number already taken, please change other phone number');
        } else {
          message.error('Something error.');
        }
      }
    }
  };

  handleSavePhoneNumber = async () => {
    const phoneNumber = `+1${this.state.phoneNumber}`;
    const smsCode = this.state.code;
    if (
      !phoneNumber.match(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/)
      || smsCode === ''
      || smsCode.length < 4
    ) {
      message.warn('Phone number is wrong or Code is empty');
    } else {
      const response = await axios.post(
        '/users/bind/phoneNumber',
        { phoneNumber, smsCode },
        { headers: { 'access-token': localStorage.getItem('token') } },
      );
      this.setState({
        code: '',
      });
      console.log(response);
      message.success('Successful');
      await this.getUserInformation();
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSaveUserInfo = async () => {
    try {
      await axios.post(
        '/users/user/update',
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
        },
        { headers: { 'access-token': localStorage.getItem('token') } },
      );
      message.success('Successful');
    } catch (error) {
      message.error('Some error!');
    }
  };

  tick = () => {
    this.setState(prevState => ({
      counter: prevState.counter - 1,
    }));
  };

  interval;

  render() {
    return (
      <div>
        <Row>
          <h2>Phone Number</h2>
          <Row>
            <Col span={5}>Phone Number</Col>
            <Col span={14}>
              <Input
                name="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.handleChange}
              />
            </Col>
            <Col span={1} />
            <Col span={4}>
              {this.state.counter <= 0 ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.handleSendSms();
                  }}
                >
                  Send
                </Button>
              ) : (
                <Button disabled>{`${this.state.counter} seconds`}</Button>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col span={5}>Code</Col>
            <Col span={14}>
              <Input name="code" value={this.state.code} onChange={this.handleChange} />
            </Col>
          </Row>
          <Row style={{ marginTop: '20px', textAlign: 'center' }}>
            <Button
              type="primary"
              onClick={() => {
                this.handleSavePhoneNumber();
              }}
            >
              Change Phone Number
            </Button>
          </Row>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <h2>Basic Information</h2>
          <Col>
            <Row style={{ marginTop: '20px' }}>
              <Col span={5}>
                <FormattedMessage id="firstName" />
              </Col>
              <Col span={14}>
                <Input
                  type="text"
                  name="firstName"
                  onChange={this.handleChange}
                  value={this.state.firstName}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
              <Col span={5}>
                <FormattedMessage id="lastName" />
              </Col>
              <Col span={14}>
                <Input
                  type="text"
                  name="lastName"
                  onChange={this.handleChange}
                  value={this.state.lastName}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
              <Col span={5}>
                <FormattedMessage id="email" />
              </Col>
              <Col span={14}>
                <Input
                  type="text"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                />
              </Col>
            </Row>
          </Col>
          <Row style={{ marginTop: '20px', textAlign: 'center' }}>
            <Button
              type="primary"
              onClick={() => {
                this.handleSaveUserInfo();
              }}
            >
              Save
            </Button>
          </Row>
        </Row>
      </div>
    );
  }
}

export default Profile;
