import React, { Component } from 'react';
import {
  Col, Row, Input, Button, message,
} from 'antd';
import axios from '../../../../axios';
// import PropTypes from 'prop-types';

export class CutoffTimes extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
  }

  handleCutoffTimesUpdate = async () => {
    const {
      lunchHours,
      lunchMinutes,
      dinnerHours,
      dinnerMinutes,
      supperHours,
      supperMinutes,
      mode,
      userId,
    } = this.props;
    try {
      const cutoffTimeResponse = await axios.post(
        '/sellers/seller/update/cutoffTimes',
        {
          lunchHours,
          lunchMinutes,
          dinnerHours,
          dinnerMinutes,
          supperHours,
          supperMinutes,
          mode,
          userId,
        },
        { headers: { 'access-token': localStorage.getItem('token') } },
      );
      console.log(cutoffTimeResponse);
      message.success('Successful');
    } catch (error) {
      message.error('Error happen');
    }
  };

  render() {
    const {
      lunchHours,
      lunchMinutes,
      dinnerHours,
      dinnerMinutes,
      supperHours,
      supperMinutes,
      handleChange,
    } = this.props;

    return (
      <div>
        {this.props.isOfficial ? (
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <h2>Cutoff Times</h2>
            </Col>
            <Col>
              <Row>
                <Col span={5}>Lunch:</Col>
                <Col span={19}>
                  <Row>
                    <Col span={4}>Hours:</Col>
                    <Col span={8}>
                      <Input value={lunchHours} name="lunchHours" onChange={handleChange} />
                    </Col>
                    <Col span={4}>Minutes:</Col>
                    <Col span={8}>
                      <Input value={lunchMinutes} name="lunchMinutes" onChange={handleChange} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={5}>Dinner:</Col>
                <Col span={19}>
                  <Row>
                    <Col span={4}>Hours:</Col>
                    <Col span={8}>
                      <Input value={dinnerHours} name="dinnerHours" onChange={handleChange} />
                    </Col>
                    <Col span={4}>Minutes:</Col>
                    <Col span={8}>
                      <Input value={dinnerMinutes} name="dinnerMinutes" onChange={handleChange} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={5}>Supper:</Col>
                <Col span={19}>
                  <Row>
                    <Col span={4}>Hours:</Col>
                    <Col span={8}>
                      <Input value={supperHours} name="supperHours" onChange={handleChange} />
                    </Col>
                    <Col span={4}>Minutes:</Col>
                    <Col span={8}>
                      <Input value={supperMinutes} name="supperMinutes" onChange={handleChange} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Row>
              <Button
                onClick={() => {
                  this.handleCutoffTimesUpdate();
                }}
              >
                Save
              </Button>
            </Row>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default CutoffTimes;
