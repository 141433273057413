import React, { Component } from 'react';
import {
  Row, Col, Collapse, Divider, Icon, Pagination, Empty,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import StatusSteper from './StatusStep/StatusStep';

import axios from '../../../axios';
import './Orders.css';

const customPanelStyle = {
  background: '#ffffff',
  borderRadius: 16,
  marginBottom: 8,
  border: 0,
  overflow: 'hidden',

  boxShadow: '0px 3px 20px rgba(185,185,185,0.16)',
};

export class Orders extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      orders: [],
      pageSize: 5,
      pageIndex: 1,
      total: 0,
    };
  }

  async componentDidMount() {
    await this.fetchOrders();
  }

  handlePageIndexChange = async (page, pageSize) => {
    await this.setState({
      pageIndex: page,
    });
    await this.fetchOrders();
  };

  handlePageSizeChange = async (current, size) => {
    await this.setState({
      pageSize: size,
    });
    await this.fetchOrders();
  };

  fetchOrders = async () => {
    const response = await axios.get('/orders/orders', {
      params: {
        pageSize: this.state.pageSize,
        pageIndex: this.state.pageIndex,
      },
      headers: { 'access-token': localStorage.getItem('token') },
    });
    await this.setState({
      orders: response.data.result.orders,
      total: response.data.result.count,
    });
  };

  render() {
    const panelHeader = item => (
      <div className="panelTitle">
        <div className="imagesAndStatus">
          <div className="images">
            {item.items.length >= 1 ? <img src={item.items[0].pictureURL} alt="food" /> : null}
            {item.items.length >= 2 ? <img src={item.items[1].pictureURL} alt="food" /> : null}
            {item.items.length > 2 ? `${item.items.length} items` : null}
          </div>
          <div className="status">{`${item.status} `}</div>
          <span className="ago">
            <FormattedMessage id="updated" defaultMessage="Updated: " />
            {moment(item.updatedAt).fromNow()}
          </span>
        </div>
        <div className="info">
          <div className="date">
            <FormattedMessage id="order.createTime" defaultMessage="Dropbee" />
            {' '}
            {moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
          </div>
          <div className="date">
            <FormattedMessage id="order.pickUpTime" defaultMessage="Dropbee" />
            {moment(item.estimatedDate).format('YYYY-MM-DD HH:mm')}
          </div>
        </div>
      </div>
    );

    return (
      <Row>
        <Row>
          <Col xs={24} md={24} lg={20}>
            <Row>
              {this.state.orders.length > 0 ? (
                <Collapse
                  bordered={false}
                  expandIcon={false}
                  className="ordersContainer"
                  defaultActiveKey={this.state.orders[0]._id}
                >
                  {this.state.orders.map(
                    order => order.isPaid === true && (
                    <Collapse.Panel
                      style={customPanelStyle}
                      header={panelHeader(order)}
                      key={order._id}
                    >
                      <div className="imgs">
                        {order.items.map(item => (
                          <img key={item._id} src={item.pictureURL} alt="food" />
                        ))}
                      </div>
                      {order.items.map(n => (
                        <div key={`${n._id}-img`}>
                          {`${n.count} x `}
                          {n.title}
                          {n.addons
                                && n.addons.map(addon => (
                                  <div key={addon.shortId}>{addon.description}</div>
                                ))}
                        </div>
                      ))}

                      <Divider />
                      {/* <h4 style={{ marginTop: '20px' }}>Order Summary </h4> */}
                      <div className="numbers">
                        <div className="line">
                          <div className="text">
                            <FormattedMessage id="subtotal" defaultMessage="Dropbee" />
                          </div>
                          <div className="numbers">{`$${order.subtotal}`}</div>
                        </div>
                        <div className="line">
                          <div className="text">
                            <FormattedMessage id="tax" defaultMessage="Dropbee" />
                          </div>
                          <div className="numbers">{`$${order.tax}`}</div>
                        </div>
                        <div className="line">
                          <div className="text">
                            <FormattedMessage id="deliveryfee" defaultMessage="Dropbee" />
                          </div>
                          <div className="numbers">{`$${order.deliveryFee}`}</div>
                        </div>
                        <div className="line">
                          <div className="text">Tips</div>
                          <div className="numbers">
                            {order.tips ? `$${order.tips}` : '$0.0'}
                          </div>
                        </div>
                        <div className="line">
                          <div className="text">
                            <FormattedMessage id="total" defaultMessage="Dropbee" />
                          </div>
                          <div className="numbers">{`$${order.total}`}</div>
                        </div>
                      </div>
                      {order.driverId !== null && order.driverId !== undefined ? (
                        <div>
                          <Divider />
                          <div className="line">
                            <div className="text">Driver Name</div>
                            <div className="content">
                              {`${order.driver.firstName} ${order.driver.lastName}`}
                            </div>
                          </div>
                          <div className="line">
                            <div className="text">Driver Phone Number</div>
                            <div className="content">{order.driver.phoneNumber}</div>
                          </div>
                        </div>
                      ) : null}
                      <Divider />
                      <div className="line">
                        <div className="text">
                          <FormattedMessage id="mode" defaultMessage="Dropbee" />
                        </div>
                        <div className="content">{order.deliveryType}</div>
                      </div>

                      <div className="line">
                        <div className="text">
                          <FormattedMessage id="address" defaultMessage="Dropbee" />
                        </div>
                        <div className="content">
                          {order.address}
                          <a
                            style={{ marginLeft: '5px' }}
                            href={`//maps.apple.com/?q=${order.address}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Icon type="environment" />
                          </a>
                        </div>
                      </div>
                      <Row>
                        <Col>
                          <StatusSteper orderStatus={order.status} />
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    ),
                  )}
                </Collapse>
              ) : (
                <Empty />
              )}
            </Row>
            <Row>
              <Pagination
                defaultCurrent={this.state.pageIndex}
                current={this.state.pageIndex}
                onChange={this.handlePageIndexChange}
                defaultPageSize={this.state.pageSize}
                pageSize={this.state.pageSize}
                total={this.state.total}
                showSizeChanger
                pageSizeOptions={['5', '10', '15']}
                onShowSizeChange={this.handlePageSizeChange}
              />
            </Row>
          </Col>
        </Row>
      </Row>
    );
  }
}

export default Orders;
