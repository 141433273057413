import React, { Component } from 'react';
import { Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import axios from '../../../../axios';
// import PropTypes from 'prop-types';

export class ConfirmButton extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
  }

  handleConfirmOrder = async () => {
    const { history } = this.props;
    try {
      const response = await axios.post(
        '/drivers/confirmOrder',
        {
          orderId: this.props.order._id,
        },
        {
          headers: {
            'access-token': localStorage.getItem('token'),
          },
        },
      );
      console.log(response);
      message.success('successful');
      this.props.fetchOrders();
       history.push('/driver/current');
    } catch (error) {
      message.error('');
    }
  };

  render() {
    return (
      <Button
        type="primary"
        style={{ width: '100%', borderRadius: '2em' }}
        onClick={() => {
          this.handleConfirmOrder();
        }}
      >
        Confirm
      </Button>
    );
  }
}

export default withRouter(ConfirmButton);
