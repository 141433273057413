import React from 'react';
import {
  Row, Col, Button, Input, Select, Icon,
} from 'antd';
import './Heading.css';
import PlacesAutocomplete from 'react-places-autocomplete';

import { FormattedMessage } from 'react-intl';
import mapImg from '../../../assets/images/guideline/map.png';
import orderImg from '../../../assets/images/guideline/order.png';
import driverImg from '../../../assets/images/guideline/driver.png';
import walkImg from '../../../assets/images/guideline/walk.png';

import shuttle from '../../../assets/images/homepage/shuttle-en2.png';

const Heading = props => (
  <div className="homepageContainer">
    <div className="headingContainer">
      <Row className="mainheader" type="flex" justify="center" align="middle">
        <Col
          className="slogan"
          sm={24}
          xs={0}
          style={{ minHeight: '150px', padding: '30px 0 0 0', textAlign: 'center' }}
        >
          {props.rerollDishURLs.length > 0 && (
            <div className="gallery">
              <img src={props.rerollDishURLs[0]} alt="dropbee dish one" />
              <img src={props.rerollDishURLs[1]} alt="dropbee dish two" />
              <img src={props.rerollDishURLs[2]} alt="dropbee dish three" />
            </div>
          )}
          <span
            className=""
            // style={{ color: 'black !important', padding: '20px', background: 'rgba(255,255,255,0.9)' }}
          >
            <FormattedMessage id="slogan" defaultMessage="Let's Order" />
          </span>
        </Col>

        <Col md={24} lg={18} style={{ textAlign: 'center' }}>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
              padding: '30px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Col xs={24} md={14} style={{ padding: '5px' }}>
              <div className="getLocation">
                <button
                  type="submit"
                  style={{
                    color: 'red',
                    border: 'none',
                    padding: '0',
                    background: 'none',
                    cursor: 'pointer',
                    outline: '0',
                  }}
                  onClick={props.getCurrentLocation}
                >
                  <Icon type="environment" />
                </button>
              </div>
              <PlacesAutocomplete
                className="autoComplete"
                value={props.address}
                onChange={props.handleAddressChange}
                onSelect={props.handleSelect}
                googleCallbackName="initOne"
              >
                {({
                  getInputProps, suggestions, getSuggestionItemProps, loading,
                }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        placeholder: 'Enter your location',
                        className: 'searchInput',
                        autoFocus: true,
                      })}
                    />

                    <div className="autocompleteDropDown">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestionItemActive'
                          : 'suggestionItem';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>

            <Col xs={24} md={4} style={{ padding: '5px' }}>
              <Select
                // defaultValue={props.deliveryType}
                value={props.deliveryType}
                onSelect={props.handleDeliveryTypeChange}
                className="selectMode"
              >
                <Select.Option value="shuttle">
                  <FormattedMessage id="shuttle" defaultMessage="shuttle" />
                </Select.Option>
                <Select.Option value="pickup">
                  <FormattedMessage id="pickup" defaultMessage="pickup" />
                </Select.Option>
                <Select.Option value="delivery">
                  <FormattedMessage id="delivery" defaultMessage="delivery" />
                </Select.Option>
              </Select>
            </Col>
            <Col xs={24} md={6} style={{ padding: '5px' }}>
              <Button
                className="searchButton"
                type="primary"
                onClick={() => {
                  props.handleSearchButton();
                }}
              >
                {' Search '}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>

    <div className="featureSection">
      <Row>
        <Col xs={24} sm={24} md={0} style={{ height: '160px' }} />
        <Col
          style={{ zIndex: 2, fontSize: 'bold' }}
          xs={24}
          sm={{ span: '20', offset: '4' }}
          md={{ span: '12', offset: '12' }}
        >
          <h2 style={{ marginLeft: '30px' }}>Why Dropbee?</h2>
          <ol>
            <li>
              <FormattedMessage id="home.dropbeeBf1" defaultMessage="$1 delivery fee" />
            </li>
            <li>
              <FormattedMessage id="home.detourMode" defaultMessage="Detour mode delivery to your door" />
            </li>
            <li>
              <FormattedMessage id="home.bundleMode" defaultMessage="bundle your food in one order" />


            </li>
            <li>
              <FormattedMessage id="home.dropbeeBf2" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              <FormattedMessage id="home.dropbeeBf3" defaultMessage="Hand pick authentic Asian cuisine, Try something new and unforgettable" />
            </li>
            <li>
              <FormattedMessage id="home.dropbeeBf4" defaultMessage="Authentic teas and desserts" />
            </li>
            <li>
              <FormattedMessage id="home.dropbeeBf5" defaultMessage="New dishes reroll everyday" />
            </li>
            {/* <li>
              <FormattedMessage id="home.dropbeeBf6" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              <FormattedMessage id="home.dropbeeBf7" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              <FormattedMessage id="home.dropbeeBf8" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            */}
          </ol>
        </Col>
      </Row>
      <div className="shuttleImg">
        <img src={shuttle} alt="Shuttle" />
      </div>
      <div />
    </div>

    {/* <div className="brand">
      <Row>
        <Col span={24}>
          <div className="title">
            <FormattedMessage id="home.dropbeetitle1" defaultMessage="Dropbee" />
          </div>
        </Col>
        <Col span={24}>
          <div className="content">
            <FormattedMessage
              id="home.dropbeeSubtitle"
              defaultMessage="Dropbee 定点送是个性化定时定点中餐送餐服务,我们从以下多个角度帮您解决人生最大哲学问题之一:今天吃什么"
            />
          </div>

          <ol>
            <li>
              <FormattedMessage id="home.dropbeeBf1" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              <FormattedMessage id="home.dropbeeBf2" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              <FormattedMessage id="home.dropbeeBf3" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              {' '}
              <FormattedMessage id="home.dropbeeBf4" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              {' '}
              <FormattedMessage id="home.dropbeeBf5" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              {' '}
              <FormattedMessage id="home.dropbeeBf6" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              {' '}
              <FormattedMessage id="home.dropbeeBf7" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
            <li>
              <FormattedMessage id="home.dropbeeBf8" defaultMessage="最便宜外卖费仅需1美元" />
            </li>
          </ol>
        </Col>
      </Row>
    </div> */}

    <div className="guideline">
      <Row>
        <Col span={24}>
          <div className="title">
            {' '}
            <FormattedMessage id="home.dropbeetitle2" defaultMessage="How we work" />
          </div>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <div className="guide_card">
            <img src={mapImg} alt="map" />
            <div>
              <FormattedMessage id="home.dropbeeStep1" defaultMessage="How we work" />
              <div>
                {/* <FormattedMessage id="home.dropbeeStep1.2" defaultMessage="How we work" /> */}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <div className="guide_card">
            <img src={orderImg} alt="order" />
            <div>
              <FormattedMessage id="home.dropbeeStep2" defaultMessage="How we work" />
              <div>
                {' '}
                {/* <FormattedMessage id="home.dropbeeStep2.2" defaultMessage="How we work" /> */}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <div className="guide_card">
            <img src={driverImg} alt="driver" />
            <div>
              <FormattedMessage id="home.dropbeeStep3" defaultMessage="How we work" />
              <div>
                {/* <FormattedMessage id="home.dropbeeStep3.2" defaultMessage="How we work" /> */}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <div className="guide_card">
            <img src={walkImg} alt="walk" />
            <div>
              <FormattedMessage id="home.dropbeeStep4" defaultMessage="How we work" />

              <div>
                {' '}
                {/* <FormattedMessage id="home.dropbeeStep4.2" defaultMessage="How we work" /> */}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default Heading;
