import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import SideMenu from '../../components/SideMenu/SideMenu';
import Overview from './Overview/Overview';
import Users from './Users/Users';
import Orders from './Orders/Orders';
import Sellers from './Sellers/Sellers';
import { SellerProfile } from '../Seller/SellerProfile/SellerProfile';
import { SellerItems } from '../Seller/SellerItems/SellerItems';
import UserLogs from './UserLogs/UserLogs';
import Settings from './Settings/Settings';
// import PropTypes from 'prop-types';

export class Admin extends Component {
  // static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      menuKey: '',
    };
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    await this.setState({ menuKey: window.location.pathname.toString().replace('/admin/', '') });
  }

  render() {
    const menuLinks = [
      {
        key: 'overview',
        href: '/admin/overview',
        name: 'Overview',
        intlName: 'overview',
      },
      {
        key: 'users',
        href: '/admin/users',
        name: 'Users',
        intlName: 'users',
      },
      {
        key: 'sellers',
        href: '/admin/sellers',
        name: 'Sellers',
        intlName: 'sellers',
      },
      {
        key: 'orders',
        href: '/admin/orders',
        name: 'Orders',
        intlName: 'orders',
      },
      {
        key: 'userLogs',
        href: '/admin/userLogs',
        name: 'Logs',
        intlName: 'logs',
      },
      {
        key: 'settings',
        href: '/admin/settings',
        name: 'Settings',
        intlName: 'settings',
      },
    ];

    return (
      <div style={{ padding: '10px 20px' }}>
        <h1>Admin Dashboard</h1>
        <Row gutter={24}>
          {this.state.menuKey !== '' ? (
            <SideMenu menuLinks={menuLinks} menuKey={this.state.menuKey} />
          ) : null}
          <Col md={18} xs={24}>
            <Switch>
              <Route exact path="/admin/overview" component={Overview} />
              <Route exact path="/admin/users" component={Users} />
              <Route exact path="/admin/sellers" component={Sellers} />
              <Route exact path="/admin/sellers/seller/:sellerId" component={SellerProfile} />
              <Route exact path="/admin/sellers/items/:sellerId" component={SellerItems} />
              <Route exact path="/admin/orders" component={Orders} />
              <Route exact path="/admin/userLogs" component={UserLogs} />
              <Route exact path="/admin/settings" component={Settings} />
              <Redirect from="/admin" to="/admin/overview" />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Admin;
