import React from 'react';
import { Row, Col, Radio } from 'antd';
// import PropTypes from 'prop-types';

const RadioGroup = props => (
  <div>
    <Row>
      <Col>
        <Radio.Group onChange={props.handleRadioChange}>
          {// console.log(item);
          props.reorderRadioGroup.map(item => (
            <Radio key={item.shortId} value={item.shortId}>
              {`${item.description} $${item.price}`}
            </Radio>
          ))}
        </Radio.Group>
      </Col>
    </Row>
  </div>
);

RadioGroup.propTypes = {};

export default RadioGroup;
