import React, { Component } from 'react';
import {
  Row, Col, Button, Select, message,
} from 'antd';
import axios from '../../../../axios';
// import PropTypes from 'prop-types';

export class OrderStatus extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      orderId: '',
      status: '',
    };
  }

  async componentDidMount() {
    await this.setState({
      orderId: this.props.order._id,
      status: this.props.order.status,
    });
  }

  handleChangeOrderStatus = async (value) => {
    await this.setState({ status: value });
    console.log(this.state.status);
  };

  handleSubmitOrderStatus = async () => {
    try {
      await axios.post(
        '/orders/update/order/status',
        {
          orderId: this.state.orderId,
          orderStatus: this.state.status,
        },
        { headers: { 'access-token': localStorage.getItem('token') } },
      );
      message.success('Successful');
    } catch (error) {
      message.error(error);
    }
  };

  render() {
    return (
      <Row>
        <Col>
          <Select
            style={{ width: 120 }}
            onSelect={this.handleChangeOrderStatus}
            value={this.state.status}
          >
            <Select.Option value="sent">Sent</Select.Option>
            <Select.Option value="confirmed">Confirmed</Select.Option>
            <Select.Option value="out for delivery">Out for delivery</Select.Option>
            <Select.Option value="delivered">Delivered</Select.Option>
            <Select.Option value="canceled">Canceled</Select.Option>
          </Select>
          <Button
            style={{ marginLeft: '10px' }}
            size="small"
            onClick={() => {
              this.handleSubmitOrderStatus();
            }}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    );
  }
}

export default OrderStatus;
