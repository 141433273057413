import React, { Component } from 'react';
import { Row, Col, Alert } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import SellerItems from './SellerItems/SellerItems';
import SellerProfile from './SellerProfile/SellerProfile';
import SellerOrders from './SellerOrders/SellerOrders';
import SideMenu from '../../components/SideMenu/SideMenu';
import * as actions from '../../store/actions';
import './Seller.css';

export class Seller extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      menuKey: '',
    };
  }

  async componentDidMount() {
    const {
      checkToken, isAuthenticated, sellerId, getCurrentUser, getSeller,
    } = this.props;
    await checkToken();
    if (isAuthenticated) {
      await getCurrentUser();
      if (sellerId !== null) {
        await getSeller(sellerId);
      }
      await this.setState({
        menuKey: window.location.pathname.toString().replace('/seller/', ''),
      });
    }
  }

  render() {
    const { approval } = this.props;

    const menuLinks = [
      {
        key: 'orders',
        href: '/seller/orders',
        name: 'Orders',
        intlName: 'orders',
      },
      {
        key: 'items',
        href: '/seller/items',
        name: 'Items',
        intlName: 'items',
      },
      {
        key: 'profile',
        href: '/seller/profile',
        name: 'Profile',
        intlName: 'profile',
      },
    ];

    return (
      <div style={{ padding: '10px 20px' }}>
        <h1>Seller settings</h1>
        {approval === false ? (
          <Alert
            message="Warning"
            description="Please connect Dropbee to active your shop via phone 520-461-9076."
            type="warning"
            showIcon
            style={{ marginBottom: '20px' }}
          />
        ) : null}
        <Row gutter={24}>
          {this.state.menuKey !== '' ? (
            <SideMenu menuLinks={menuLinks} menuKey={this.state.menuKey} />
          ) : null}
          <Col md={18} xs={24}>
            <Switch>
              <Route exact path="/seller/items" component={SellerItems} />
              <Route exact path="/seller/orders" component={SellerOrders} />
              <Route path="/seller/profile" component={SellerProfile} />
              <Redirect from="/seller" to="/seller/orders" />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.token !== null,
  userId: state.user.userId !== null,
  sellerId: state.user.sellerId,
  approval: state.seller.approval,
});

const mapDispatchToProps = dispatch => ({
  checkToken: () => dispatch(actions.authCheckState()),
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
  getSeller: sellerId => dispatch(actions.getSeller(sellerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Seller);
