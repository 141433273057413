import React, { Component } from 'react';
import { Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import axios from '../../axios';
import LoginForm from '../../components/HomePage/LoginForm/LoginForm';
import './Login.css';
import { PHONE_NUMBER } from '../../config';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      phoneNumber: PHONE_NUMBER,
      smsCode: '',
      counter: 0,
      exist: true,
      facebookLoading: false,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleChange = async (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === 'phoneNumber') {
      const phoneNumber = `+1${value}`;
      if (phoneNumber.match(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/)) {
        const checkResponse = await axios.post('/users/user/check/exist', {
          phoneNumber,
        });
        this.setState({ exist: checkResponse.data.result.exist });
      }
    }
  };

  handleSubmit = async (phoneNumber, smsCode) => {
    const { auth, getCurrentUser } = this.props;
    if (
      phoneNumber.match(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/)
      && smsCode.length === 4
    ) {
      try {
        await auth(phoneNumber, smsCode);
        await getCurrentUser();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code === 104008) {
          message.error(error.response.data.message);
        }
      }
    } else {
      message.warn('Please enter the correct account and password.');
    }
  };

  handleSendSMS = async (phoneNumber) => {
    console.log(phoneNumber);
    if (!phoneNumber.match(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/)) {
      message.warn('Phone number is wrong.');
    } else {
      const response = await axios.post('/users/sms', { phoneNumber });
      console.log(response.data.result);
      if (process.env.NODE_ENV === 'development') {
        if (response.data.code === 101006) {
          message.success(`Please wait ${response.data.result.time} seconds.`);
        } else if (response.data.code === 200001) {
          message.success(response.data.result);
        }
      }

      if (response.data.code === 200001) {
        this.props.updatePhoneNumber(phoneNumber);
        this.setState({
          counter: 30,
        });
      } else if (response.data.code === 101006) {
        this.setState({ counter: response.data.result.time });
      }

      this.interval = setInterval(() => {
        if (this.state.counter <= 0) {
          clearInterval(this.interval);
        }
        this.tick();
      }, 1000);
    }
  };

  handleResponseFacebook = async (response) => {
    await this.setState({
      facebookLoading: true,
    });

    const { getAccessToken, getCurrentUser } = this.props;

    console.log(response);
    const firstName = response.first_name;
    const lastName = response.last_name;
    const email = response.email;
    const accessToken = response.accessToken;
    const userID = response.userID;
    try {
      const thirdPartResponse = await axios.post('/users/sso', {
        platform: 'facebook',
        userAccessToken: accessToken,
        userID,
        firstName,
        lastName,
        email,
      });
      console.log(thirdPartResponse);
      await getAccessToken(thirdPartResponse.data.result.code);
      await getCurrentUser();
      // when got code try to login
    } catch (error) {
      await this.setState({
        facebookLoading: false,
      });
      message.error('Sorry, can not login with Facebook.');
    }
  };

  tick = () => {
    this.setState(prevState => ({
      counter: prevState.counter - 1,
    }));
  };

  interval;

  render() {
    const {
      phoneNumber, smsCode, counter, exist,
    } = this.state;
    return (
      <div className="loginForm">
        <Row type="flex" align="middle" justify="center">
          <Col>
            <LoginForm
              phoneNumber={phoneNumber}
              handleChange={this.handleChange}
              handleSendSMS={this.handleSendSMS}
              handleSubmit={this.handleSubmit}
              smsCode={smsCode}
              counter={counter}
              exist={exist}
              handleResponseFacebook={this.handleResponseFacebook}
              facebookLoading={this.state.facebookLoading}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
  updatePhoneNumber: phoneNumber => dispatch(actions.updatePhoneNumber(phoneNumber)),
  getAccessToken: code => dispatch(actions.getAccessToken(code)),
  auth: (phoneNumber, smsCode) => dispatch(actions.auth(phoneNumber, smsCode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
